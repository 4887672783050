import React, { useEffect } from 'react';
import MetaDescription from '../MetaDescription';
import '../styles/BPOPage.css';
import { useTranslation, Trans } from 'react-i18next';
import { Link } from 'react-router-dom';
import Indicators from '../Indicators';
import plataforma from '../../assets/Plataforma-SIAN-Solucion-Teconologica-Navemar-Navesoft.png'

function BPOPage() {
    const { t } = useTranslation();
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const ImagenPrincipal = () => {
        return (
            <div className="bpoimg">
                <div className="container-fluid custom-container">
                    <div className="content-portada col-12 col-md-6 col-xl-8">
                        <h1><Trans>servicios.bpo_titulo_principal</Trans></h1>
                        <p><Trans>servicios.bpo_texto_principal</Trans></p>
                        <div className="buttons-container">
                            <Link to="/contactenos"><button className="btn btn-primary me-4"><Trans>servicios.bpo_boton_principal1</Trans></button></Link>
                        </div>
                    </div>
                </div>
            </div>
        );
    };

    const Indicator = () => {
        return (
            <div className="container">
                <div className='row text-justify mt-0 mt-md-4 ms-5 ms-md-0'>
                    <div className='col-12 col-md-6 col-xl-3 border-left py-4 mt-5 mt-xl-0'>
                        <h3><Indicators endNumber={14743} suffix="" decimals={0} time={30} /></h3>
                        <p className="titulo_indicador my-3"><Trans>servicios.bpo_indicador_titulo1</Trans></p>
                        <p className="pe-5"><Trans>servicios.bpo_indicador_texto1</Trans></p>
                    </div>
                    <div className='col-12 col-md-6 col-xl-3 border-left py-4 mt-5 mt-xl-0'>
                        <h3><Indicators endNumber={15301} suffix="" decimals={0} time={30} /></h3>
                        <p className="titulo_indicador my-3 "><Trans>servicios.bpo_indicador_titulo2</Trans></p>
                        <p className="pe-5"><Trans>servicios.bpo_indicador_texto2</Trans></p>
                    </div>
                    <div className='col-12 col-md-6 col-xl-3 border-left py-4 mt-5 mt-xl-0'>
                        <h3><Indicators endNumber={1227216} suffix="" decimals={0} time={30} /></h3>
                        <p className="titulo_indicador my-3"><Trans>servicios.bpo_indicador_titulo3</Trans></p>
                        <p className="pe-5"><Trans>servicios.bpo_indicador_texto3</Trans></p>
                    </div>
                    <div className='col-12 col-md-6 col-xl-3 border-left py-4 mt-5 mt-xl-0'>
                        <h3><Indicators endNumber={59185} suffix="" decimals={0} time={30} /></h3>
                        <p className="titulo_indicador my-3"><Trans>servicios.bpo_indicador_titulo4</Trans></p>
                        <p className="pe-5"><Trans>servicios.bpo_indicador_texto4</Trans></p>
                    </div>
                </div>
            </div>
        );
    };

    const SeccionIndicadores = () => {
        return (
            <div className='container-fluid'>
                <div className='custom-container'>
                    <div className="height-space-med"></div>
                    <div className='row'>
                        <div className='col-12 col-xl-11 mt-4'>
                            <h2 className="mb-5"><Trans>servicios.bpo_titulo_indicadores</Trans></h2>
                            <p className="text-justify"><Trans i18nKey="servicios.bpo_texto_indicadores" components={{ text_link: <Link className="texto-azul-link" to='/nuestros-clientes'/>}} /></p>
                        </div>
                    </div>
                    <div className='row'>
                        <Indicator />
                    </div>
                    <div className='row'></div>
                    <div className="height-space-med"></div>
                </div>
            </div>
        );
    };

    const PlataformaSian = () => {
        return (
            <div className='container-fluid bgclaro'>
                <div className='custom-container'>
                    <div className="height-space-med"></div>
                    <div className='row'>
                        <div className='col-12 col-xl-6 text-center order-2 order-xl-1 mt-5 mt-xl-0'>
                            <img src={plataforma} alt="Politica" className="img-fluid img-bpo" />
                        </div>
                        <div className='col-12 col-xl-6 d-flex align-items-center order-1 order-xl-2'>
                            <div>
                                <h3 className="mb-5"><Trans>servicios.bpo_titulo_plataforma</Trans></h3>
                                <p className="text-justify"><Trans>servicios.bpo_texto_plataforma</Trans></p>
                                <Link to="/contactenos"><button type="button" className="btn btn-primary d-none d-xl-block"><Trans>servicios.bpo_servicios_boton_plataforma</Trans></button></Link>
                            </div>
                        </div>
                        <div className='col-12 d-block d-xl-none order-3 text-center mt-5'>
                            <div>
                                <Link to="/contactenos"><button type="button" className="btn btn-primary me-0 me-md-5 mb-3 mb-md-0"><Trans>servicios.bpo_servicios_boton_plataforma</Trans></button></Link>
                            </div>
                        </div>
                    </div>
                    <div className='row mb-2 mb-xl-5'></div>
                    <div className="height-space-med"></div>
                </div>
            </div>
        );
    };

    const ServiciosBPO = () => {
        return (
            <div className="container-fluid">
                <div className='custom-container'>
                    <div className="height-space-med"></div>
                    <h2 className="text-justify"><Trans>servicios.bpo_servicios_titulo</Trans></h2>
                    <div className="height-space-min"></div>
                    <div className="list-container">
                        <ul className="centered-list custom-list-services">
                            <li>
                                <p className="text-justify margen-centro-bpo subt"><Trans>servicios.bpo_servicios_1</Trans></p>
                                <p className="text-justify margen-centro-bpo mb-5"><Trans>servicios.bpo_servicios_texto_1</Trans></p>
                            </li>
                            <li>
                                <p className="text-justify margen-centro-bpo subt"><Trans>servicios.bpo_servicios_2</Trans></p>
                                <p className="text-justify margen-centro-bpo mb-5"><Trans>servicios.bpo_servicios_texto_2</Trans></p>
                            </li>
                            <li>
                                <p className="text-justify margen-centro-bpo subt"><Trans>servicios.bpo_servicios_3</Trans></p>
                                <p className="text-justify margen-centro-bpo mb-5"><Trans>servicios.bpo_servicios_texto_3</Trans></p>
                            </li>
                            <li>
                                <p className="text-justify margen-centro-bpo subt"><Trans>servicios.bpo_servicios_4</Trans></p>
                                <p className="text-justify margen-centro-bpo mb-5"><Trans>servicios.bpo_servicios_texto_4</Trans></p>
                            </li>
                        </ul>
                    </div>
                    <div className="botones-nuestros-servicios botones-bpo text-center mt-0 mt-md-5">
                        <Link to="/nuestros-clientes"><button type="button" className="btn btn-primary me-0 me-md-5 mb-3 mb-md-0"><Trans>servicios.bpo_servicios_boton_clientes</Trans></button></Link>
                        <Link to="/nuestros-servicios"><button type="button" className="btn btn-primary"><Trans>servicios.bpo_servicios_boton_servicios</Trans></button></Link>
                    </div>
                    <div className="height-space-med"></div>
                </div>
            </div>
        );
    };



    return (
        <div>
            <MetaDescription content={t('servicios.bpo_meta_descripcion')}/>
            <ImagenPrincipal />
            <SeccionIndicadores />
            <PlataformaSian />
            <ServiciosBPO />
        </div>
    );
}

export default BPOPage;
