import React from 'react';
import Slider from 'react-slick';
import { Trans } from 'react-i18next';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import './styles/SliderAgenciamiento.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft, faChevronRight } from '@fortawesome/free-solid-svg-icons';
import buque_nyk from '../assets/Transbordo directo de vehiculos entre dos buques de la naviera NYK.png';
import buque_ever_baq from '../assets/MN Ever Career - Primer buque de Evergren en el Puerto de Barranquilla-NAVEMAR.png';
import buque_hapag_bun from '../assets/Arribo buque MN Vantage HapagLloyd BUN.png';
import roro_nyk from '../assets/RoRo-descargue vehiculos-mn global leader.png';
import buque_hapag from '../assets/Cargue contenedores MN Vantage.png';
import buque_nyk_bun from '../assets/Arribo buquue mn global leader bun.png';
import buque_ever_bun from '../assets/Buque de 334 metros de eslora operado por Evergreen-Navemar.png';
import ever_amarre from '../assets/Proceso de amarre para facilitar la carga y descarga de mercancias del buque-Navemar.jpg';
import ever_navemar from '../assets/El equipo de Navemar gestionando la llegada del MN Ever Career de Evergreen-Navemar.jpg';


const SliderAgenciamiento = () => {

    const CustomPrevArrow = ({ onClick }) => (
        <div className="custom-prev-arrow" onClick={onClick}>
            <FontAwesomeIcon icon={faChevronLeft} />
        </div>
    );

    const CustomNextArrow = ({ onClick }) => (
        <div className="custom-next-arrow" onClick={onClick}>
            <FontAwesomeIcon icon={faChevronRight} />
        </div>
    );

    const slides = [
        {
            image: buque_nyk,
            title: <Trans>servicios.agenciamiento_slider_titulo1</Trans>,
            subtitle: <Trans>servicios.agenciamiento_slider_subtitulo1</Trans>,
            text: <Trans>servicios.agenciamiento_slider_texto1</Trans>
        },
        {
            image: buque_ever_baq,
            title: <Trans>servicios.agenciamiento_slider_titulo2</Trans>,
            subtitle: <Trans>servicios.agenciamiento_slider_subtitulo2</Trans>,
            text: <Trans>servicios.agenciamiento_slider_texto2</Trans>
        },
        {
            image: roro_nyk,
            title: <Trans>servicios.agenciamiento_slider_titulo3</Trans>,
            subtitle: <Trans>servicios.agenciamiento_slider_subtitulo3</Trans>,
            text: <Trans>servicios.agenciamiento_slider_texto3</Trans>
        },
        {
            image: buque_hapag,
            title: <Trans>servicios.agenciamiento_slider_titulo4</Trans>,
            subtitle: <Trans>servicios.agenciamiento_slider_subtitulo4</Trans>,
            text: <Trans>servicios.agenciamiento_slider_texto4</Trans>
        },
        {
            image: buque_ever_bun,
            title: <Trans>servicios.agenciamiento_slider_titulo5</Trans>,
            subtitle: <Trans>servicios.agenciamiento_slider_subtitulo5</Trans>,
            text: <Trans>servicios.agenciamiento_slider_texto5</Trans>
        },
        {
            image: buque_nyk_bun,
            title: <Trans>servicios.agenciamiento_slider_titulo6</Trans>,
            subtitle: <Trans>servicios.agenciamiento_slider_subtitulo6</Trans>,
            text: <Trans>servicios.agenciamiento_slider_texto6</Trans>
        },
        {
            image: buque_hapag_bun,
            title: <Trans>servicios.agenciamiento_slider_titulo7</Trans>,
            subtitle: <Trans>servicios.agenciamiento_slider_subtitulo7</Trans>,
            text: <Trans>servicios.agenciamiento_slider_texto7</Trans>
        },
        {
            image: ever_amarre,
            title: <Trans>servicios.agenciamiento_slider_titulo8</Trans>,
            subtitle: <Trans>servicios.agenciamiento_slider_subtitulo8</Trans>,
            text: <Trans>servicios.agenciamiento_slider_texto8</Trans>
        },
        {
            image: ever_navemar,
            title: <Trans>servicios.agenciamiento_slider_titulo9</Trans>,
            subtitle: <Trans>servicios.agenciamiento_slider_subtitulo9</Trans>,
            text: <Trans>servicios.agenciamiento_slider_texto9</Trans>
        },
    ];

    const settings = {
        dots: true,
        infinite: true,
        lazyLoad: true,
        speed: 1500,
        slidesToShow: 3,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 2000,
        pauseOnHover: true,
        prevArrow: <CustomPrevArrow />,
        nextArrow: <CustomNextArrow />,
        swipeToSlide: true,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 2
                }
            },
            {
                breakpoint: 767,
                settings: {
                    slidesToShow: 1
                }
            },
        ],
    };

    return (
        <div className="slider-container-agenciamiento">
            <div className="height-space-med"></div>
            <h2 className="text-center mb-5"><Trans>servicios.agenciamiento_slider_titulo</Trans></h2>
            <Slider {...settings} >
                {slides.map((slide, index) => (
                    <div key={index} className="slide">
                        <div className="container">
                            <div className="row">
                                <div className="d-flex flex-column justify-content-start"> 
                                <div className="image-container d-flex justify-content-center">
                                        <img src={slide.image} alt={`Slide ${index + 1}`} className="img-fluid" />
                                    </div>
                                    <div className="content-slider text-start">
                                        <p className="title-slider-agenciamiento">{slide.title}</p>
                                        <p className='subtitle-slider-agenciamiento-linea mb-0'>{slide.subtitle}</p>
                                        <p>{slide.text}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                ))}
            </Slider>
        </div>
    );
};

export default SliderAgenciamiento;
