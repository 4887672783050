import React, { useState } from 'react';
import '../styles/PerfilPage.css';
import { Trans } from 'react-i18next';
import config from '../../configuration/config';
import actualizarIcon from '../../assets/icons/Icono-actualizar-datos-navemar-gris.svg';
import fletesIcon from '../../assets/icons/Icono-certificaciones-de-fletes-navemar-gris.svg';
import estadoIcon from '../../assets/icons/Icono-estado-de-cuenta-navemar-gris.svg';
import facturasIcon from '../../assets/icons/Icono-impresion-de-facturas-y-recibos-navemar-gris.svg';
import mailIcon from '../../assets/icons/Icono-mail-gris.svg';
import actualizarIconActivo from '../../assets/icons/Icono-actualizar-datos-navemar-azul.svg';
import fletesIconActivo from '../../assets/icons/Icono-certificaciones-de-fletes-navemar-azul.svg';
import estadoIconActivo from '../../assets/icons/Icono-estado-de-cuenta-navemar-azul.svg';
import facturasIconActivo from '../../assets/icons/Icono-impresion-de-facturas-y-recibos-navemar-azul.svg';
import mailIconActivo from '../../assets/icons/Icono-mail-azul.svg';
import ActualizarPage from './ActualizarPage';
import { useAuth } from '../../AuthContext';
import { Link } from 'react-router-dom';


const PerfilPage = () => {

    const SidebarMenu = () => {
        const [activeItem, setActiveItem] = useState(1);

        const urlFletes = config.fletesNyk;
        const urlEstado = config.estadoNyk;
        const urlFacturas = config.facturasNyk;
        const urlMailMasivo = config.mailMasivo;

        const menuItems = [
            { id: 1, label: <Trans>perfil.actualizar</Trans>, icon: actualizarIcon, iconActive: actualizarIconActivo, linkto: '/perfil' },
            { id: 2, label: <Trans>perfil.fletes</Trans>, icon: fletesIcon, iconActive: fletesIconActivo, url: urlFletes, target: '_blank' },
            { id: 3, label: <Trans>perfil.estado</Trans>, icon: estadoIcon, iconActive: estadoIconActivo, url: urlEstado, target: '_blank' },
            { id: 4, label: <Trans>perfil.facturas</Trans>, icon: facturasIcon, iconActive: facturasIconActivo, url: urlFacturas, target: '_blank' },
            { id: 5, label: <Trans>perfil.mail</Trans>, icon: mailIcon, iconActive: mailIconActivo, url: urlMailMasivo, target: '_blank' }
        ];

        const { logout } = useAuth();
        const { user } = useAuth();
        const handleLogout = () => {
            logout();
        };

        const openLinks = (url) => {

            const form = document.getElementById('f_links_nyk');
            const nitInput = document.getElementById('PTWEB_NITPERFIL');
            const nitsistemaInput = document.getElementById('PTWEB_NITSISTEMAPERFIL');

            if (form && nitInput && nitsistemaInput) {
                form.action = url;
                form.target = '_blank';
                nitInput.value = user.nit;
                nitsistemaInput.value = user.nitsistema;
                form.submit();
            }
        };

        const filteredMenuItems = user.perfil === 'MAIL' ? menuItems : menuItems.filter(item => item.id !== 5);

        return (
            <div className="sidebar-menu">
                <div className="header-sidebar">
                    <h3>{user.nombreCliente}</h3>
                </div>
                <ul className="menu-list">
                    {filteredMenuItems.map(item => (
                        <li
                            key={item.id}
                            className={`menu-item ${activeItem === item.id ? 'active' : ''}`}
                            onClick={() => setActiveItem(item.id)}
                        >
                            {item.linkto ? (
                                <Link to={item.linkto} className="sidebar-link">
                                    <img src={activeItem === item.id ? item.iconActive : item.icon} alt={item.label} className="icon-sidebar" />
                                    <span className="label-sidebar">{item.label}</span>
                                </Link>
                            ) : (
                                <Link to="#" onClick={() => openLinks(item.url)} className="sidebar-link">
                                    <img src={activeItem === item.id ? item.iconActive : item.icon} alt={item.label} className="icon-sidebar" />
                                    <span className="label-sidebar">{item.label}</span>
                                </Link>
                            )}
                        </li>
                    ))}
                </ul>
                <div className="botones-side">
                    <button className="btn btn-primary" onClick={handleLogout}><Trans>perfil.logout</Trans></button>
                    <button className="btn btn-third d-none d-xl-block"><Link to="/inicio" className='link-perfil'><Trans>perfil.inicio</Trans></Link></button>
                </div>
                <form id="f_links_nyk" name="f_links_nyk" action="" method="post" style={{ display: 'none' }}>
                    <input type="hidden" name="PACCION" id="PACCIONPERFIL" value="M" />
                    <input type="hidden" name="PTWEB_NIT" id="PTWEB_NITPERFIL" />
                    <input type="hidden" name="PTWEB_NITSISTEMA" id="PTWEB_NITSISTEMAPERFIL" />
                    <input type="hidden" name="PENTRADA" id="PENTRADA" value="S" />
                </form>
            </div>
        );
    };

    return (
        <div className="container-fluid">
            <div className="custom-container">
                <div className="row">
                    <div className="col-12 col-xl-4 sidebar-perfil">
                        <SidebarMenu />
                    </div>
                    <div className="col-12 col-xl-8 sidebar-datos">
                        <ActualizarPage />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default PerfilPage;
