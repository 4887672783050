import React, { useState } from 'react';
import '../styles/LoginModal.css';
import { Link, useNavigate } from 'react-router-dom';
import { Trans } from 'react-i18next';
import config from '../../configuration/config';
import { useAuth } from '../../AuthContext';
import Alert from '@mui/material/Alert';
import BeatLoader from "react-spinners/BeatLoader";
import OlvidoPasswordModal from './OlvidoPasswordModal';

const LoginModal = ({ isOpen, onClose, rutaRedirect }) => {
    const [nit, setNit] = useState('');
    const [password, setPassword] = useState('');
    const { login } = useAuth();
    const [responseMessage, setResponseMessage] = useState();
    const [loginButton, setloginButton] = useState(true);
    const navigate = useNavigate();
    const [isPasswordResetOpen, setIsPasswordResetOpen] = useState(false);

    const handleLogin = async (event) => {
        event.preventDefault();
        setResponseMessage('');
        setloginButton(false);
        try {
            const response = await fetch(`${config.apiUrl}/login-navemar`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({ username: nit, password: password }),
                credentials: 'omit'
            });
            const data = await response.json();
            if (data.status === 'success') {
                console.log('Login successful');
                login({
                    nit: data.nit,
                    nombreCliente: data.nombre,
                    nitsistema: data.nitsistema,
                    perfil: data.perfil
                }, data.authToken, data.expiration);
                setloginButton(true);
                setNit('');
                setPassword('');
                navigate(rutaRedirect);
                onClose();
            } else {
                setResponseMessage(data.message);
                setloginButton(true);
            }
        } catch (error) {
            setResponseMessage('Login error: ' + error);
            setloginButton(true);
        }
    };

    const handleClose = () => {
        setResponseMessage('');
        setloginButton(true);
        setNit('');
        setPassword('');
        onClose();
    };

    const handlePasswordResetClick = () => {
        handleClose();
        setIsPasswordResetOpen(true);
    };

    return (
        <>
            {isOpen && (
                <div className="modal-overlay">
                    <div className="modal-container">
                        <div className="modal-margin">
                            <button className="modal-close" onClick={handleClose}>×</button>
                            <h1 className="modal-title mt-4"><Trans>inicio_sesion.titulo</Trans></h1>
                            <p className="modal-subtitle"><Trans>inicio_sesion.subtitulo</Trans></p>
                            {responseMessage && <Alert severity="error" onClose={() => { }}>{responseMessage}</Alert>}
                            <form className="login-form" onSubmit={handleLogin}>
                                <input
                                    type="text"
                                    className="input-field form-control"
                                    id="ptweb_nit"
                                    name="ptweb_nit"
                                    placeholder="NIT*"
                                    value={nit}
                                    onChange={(e) => setNit(e.target.value)}
                                    maxLength={12}
                                    autoComplete="off"
                                    required
                                />
                                <input
                                    type="password"
                                    className="password-field form-control"
                                    id="ptweb_clave"
                                    name="ptweb_clave"
                                    placeholder="Contraseña*"
                                    value={password}
                                    onChange={(e) => setPassword(e.target.value)}
                                    maxLength={8}
                                    required
                                />
                                {loginButton ? <button type="submit" className="login-button"><Trans>inicio_sesion.boton</Trans></button> : <BeatLoader color="#0082c6" size={15} margin={5} />}
                                <Link onClick={handlePasswordResetClick} className="forgot-password"><Trans>inicio_sesion.recordar_clave</Trans></Link>
                            </form>
                            <div className="register-section">
                                <span className="titulo-registro"><Trans>inicio_sesion.titulo_registro</Trans><Link to="/registrese" className="ms-3 register-link" onClick={onClose}><Trans>inicio_sesion.registro</Trans></Link> </span>
                            </div>
                        </div>
                        <div className="alternative-login">
                            <span className="borde-sesion"><Trans>inicio_sesion.sesion_otros</Trans></span>
                            <Link to="https://navesoftonline.com/serviciosenlinea.html" target="_blank" rel="noreferrer noopener" className="company-link">Hapag-Lloyd</Link>
                            <Link to="https://formsweb.navesoft.com/ords_ecocol_croma/WEB_MENU_PRINEVER" className="company-link" target="_blank" rel="noreferrer noopener">Evergreen</Link>
                        </div>
                    </div>
                </div>
            )}
            <OlvidoPasswordModal isOpen={isPasswordResetOpen} onClose={() => setIsPasswordResetOpen(false)} />
        </>
    );
};

export default LoginModal;
