import React from 'react';
import Slider from 'react-slick';
import { Trans } from 'react-i18next';
import { Link } from 'react-router-dom';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import './styles/SliderAgenciamiento.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft, faChevronRight } from '@fortawesome/free-solid-svg-icons';
import visitaSPIA from '../assets/Visita del personal de Navemar al terminal de SPIA-NAVEMAR.png';
import encuentro from '../assets/Encuentro estrategico entre delegados de Cartagena y Santa Marta-Navemar.png';
import tallerLiderazgo from '../assets/Taller de Cocina con Enfoque Transformador en Liderazgo para Potenciar Equipos-Navemar.png';
import certificacionISO from '../assets/Entrega de la Certificacion de Sistemas de Gestion ISO 28000.jpg';
import auditoria from '../assets/Auditoria interna en total cumplimiento de los estandares de ISO 28000-Navemar.png';
import equidad from '../assets/Celebracion del Dia de la Equidad de Genero, Diversidad e Inclusion en Navemar.png';
import bienvenidaBuque from '../assets/Equipo de Navemar dando la bienvenida a un buque en el Puerto de Barranquilla.png';


const SliderDiversidad = () => {

    const CustomPrevArrow = ({ onClick }) => (
        <div className="custom-prev-arrow" onClick={onClick}>
            <FontAwesomeIcon icon={faChevronLeft} />
        </div>
    );

    const CustomNextArrow = ({ onClick }) => (
        <div className="custom-next-arrow" onClick={onClick}>
            <FontAwesomeIcon icon={faChevronRight} />
        </div>
    );

    const slides = [
        {
            image: visitaSPIA,
            title: <Trans>diversidad.diversidad_slider_titulo1</Trans>,
            text: <Trans>diversidad.diversidad_slider_texto1</Trans>
        },
        {
            image: encuentro,
            title: <Trans>diversidad.diversidad_slider_titulo2</Trans>,
            text: <Trans>diversidad.diversidad_slider_texto2</Trans>
        },
        {
            image: tallerLiderazgo,
            title: <Trans>diversidad.diversidad_slider_titulo3</Trans>,
            text: <Trans>diversidad.diversidad_slider_texto3</Trans>
        },
        {
            image: certificacionISO,
            title: <Trans>diversidad.diversidad_slider_titulo4</Trans>,
            text: <Trans>diversidad.diversidad_slider_texto4</Trans>
        },
        {
            image: auditoria,
            title: <Trans>diversidad.diversidad_slider_titulo5</Trans>,
            text: <Trans>diversidad.diversidad_slider_texto5</Trans>
        },
        {
            image: equidad,
            title: <Trans>diversidad.diversidad_slider_titulo6</Trans>,
            text: <Trans>diversidad.diversidad_slider_texto6</Trans>
        },
        {
            image: bienvenidaBuque,
            title: <Trans>diversidad.diversidad_slider_titulo7</Trans>,
            text: <Trans>diversidad.diversidad_slider_texto7</Trans>
        },
    ];

    const settings = {
        dots: true,
        infinite: true,
        lazyLoad: true,
        speed: 1500,
        slidesToShow: 3,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 2000,
        pauseOnHover: true,
        prevArrow: <CustomPrevArrow />,
        nextArrow: <CustomNextArrow />,
        swipeToSlide: true,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 2
                }
            },
            {
                breakpoint: 767,
                settings: {
                    slidesToShow: 1
                }
            },
        ],
    };

    return (
        <div className="slider-container-agenciamiento">
            <div className="height-space-med"></div>
            <h4 className="text-center mb-5"><Trans>diversidad.diversidad_slider_titulo</Trans></h4>
            <Slider {...settings} >
                {slides.map((slide, index) => (
                    <div key={index} className="slide mb-5">
                        <div className="container">
                            <div className="row">
                                <div className="d-flex flex-column justify-content-start"> 
                                <div className="image-container d-flex justify-content-center">
                                        <img src={slide.image} alt={`Slide ${index + 1}`} className="img-fluid" />
                                    </div>
                                    <div className="content-slider-aerolineas text-start">
                                        <p className="title-slider-agenciamiento">{slide.title}</p>
                                        <p>{slide.text}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                ))}
            </Slider>
            <div className="height-space-min"></div>
            <div className="container-fluid custom-container text-center mt-4">
                <Link to="/quienes-somos"><button className="btn btn-primary"><Trans>diversidad.boton_slider</Trans></button></Link>
            </div>
            <div className="height-space-min"></div>
        </div>
    );
};

export default SliderDiversidad;
