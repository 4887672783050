import React from 'react';
import { Row, Col } from 'react-bootstrap';
import sucursal from '../assets/icons/Icono-ubicacion-Azul-Navemar.svg';
import { useTranslation } from 'react-i18next';

function Sucursales() {
    const { t } = useTranslation();
    const sucursales = [
        {
            image: sucursal,
            title: t('contacto.barranquilla_titulo'),
            telefono: t('contacto.barranquilla_telefono'),
            direccion: t('contacto.barranquilla_direccion'),
            ciudad: t('contacto.barranquilla_ciudad'),
        },
        {
            image: sucursal,
            title: t('contacto.bogota_titulo'),
            telefono: t('contacto.bogota_telefono'),
            direccion: t('contacto.bogota_direccion'),
            ciudad: t('contacto.bogota_ciudad'),
        },
        {
            image: sucursal,
            title: t('contacto.buenaventura_titulo'),
            telefono: t('contacto.buenaventura_telefono'),
            direccion: t('contacto.buenaventura_direccion'),
            ciudad: t('contacto.buenaventura_ciudad'),
        },
        {
            image: sucursal,
            title: t('contacto.cartagena_titulo'),
            telefono: t('contacto.cartagena_telefono'),
            direccion: t('contacto.cartagena_direccion'),
            ciudad: t('contacto.cartagena_ciudad'),
        },
        {
            image: sucursal,
            title: t('contacto.santamarta_titulo'),
            telefono: t('contacto.santamarta_telefono'),
            direccion: t('contacto.santamarta_direccion'),
            ciudad: t('contacto.santamarta_ciudad'),
        }
    ];


    const renderSucursales = () => {
        return (
            <Row className="justify-content-center">
                {sucursales.map((item, index) => (
                    <Col key={index} lg={2} md={4} sm={4} xs={4} className={`p-2 p-xl-4 custom-width-sucursales ${index !== 0 ? 'border-left' : ''}`} >
                        <div className={`custom-div ${index !== 0 ? 'border-left' : ''} ${index === 0 ? 'border-left-1' : ''} ${index === 3 ? 'border-left-2' : ''}`}>
                            <Row className="align-items-start">
                                <Col xs={2} xl={2} className="flex-column justify-content-start align-items-start sucursales-div" >
                                    <img src={item.image} alt="Imagen" className="iconos-contacto" />
                                </Col>
                                <Col xs={10} xl={10} className="d-flex flex-column justify-content-start align-items-start sucursales-texto">
                                    <div className="fw-bold mb-4">{item.title}</div>
                                    <div><a href={`tel:${item.telefono}`} className="link-telefono">{item.telefono}</a></div>
                                    <div>{item.direccion}</div>
                                    <div>{item.ciudad}</div>
                                </Col>
                            </Row>
                        </div>
                    </Col>
                ))}
            </Row>
        );
    };

    return (
        <div className='container-fluid bgclaro'>
            <div className='custom-container'>
                <div className="height-space-med"></div>
                <div className="d-flex flex-column justify-content-center align-items-center">
                    <div className="row mb-5">
                        <div id="sucursales" className='col-12 d-flex align-items-center justify-content-center'>
                            <h3 className="text-center">{t('contacto.nuestras_oficinas')}</h3>
                        </div>
                    </div>
                </div>
                {renderSucursales()}
                <div className="height-space-med"></div>
            </div>
        </div>
    );
};

export default Sucursales;
