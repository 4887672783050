import React, { useState, useEffect } from 'react';
import './App.css';
import { BrowserRouter as Router, Routes, Route, Navigate, useLocation } from 'react-router-dom';
import Header from './components/Header';
import HomePage from './components/pages/HomePage';
import ContactPage from './components/pages/ContactPage';
import AboutPage from './components/pages/AboutPage';
import NotFoundPage from './components/pages/NotFoundPage';
import Footer from './components/Footer';
import PoliticaDatos from './components/pages/PoliticaDatos';
import PoliticaPrevencionLavadoActivos from './components/pages/PoliticaPrevencionLavadoActivos';
import TerminosCondiciones from './components/pages/TerminosCondiciones';
import ResponsabilidadSocialPage from './components/pages/ResponsabilidadSocialPage';
import ServicesPage from './components/pages/ServicesPage';
import AgenciamientoPortuarioPage from './components/pages/AgenciamientoPortuarioPage';
import RepresentacionAerolineasPage from './components/pages/RepresentacionAerolineasPage';
import SolucionesTecnologiaPage from './components/pages/SolucionesTecnologiaPage';
import BPOPage from './components/pages/BPOPage';
import DiversidadPage from './components/pages/DiversidadPage';
import PuertosMaritimosPage from './components/pages/PuertosMaritimosPage';
import NuestrosClientesPage from './components/pages/NuestrosClientesPage';
import PreguntasPage from './components/pages/PreguntasPage';
import TramitesPage from './components/pages/TramitesPage';
import NoticiasFormato1 from './components/NoticiasFormato1';
import NoticiasFormato2 from './components/NoticiasFormato2';
import NoticiasFormato3 from './components/NoticiasFormato3';
import { AuthProvider, useAuth } from './AuthContext';
import RegistroPage from './components/pages/RegistroPage';
import PerfilPage from './components/pages/PerfilPage';
import { setCookie, getCookie } from './cookies';
import CookiePage from './components/CookiePage';
import ReactGA from 'react-ga4';
import RestablecerPasswordPage from './components/pages/RestablecerPasswordPage';
import HeaderRestablecer from './components/HeaderRestablecer';


function App() {

  const [showModal, setShowModal] = useState(false);

  const AnalyticsTracker = () => {
    const location = useLocation();

    useEffect(() => {
      ReactGA.send({ hitType: "pageview", page: location.pathname + location.search });
    }, [location]);

    return null;
  };

  useEffect(() => {
    const cookieConsent = getCookie('cookieConsent');
    const localConsent = localStorage.getItem('cookieConsent');

    if (!cookieConsent || !localConsent) {
      setShowModal(true);
    }
  }, []);

  const handleAccept = () => {
    setCookie('cookieConsent', 'true', { expires: 30 });
    localStorage.setItem('cookieConsent', 'true');
    setShowModal(false);
  };

  const handleReject = () => {
    setShowModal(false);
  };

  return (
    <Router>
      <AuthProvider>
        <AnalyticsTracker />
        <div>
          {showModal && <CookiePage onAccept={handleAccept} onReject={handleReject} />}
          {window.location.pathname === '/restablecer-clave' ? (
            <HeaderRestablecer />
          ) : (
            <Header />
          )}
          <div className="container-full-width">
            <Routes>
              <Route path="/" element={<HomePage />} />
              <Route path="/inicio" element={<HomePage />} />
              <Route path="/contactenos" element={<ContactPage />} />
              <Route exact path="/quienes-somos" element={<AboutPage />} />
              <Route path="/responsabilidad-social-y-empresarial" element={<ResponsabilidadSocialPage />} />
              <Route path="/diversidad-equidad-de-genero-e-inclusion" element={<DiversidadPage />} />
              <Route path="/politica-de-tratamiento-de-datos-personales" element={<PoliticaDatos />} />
              <Route path="/manual-de-prevencion-de-lavado-de-activos-y-financiacion-del-terrorismo" element={<PoliticaPrevencionLavadoActivos />} />
              <Route path="/terminos-y-condiciones" element={<TerminosCondiciones />} />
              <Route path="/nuestros-servicios" element={<ServicesPage />} />
              <Route path="/agenciamiento-maritimo-comercial-y-portuario" element={<AgenciamientoPortuarioPage />} />
              <Route path="/puertos-maritimos" element={<PuertosMaritimosPage />} />
              <Route path="/representacion-de-aerolineas" element={<RepresentacionAerolineasPage />} />
              <Route path="/soluciones-de-tecnologia" element={<SolucionesTecnologiaPage />} />
              <Route path="/gestion-de-liberacion-facturacion-y-recaudo-de-gastos-locales" element={<BPOPage />} />
              <Route path="/nuestros-clientes" element={<NuestrosClientesPage />} />
              <Route path="/preguntas-frecuentes" element={<PreguntasPage />} />
              <Route path="/tramites-y-consultas" element={<TramitesPage />} />
              <Route path="/trasbordo-de-nyk-roro-en-el-puerto-de-buenaventura" element={<NoticiasFormato1 />} />
              <Route path="/buque-de-evergreen-atraca-en-el-puerto-de-barranquilla" element={<NoticiasFormato2 />} />
              <Route path="/hapag-lloyd-inicia-operaciones-en-turbo" element={<NoticiasFormato3 />} />
              <Route path="/registrese" element={<RegistroPage />} />
              <Route path="/restablecer-clave" element={<RestablecerPasswordPage />} />
              <Route path="/perfil" element={<ProtectedRoute><PerfilPage /></ProtectedRoute>} />
              <Route path="*" element={<NotFoundPage />} />
            </Routes>
          </div>
          {window.location.pathname === '/restablecer-clave' ? (
            ""
          ) : (
            <Footer />
          )}
          
        </div>
      </AuthProvider>
    </Router>
  );

}

const ProtectedRoute = ({ children }) => {
  const { user } = useAuth();
  if (!user) {
    return <Navigate to="/" />;
  }
  return children;
};

export default App;