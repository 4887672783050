import React, { createContext, useContext, useState, useEffect } from 'react';

const AuthContext = createContext(null);

export const AuthProvider = ({ children }) => {
    const [user, setUser] = useState(() => {
        const savedUserData = localStorage.getItem('userData');
        return savedUserData ? JSON.parse(savedUserData) : null;
    });

    useEffect(() => {
        if (user) {
            localStorage.setItem('userData', JSON.stringify(user));
        } else {
            localStorage.removeItem('userData');
        }
    }, [user]);

    useEffect(() => {
        const checkTokenExpiration = () => {
            const now = new Date();
            if (user && new Date(user.expiration) <= now) {
                logout();
            }
        };

        const intervalId = setInterval(checkTokenExpiration, 1000 * 60 * 5);
        return () => clearInterval(intervalId);
    }, [user]);

    const login = (userData, authToken, expiration) => {
        const userWithToken = {
            ...userData,
            authToken,
            expiration
        };
        setUser(userWithToken);
        document.cookie = `authToken=${authToken}; expires=${new Date(expiration).toUTCString()}; path=/;`;
    };

    const logout = () => {
        setUser(null);
        document.cookie = "authToken=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
        localStorage.removeItem('userData');
    };

    return (
        <AuthContext.Provider value={{ user, login, logout }}>
            {children}
        </AuthContext.Provider>
    );
};

export const useAuth = () => useContext(AuthContext);
