import React from 'react';
import { useTranslation } from 'react-i18next';
import maritimo from '../assets/icons/Icono_maritimo.svg'
import aerolinea from '../assets/icons/Icono_representacion_aerolineas.svg'
import tecnologia from '../assets/icons/Icono-Soluciones de tecnologias-Navemar-Navesoft.svg'
import demoras from '../assets/icons/Icono_facturacion_demoras.svg'
import { Link } from 'react-router-dom';

/**
 * Página de "ServiciosComponent".
 * @component
 * @returns {JSX.Element} Componente con los servicios que brinda de la empresa "ServiciosComponent".
 */
function ServiciosComponent(props) {
    const { t } = useTranslation();

    const services = [
        {
            image: maritimo,
            title: t('home.titulo_maritimo'),
            description: t('home.descripcion_maritimo'),
            buttonText: t('home.boton_nuestros_servicios'),
            url:"/agenciamiento-maritimo-comercial-y-portuario"
        },
        {
            image: aerolinea,
            title: t('home.titulo_aerolinea'),
            description: t('home.descripcion_aerolinea'),
            buttonText: t('home.boton_nuestros_servicios'),
            url:"/representacion-de-aerolineas"
        },
        {
            image: tecnologia,
            title: t('home.titulo_tecnologia'),
            description: t('home.descripcion_tecnologia'),
            buttonText: t('home.boton_nuestros_servicios'),
            url:"/soluciones-de-tecnologia"
        },
        {
            image: demoras,
            title: t('home.titulo_demoras'),
            description: t('home.descripcion_demoras'),
            buttonText: t('home.boton_nuestros_servicios'),
            url:"/gestion-de-liberacion-facturacion-y-recaudo-de-gastos-locales"
        }
    ];

    return services.map((item, index) => (
        <div key={index} className='col-12 col-sm-6 col-md-4 col-md-6 col-xl-3 p-4 p-xl-2'>
            <div className={`card card-services${props.text||''}`}>
                <div className="card-body text-center">
                    <div className="card-title mb-4">
                        <img src={item.image} alt={index} className="iconos" />
                    </div>
                    <p className="subtitulo">{item.title}</p>
                    <p className="card-text">{item.description}</p>
                    <div className="d-grid gap-2 justify-content-center">
                        <Link to={item.url}><button className="btn btn-primary" type="button">{item.buttonText}</button></Link>
                    </div>
                </div>
            </div>
        </div>
    ));
};


export default ServiciosComponent;