import React, { useEffect } from 'react';
import { useTranslation, Trans } from 'react-i18next';
import { Link, useLocation } from 'react-router-dom';
import SliderHomePage from '../SliderHomePage';
import Indicators from '../Indicators';
import SlideClient from '../SlideClients';
import SliderNews from '../SliderNews';
import ContactForm from '../ContactForm';
import ServiciosComponent from '../ServiciosComponent';
import buque from '../../assets/agenciamiento_buque _contenedores.png'
import logo_navesoft from '../../assets/Logo_Navesoft.svg'
import logo_iso from '../../assets/Logo-Iso28000.svg'
import logo_soc2 from '../../assets/Logo-AICPA_SOC2_TYPE2.svg'
import logo_asonav from '../../assets/Logo_Asonav-Partner.png'
import logo_oracle from '../../assets/Logo_Oracle-Partner.png'

import analdex from '../../assets/otros_enlaces/Logo-Asociacion-Navemar.png';
import anato from '../../assets/otros_enlaces/Logo-Anato-Navemar.png';
import dian from '../../assets/otros_enlaces/Logo-DIAN-Navemar.png';
import dimar from '../../assets/otros_enlaces/Logo-DIMAR-Navemar.png';
import fitac from '../../assets/otros_enlaces/Logo-FITAC-Navemar.png';
import iata from '../../assets/otros_enlaces/Logo-IATA-Navemar.png';
import puertobarranquilla from '../../assets/otros_enlaces/Logo-Puerto-barranquilla-Navemar.png';
import puertobuenaventura from '../../assets/otros_enlaces/Logo-Sociedad-Portuaria-Buenaventura-Navemar.png';
import puertocartagena from '../../assets/otros_enlaces/Logo-Puerto-Cartagena-Navemar.png';
import puertosantamarta from '../../assets/otros_enlaces/Logo-Puerto-Santa-Marta-Navemar.png';
import uniban from '../../assets/otros_enlaces/Logo-Uniban-Navemar.png';
import puertobahia from '../../assets/otros_enlaces/Logo-Puerto-Bahia-Navemar.png';
import aguadulce from '../../assets/otros_enlaces/Logo-Sociedad-Potuaria-Agua-Dulce-Navemar.png';
import banacol from '../../assets/otros_enlaces/Logo-Banacol-Navemar.png';
import compas from '../../assets/otros_enlaces/Logo-Compas-Navemar.png';
import MetaDescription from '../MetaDescription';

/**
 * Representa la página principal de la aplicación.
 * @component
 * @returns {JSX.Element} El componente HomePage con sus subcomponentes.
 */
function HomePage() {
    const { t } = useTranslation();
    const location = useLocation();

    useEffect(() => {
        if (location.hash) {
          const id = location.hash.replace('#', '');
          const element = document.getElementById(id);
          if (element) {
            element.scrollIntoView({ behavior: 'smooth' });
          }
        } else {
          window.scrollTo({ top: 0, behavior: 'smooth' });
        }
      }, [location]);

    /**
     * Componente ImageWithText que muestra una imagen con texto a su lado.
     * @param {object} props - Propiedades del componente.
     * @param {string} props.imageUrl - URL de la imagen a mostrar.
     * @param {string} props.title - Título del texto.
     * @param {string} props.subtitle - Subtítulo del texto.
     * @param {string} props.description - Descripción asociada.
     * @param {string} props.buttonText - Texto del botón.
     * @returns {JSX.Element} Un componente con imagen y texto.
     */

    const Aboutus = ({ imageUrl, title, subtitle, description, buttonText }) => {
        return (
            <div className='container-fluid custom-container'>
                <div className="height-space-med"></div>
                <div className='row imghome'>
                    <div className='col-sm-12 d-block d-sm-none text-justify'>
                        <h2>{title}</h2>
                        <p className='subtitulo'>{subtitle}</p>
                    </div>
                    <div className='col-md-6 col-sm-12 text-center mt-4'>
                        <img src={imageUrl} alt="Quienes Somos" className="img-fluid me-md-5" />
                    </div>
                    <div className='col-md-6 col-sm-12 d-flex align-items-center'>
                        <div className="text-min">
                            <h1 className='d-none d-sm-block'>{title}</h1>
                            <p className='subtitulo d-none d-sm-block'>{subtitle}</p>
                            <p className='mt-5 mt-md-auto'>{description}</p>
                            <Link to="/quienes-somos"><button className="btn btn-primary mt-3">{buttonText}</button></Link>
                        </div>
                    </div>
                </div>
            </div>
        );
    };

    /**
     * Componente Indicator que muestra indicadores numéricos.
     * @returns {JSX.Element} Un conjunto de indicadores dentro de una estructura de columnas.
     */
    const Indicator = () => {
        return (
            <div className='container-fluid justify-content-center custom-container mt-5 mt-xl-0'>
            <div className="height-space custom-container mt-6 mt-xl-0"></div>
            <div className='row text-center justify-content-center'>
                    <div className='col-6 col-md-2 ancho-indicadores'>
                        <h3><Indicators endNumber={131173} suffix="" decimals={0} /></h3>
                        <p><Trans>home.indicador</Trans></p>
                    </div>
                    <div className='col-6 col-md-2 ancho-indicadores'>
                        <h3><Indicators endNumber={815} suffix="" decimals={0} /></h3>
                        <p><Trans>home.indicador1</Trans></p>
                    </div>
                    <div className='col-6 col-md-2 ancho-indicadores'>
                        <h3><Indicators endNumber={7.5} suffix="B" decimals={1} /></h3>
                        <p><Trans>home.indicador2</Trans></p>
                    </div>
                    <div className='col-6 col-md-2 ancho-indicadores'>
                        <h3><Indicators endNumber={370593} suffix="" decimals={0} /></h3>
                        <p><Trans>home.indicador3</Trans></p>
                    </div>
                    <div className='col-6 col-md-2 ancho-indicadores'>
                        <h3><Indicators endNumber={698934} suffix="" decimals={0} /></h3>
                        <p><Trans>home.indicador4</Trans></p>
                    </div>
                </div>
                <div className="height-space-med"></div>
            </div>
        );
    };

    const OurServices = () => {
        return (
            <div className='container-fluid bgclaro'>
                <div className='custom-container'>
                    <div className="height-space-med"></div>
                    <div className='row'>
                        <div className='col-12 col-xl-10 mt-4'>
                            <h3 className="mb-5">{t('home.titulo_nuestros_servicios')}</h3>
                            <p className="text-justify">{t('home.texto_nuestros_servicios')}</p>
                        </div>
                    </div>
                    <div className='row mb-5'></div>
                    <div className='row'>
                        <ServiciosComponent />
                    </div>
                    <div className='row'></div>
                    <div className="height-space-med"></div>

                </div>
            </div>
        );
    };

    const Navesoft = () => {
        return (
            <div className='container-fluid custom-container'>
                <div className="height-space-med"></div>
                <div className="d-flex flex-column justify-content-center align-items-center mt-3">
                    <div className="logo-container mb-5">
                        <Link to="https://navesoftonline.com/"><img src={logo_navesoft} alt="Logo" className="logo-img" /></Link>
                    </div>
                    <div className="row mb-4 mx-auto mx-xl-5" >
                        <div className='col-12 d-flex align-items-center justify-content-center'>
                            <h3 className="text-center"><Trans i18nKey="home.navesoft" components={{ text_blue: <span className="texto-azul"/>}} /></h3>
                        </div>
                    </div>
                    <div className="row" >
                        <div className='col-12 d-flex align-items-center justify-content-center'>
                            <Link to="https://navesoftonline.com/"><button className="btn btn-primary" type="button">{t('home.boton_navesoft')}</button></Link>
                        </div>
                    </div>
                </div>
                <div className="height-space-med"></div>
            </div >
        );
    };

    const Certifications = () => {
        return (
            <div className='container-fluid certifications-container-border'>
                <div className="certifications-container custom-container">
                    <div className="container-fluid ">
                        <div className="row d-flex align-items-center" >
                            <div className="col-12 col-md-6 d-flex flex-column mt-5 mt-md-0 ">
                                <h4>{t('home.certificaciones')}</h4>
                                <p><Trans i18nKey="home.certificaciones_texto" components={{ text_bold: <span className="texto-bold"/>}} /></p>
                            </div>
                            <div className="col-md-6 d-flex justify-content-center justify-content-xl-end">
                                <img src={logo_soc2} alt="Logo" className="logo-certification img-fluid me-3 me-xl-0" />
                                <img src={logo_iso} alt="Logo" className="logo-certification img-fluid me-0" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        );
    };

    const Clients = () => {
        return (
            <div className='container-fluid bgclaro'>
                <div className='custom-container'>
                    <div className="height-space-med"></div>
                    <div className="d-flex flex-column justify-content-center align-items-center">
                        <div className="row mb-4 mt-0 mx-xl-5">
                            <div className='col-12 d-flex align-items-center justify-content-center'>
                                <h4 className="text-center">{t('home.titulo_clientes')}</h4>
                            </div>
                        </div>
                        <div className="row mb-4">
                            <div className='col-12 d-flex align-items-center justify-content-center text-center'>
                                <p>{t('home.texto_clientes')}</p>
                            </div>
                        </div>
                    </div>
                    <SlideClient />
                    <div className="row justify-content-center">
                        <div className="col-12 col-md-auto d-flex justify-content-center mb-3">
                            <Link to="/nuestros-clientes"><button className="btn btn-third" style={{ width: '193px' }}>{t('clientes.boton_clientes')}</button></Link>
                        </div>
                        <div className="col-12 col-md-auto d-flex justify-content-center mb-3">
                            <Link to="/contactenos">
                            <button className="btn btn-primary" style={{ width: '193px' }}>{t('clientes.boton_cliente_unirse')}</button>
                            </Link>
                        </div>
                    </div>
                    <div className="height-space-med"></div>
                </div>
            </div>
        );
    };

    const Partners = () => {
        return (
            <div className='container-fluid certifications-container-border'>
                <div className="certifications-container custom-container">
                    <div className="container-fluid">
                        <div className="row align-items-center">
                            <div className="col-12 col-md-6 mt-5 mt-md-0 d-flex flex-column justify-content-center">
                                <h4>{t('partners.titulo_partners')}</h4>
                                <p>{t('partners.texto_partners')}</p>
                                <div className="d-flex justify-content-center justify-content-md-start mt-3 mt-md-4 d-none d-md-block">
                                    <Link to="/contactenos">
                                        <button className="btn btn-primary">{t('partners.boton_partners')}</button>
                                    </Link>
                                </div>
                            </div>
                            <div className="col-12 col-md-6 mt-4 mt-md-0 d-flex justify-content-center justify-content-md-end">
                                <div className="container-partner d-flex align-items-center">
                                    <img src={logo_asonav} alt="Logo" className="img-partners" />
                                </div>
                                <div className="container-partner d-flex align-items-center">
                                    <img src={logo_oracle} alt="Logo" className="img-partners" />
                                </div>
                            </div>
                            <div className="col-12 d-md-none d-flex justify-content-center mt-5">
                                <button className="btn btn-primary">{t('partners.boton_partners')}</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    };

    const News = () => {
        return (
            <div id="blog" className='container-fluid bgclaro'>
                <div className='custom-container'>
                    <div className="height-space-med"></div>
                    <div className="d-flex flex-column justify-content-center align-items-center">
                        <div className="row mb-4">
                            <div className='col-12 d-flex align-items-center justify-content-center'>
                                <h4 className="text-center">{t('news.titulo')}</h4>
                            </div>
                        </div>
                    </div>
                    <SliderNews />
                    {/*<div className="d-flex justify-content-center mt-5">
                        <button className="btn btn-primary me-4">{t('news.boton')}</button>
                    </div>*/}
                    <div className="height-space-med"></div>
                </div>
            </div>
        );
    };

    const links = [
        { image: aguadulce, url: "https://www.puertoaguadulce.com", target: "_blank" },
        { image: analdex, url: "https://www.analdex.org", target: "_blank" },
        { image: anato, url: "https://anato.org", target: "_blank" },
        { image: banacol, url: "https://www.banacol.co", target: "_blank" },
        { image: compas, url: "https://www.compas.com.co/es", target: "_blank" },
        { image: dian, url: "https://www.dian.gov.co", target: "_blank" },
        { image: dimar, url: "https://www.dimar.mil.co", target: "_blank" },
        { image: fitac, url: "https://fitac.net", target: "_blank" },
        { image: iata, url: "https://www.iata.org", target: "_blank" },
        { image: puertobahia, url: "https://puertobahia.com.co", target: "_blank" },
        { image: puertobarranquilla, url: "https://www.puertodebarranquilla.com", target: "_blank" },
        { image: puertobuenaventura, url: "http://www.sprbun.com/web/portal/inicio", target: "_blank" },
        { image: puertocartagena, url: "https://www.puertocartagena.com/es", target: "_blank" },
        { image: puertosantamarta, url: "https://www.spsm.com.co", target: "_blank" },
        { image: uniban, url: "https://www.uniban.com", target: "_blank" },
    ];

    const OtherLinksGrid = () => {
        return (
            <div className='container-fluid custom-container'>
                <div className="height-space-med"></div>
                <div className='row justify-content-center text-center'>
                    <div className='col-12 col-md-9'>
                        <h4 className="mb-4">{t('enlaces.titulo')}</h4>
                    </div>
                </div>
                <div className='row mb-5'></div>
                <div className='enlaces'>
                    <div className="images-container">
                        {links.map((link, index) => (
                            <div key={index} className='container-enlaces'>
                                <a href={link.url} target={link.target} rel="noopener noreferrer">
                                    <img src={link.image} alt={`Imagen ${index + 1}`} className="img-fluid img-enlaces" />
                                </a>
                            </div>
                        ))}
                    </div>
                </div>
                <div className="height-space-med"></div>
            </div>
        );
    }

    return (
        <div>
            <MetaDescription content={t('home.meta_descripcion')}/>
            <SliderHomePage />
            <Aboutus
                imageUrl={buque}
                title={t('home.titulo_quienes_somos')}
                subtitle={t('home.subtitulo_quienes_somos')}
                description={<Trans i18nKey="home.descripcion_quienes_somos"components={{ text_bold: <span className="texto-bold" />, text_blue: <Link className="texto-azul-link" to='/quienes-somos#nuestros-valores'/> }}/>
                }
                buttonText={t('home.btn2')}
                />

            <Indicator />
            <OurServices />
            <Navesoft />
            <Certifications />
            <Clients />
            <Partners />
            <News />
            <OtherLinksGrid />
            <ContactForm />
        </div>

    );

};

export default HomePage;
