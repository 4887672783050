import React, { useEffect } from 'react';
import MetaDescription from '../MetaDescription';
import '../styles/RepresentacionAerolineasPage.css';
import { Trans } from 'react-i18next';
import { Link } from 'react-router-dom';
import Indicators from '../Indicators';
import puerto_buenaventura from '../../assets/Puerto-de-Buenaventura-Colombia-Navemar.png';
import puerto_cartagena from '../../assets/Puerto-de-Cartagena-Colombia-Navemar.png';
import puerto_santa_marta from '../../assets/Puerto-de-Santa Marta-Colombia-Navemar.png';
import puerto_barranquilla from '../../assets/Puerto-de-Barranquilla-Colombia-Navemar.png';

function PuertosMaritimosPage() {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const IndicadorBun = () => {
        return (
            <div className="container-fluid">
                <div className='row text-justify my-3 my-xl-5'>
                    <div className='col-12 col-md-4 border-left py-2 ps-3 mt-3 mt-xl-0'>
                        <h3><Indicators endNumber={348} suffix="" decimals={0} time={30} /></h3>
                        <p className="titulo_indicador my-3"><Trans>servicios.puertos_maritimos_indicador_titulo_bun_buque</Trans></p>
                        <p className="pe-5"><Trans>servicios.puertos_maritimos_indicador_texto_bun_buque</Trans></p>
                    </div>
                    <div className='col-12 col-md-4 border-left py-2 ps-3 mt-3 mt-xl-0'>
                        <h3><Indicators endNumber={47345} suffix="" decimals={0} time={30} /></h3>
                        <p className="titulo_indicador my-3 "><Trans>servicios.puertos_maritimos_indicador_titulo_bun_impo</Trans></p>
                        <p className="pe-5"><Trans>servicios.puertos_maritimos_indicador_texto_bun_impo</Trans></p>
                    </div>
                    <div className='col-12 col-md-4 border-left py-2 ps-3 mt-3 mt-xl-0'>
                        <h3><Indicators endNumber={42687} suffix="" decimals={0} time={30} /></h3>
                        <p className="titulo_indicador my-3 "><Trans>servicios.puertos_maritimos_indicador_titulo_bun_expo</Trans></p>
                        <p className="pe-5"><Trans>servicios.puertos_maritimos_indicador_texto_bun_expo</Trans></p>
                    </div>
                </div>
            </div>
        );
    };

    const IndicadorCtg = () => {
        return (
            <div className="container-fluid">
                <div className='row text-justify my-5'>
                    <div className='col-12 col-md-4 border-left py-2 ps-3 mt-5 mt-xl-0'>
                        <h3><Indicators endNumber={160} suffix="" decimals={0} time={30} /></h3>
                        <p className="titulo_indicador my-3"><Trans>servicios.puertos_maritimos_indicador_titulo_ctg_buque</Trans></p>
                        <p className="pe-5"><Trans>servicios.puertos_maritimos_indicador_texto_ctg_buque</Trans></p>
                    </div>
                    <div className='col-12 col-md-4 border-left py-2 ps-3 mt-5 mt-xl-0'>
                        <h3><Indicators endNumber={3417} suffix="" decimals={0} time={30} /></h3>
                        <p className="titulo_indicador my-3 "><Trans>servicios.puertos_maritimos_indicador_titulo_ctg_impo</Trans></p>
                        <p className="pe-5"><Trans>servicios.puertos_maritimos_indicador_texto_ctg_impo</Trans></p>
                    </div>
                    <div className='col-12 col-md-4 border-left py-2 ps-3 mt-5 mt-xl-0'>
                        <h3><Indicators endNumber={2520} suffix="" decimals={0} time={30} /></h3>
                        <p className="titulo_indicador my-3 "><Trans>servicios.puertos_maritimos_indicador_titulo_ctg_expo</Trans></p>
                        <p className="pe-5"><Trans>servicios.puertos_maritimos_indicador_texto_ctg_expo</Trans></p>
                    </div>
                </div>
            </div>
        );
    };

    const IndicadorSmr = () => {
        return (
            <div className="container-fluid">
                <div className='row text-justify my-5'>
                    <div className='col-12 col-md-4 border-left py-2 ps-3 mt-5 mt-xl-0'>
                        <h3><Indicators endNumber={150} suffix="" decimals={0} time={30} /></h3>
                        <p className="titulo_indicador my-3"><Trans>servicios.puertos_maritimos_indicador_titulo_smr_buque</Trans></p>
                        <p className="pe-5"><Trans>servicios.puertos_maritimos_indicador_texto_smr_buque</Trans></p>
                    </div>
                    <div className='col-12 col-md-4 border-left py-2 ps-3 mt-5 mt-xl-0'>
                        <h3><Indicators endNumber={4064} suffix="" decimals={0} time={30} /></h3>
                        <p className="titulo_indicador my-3 "><Trans>servicios.puertos_maritimos_indicador_titulo_smr_impo</Trans></p>
                        <p className="pe-5"><Trans>servicios.puertos_maritimos_indicador_texto_smr_impo</Trans></p>
                    </div>
                    <div className='col-12 col-md-4 border-left py-2 ps-3 mt-5 mt-xl-0'>
                        <h3><Indicators endNumber={3831} suffix="" decimals={0} time={30} /></h3>
                        <p className="titulo_indicador my-3 "><Trans>servicios.puertos_maritimos_indicador_titulo_smr_expo</Trans></p>
                        <p className="pe-5"><Trans>servicios.puertos_maritimos_indicador_texto_smr_expo</Trans></p>
                    </div>
                </div>
            </div>
        );
    };

    const IndicadorBaq = () => {
        return (
            <div className="container-fluid">
                <div className='row text-justify my-5'>
                    <div className='col-12 col-md-4 border-left py-2 ps-3 mt-5 mt-xl-0'>
                        <h3><Indicators endNumber={157} suffix="" decimals={0} time={30} /></h3>
                        <p className="titulo_indicador my-3"><Trans>servicios.puertos_maritimos_indicador_titulo_baq_buque</Trans></p>
                        <p className="pe-5"><Trans>servicios.puertos_maritimos_indicador_texto_baq_buque</Trans></p>
                    </div>
                    <div className='col-12 col-md-4 border-left py-2 ps-3 mt-5 mt-xl-0'>
                        <h3><Indicators endNumber={7504} suffix="" decimals={0} time={30} /></h3>
                        <p className="titulo_indicador my-3 "><Trans>servicios.puertos_maritimos_indicador_titulo_baq_impo</Trans></p>
                        <p className="pe-5"><Trans>servicios.puertos_maritimos_indicador_texto_baq_impo</Trans></p>
                    </div>
                    <div className='col-12 col-md-4 border-left py-2 ps-3 mt-5 mt-xl-0'>
                        <h3><Indicators endNumber={6127} suffix="" decimals={0} time={30} /></h3>
                        <p className="titulo_indicador my-3 "><Trans>servicios.puertos_maritimos_indicador_titulo_baq_expo</Trans></p>
                        <p className="pe-5"><Trans>servicios.puertos_maritimos_indicador_texto_baq_expo</Trans></p>
                    </div>
                </div>
            </div>
        );
    };

    const SeccionPuertos = () => {
        return (
            <div className='container-fluid'>
                <div className='custom-container'>
                    <div className="height-space-med"></div>
                    <div className='row'>
                        <div className='col-12 mt-4 text-center'>
                            <h1 className="mb-5"><Trans>servicios.puertos_maritimos_titulo_indicadores</Trans></h1>
                        </div>
                    </div>
                    <div className='row mb-5'>
                        <div className='col-12 col-xl-5 mt-4 text-center text-xl-start order-2 order-xl-1'>
                            <img src={puerto_buenaventura} alt="Lufthansa" className="img-fluid img-indicadores-puertos" />
                        </div>
                        <div className='col-12 col-xl-7 mt-4 text-justify order-1 order-xl-2'>
                            <p className="mb-3 subtitulo"><Trans>servicios.puertos_maritimos_subtitulo_indicadores_bun</Trans></p>
                            <p className="text-justify"><Trans>servicios.puertos_maritimos_texto_indicadores_bun</Trans></p>
                            <div className="d-none d-xl-block">
                                <IndicadorBun />
                            </div>
                        </div>
                        <div className='col mt-4 d-block d-xl-none order-3'>
                            <div className="indicador-aerolineas">
                                <IndicadorBun />
                            </div>
                        </div>
                    </div>
                    <div className='row mt-2 mt-xl-5'>
                        <div className='col-12 col-xl-7 mt-2 mt-xl-4 text-justify'>
                            <p className="mb-3 subtitulo"><Trans>servicios.puertos_maritimos_subtitulo_indicadores_ctg</Trans></p>
                            <p className="text-justify"><Trans>servicios.puertos_maritimos_texto_indicadores_ctg</Trans></p>
                            <div className="d-none d-xl-block">
                                <IndicadorCtg />
                            </div>
                        </div>
                        <div className='col-12 col-xl-5 mt-2 mt-xl-4 text-center'>
                            <img src={puerto_cartagena} alt="Air Canada" className="img-fluid img-indicadores-puertos" />
                        </div>
                        <div className='col mt-4 d-block d-xl-none'>
                            <div className="indicador-aerolineas">
                                <IndicadorCtg />
                            </div>
                        </div>
                    </div>
                    <div className='row mb-5'>
                        <div className='col-12 col-xl-5 mt-4 text-center text-xl-start order-2 order-xl-1'>
                            <img src={puerto_santa_marta} alt="Lufthansa" className="img-fluid img-indicadores-puertos" />
                        </div>
                        <div className='col-12 col-xl-7 mt-4 text-justify order-1 order-xl-2'>
                            <p className="mb-3 subtitulo"><Trans>servicios.puertos_maritimos_subtitulo_indicadores_smr</Trans></p>
                            <p className="text-justify"><Trans>servicios.puertos_maritimos_texto_indicadores_smr</Trans></p>
                            <div className="d-none d-xl-block">
                                <IndicadorSmr/>
                            </div>
                        </div>
                        <div className='col mt-4 d-block d-xl-none order-3'>
                            <div className="indicador-aerolineas">
                                <IndicadorSmr/>
                            </div>
                        </div>
                    </div>
                    <div className='row mt-5'>
                        <div className='col-12 col-xl-7 mt-2 mt-xl-4 text-justify'>
                            <p className="mb-3 subtitulo"><Trans>servicios.puertos_maritimos_subtitulo_indicadores_baq</Trans></p>
                            <p className="text-justify"><Trans>servicios.puertos_maritimos_texto_indicadores_baq</Trans></p>
                            <div className="d-none d-xl-block">
                                <IndicadorBaq />
                            </div>
                        </div>
                        <div className='col-12 col-xl-5 mt-2 mt-xl-4 text-center'>
                            <img src={puerto_barranquilla} alt="Air Canada" className="img-fluid img-indicadores-puertos" />
                        </div>
                        <div className='col mt-4 d-block d-xl-none'>
                            <div className="indicador-aerolineas">
                                <IndicadorBaq />
                            </div>
                        </div>
                    </div>
                    <div className="botones-bpo text-center d-flex flex-column flex-md-row align-items-center justify-content-center mt-5">
                        <Link to="/contactenos">
                            <button type="button" className="btn btn-primary mb-3 mb-md-0 me-md-5">
                                <Trans>servicios.puertos_maritimos_boton_contacto</Trans>
                            </button>
                        </Link>
                        <Link to="/nuestros-servicios">
                            <button type="button" className="btn btn-primary">
                                <Trans>servicios.puertos_maritimos_boton_servicios</Trans>
                            </button>
                        </Link>
                    </div>

                    <div className='row mb-5'></div>
                    <div className='row'></div>
                    <div className="height-space-med"></div>
                </div>
            </div>
        );
    };

    return (
        <div>
            <MetaDescription content="Expertos en soluciones logísticas personalizadas y representación legal de para líneas navieras, exportadores e importadores en Colombia."/>
            <SeccionPuertos />
        </div>
    );
}

export default PuertosMaritimosPage;
