import { useEffect } from 'react';

const MetaDescription = ({ content }) => {
    useEffect(() => {
        const metaDescription = document.querySelector('meta[name="description"]');
        if (metaDescription) {
            metaDescription.content = content;
        } else {
            const head = document.getElementsByTagName('head')[0];
            const newMetaDescription = document.createElement('meta');
            newMetaDescription.name = "description";
            newMetaDescription.content = content;
            head.appendChild(newMetaDescription);
        }
    }, [content]);

    return null;
};

export default MetaDescription;
