import React, { useState, useEffect } from 'react';
import MetaDescription from '../MetaDescription';
import '../styles/TramitesPage.css';
import { Trans } from 'react-i18next';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import plataforma from '../../assets/Procesos-por-linea-maritima-NAVEMAR.png'
import TabsTramites from '../TabsTramites';
import { saveAs } from 'file-saver';
import pdfFile from '../../components/politicas/Manual Integral de Tramites y Consultas-Navemar-NYK.pdf';

function TramitesPage() {
  const [activeTab, setActiveTab] = useState('');
  const handleDownload = () => {
    saveAs(pdfFile, 'Manual Integral de Tramites y Consultas Navemar NYK.pdf');
};

  /*useEffect(() => {
      window.scrollTo(0, 0);
  }, []);*/

  const location = useLocation();
  const navigate = useNavigate();


  useEffect(() => {
    if (location.hash) {
      const id = location.hash.replace('#', '');
      setActiveTab(id);
      navigate('/tramites-y-consultas');
    }
  }, [location, navigate]);


  const tramites = [
    { opcion: <Trans>tramites.submenu_pagos</Trans>, valor: "pagos" },
    { opcion: <Trans>tramites.submenu_itinerarios</Trans>, valor: "itinerarios" },
    { opcion: <Trans>tramites.submenu_formatos</Trans>, valor: "formatos" },
    { opcion: <Trans>tramites.submenu_poderes</Trans>, valor: "poderes" },
    { opcion: <Trans>tramites.submenu_buques</Trans>, valor: "buques" },
    { opcion: <Trans>tramites.submenu_saes</Trans>, valor: "saes" },
    { opcion: <Trans>tramites.submenu_cargos</Trans>, valor: "cargos" },
  ];


  const TramitesPrincipal = () => {
    return (
      <div className="tramitesimg">
        <div className="container-fluid custom-container">
          <div className="content-portada">
            <h1>
              <Trans>tramites.titulo_principal</Trans>
            </h1>
            <p>
              <Trans>tramites.texto_principal</Trans>
            </p>
            <div className="buttons-container">
              <Link to="/preguntas-frecuentes">
                <button className="btn btn-primary me-4">
                  <Trans>tramites.boton_principal</Trans>
                </button>
              </Link>
            </div>
          </div>
        </div>
        <div className="tabs-container-principal">
          <TabsTramites tramites={tramites} activeTabFromUrl={activeTab} />
        </div>
      </div>
    );
  };


  const Plataforma = () => {
    return (
      <div className='container-fluid'>
        <div className='custom-container'>
          <div className="height-space-med"></div>
          <div className="height-space-med"></div>
          <div className="height-space-med"></div>
          <div className="height-space-tramites"></div>
          <div className='row'>
            <div className='col-12 col-xl-6 d-flex align-items-center order-1 order-xl-2'>
              <div>
                <h2 className="mb-5"><Trans i18nKey="tramites.titulo_plataforma" components={{ text_blue: <span className="texto-azul" /> }} /></h2>
                <p className="text-justify texto-plataforma"><Trans>tramites.texto_plataforma</Trans></p>
                <div className="list-container-tramites">
                  <ul className="centered-list-tramites custom-list-tramites">
                    <li>
                      <p className="text-justify margen-centro-bpo pb-3 pb-md-2"><Trans>tramites.texto_item1</Trans></p>
                    </li>
                    <li>
                      <p className="text-justify margen-centro-bpo pb-2"><Trans>tramites.texto_item2</Trans></p>
                    </li>
                    <li>
                      <p className="text-justify margen-centro-bpo pb-2 pb-md-0"><Trans>tramites.texto_item3</Trans></p>
                    </li>
                  </ul>
                </div>
                <button type="button" className="btn btn-primary d-none d-xl-block" onClick={handleDownload}><Trans>tramites.boton_manual</Trans></button>
              </div>
            </div>
            <div className='col-12 d-block d-xl-none order-3 text-center mt-5'>
              <div>
                <button type="button" className="btn btn-primary me-0 me-md-5 mb-3 mb-md-0" onClick={handleDownload}><Trans>tramites.boton_manual</Trans></button>
              </div>
            </div>
            <div className='col-12 col-xl-6 text-center order-2 order-xl-2 mt-5 mt-xl-0'>
              <img src={plataforma} alt="Politica" className="img-fluid img-tramites" />
            </div>
          </div>
          <div className='row mb-2 mb-xl-5'></div>
          <div className="height-space-med"></div>
        </div>
      </div>
    );
  };

  const OtrosTramites = () => {
    return (
      <div className='container-fluid bgclaro'>
        <div className='custom-container'>
          <div className="height-space-med"></div>
          <div className='row justify-content-center'>
            <div className='col-auto'>
              <h2 className="mb-5"><Trans>tramites.otros_tramites_titulo</Trans></h2>
            </div>
          </div>
          <div className='row justify-content-center'>
            <div className='col-12 col-lg-10 col-xl-6'>
              <p className="text-center "><Trans i18nKey="tramites.otros_tramites_texto" components={{ text_bold: <span className="texto-bold" /> }} /></p>
            </div>
          </div>
          <div className='row justify-content-center mt-3'>
            <div className='col-7 col-md-6 col-xl-3'>
              <div className='row justify-content-center marco-tramites'>
                <div className='col-6 borde-der-tramites d-flex align-items-center justify-content-center'>
                  <span><Link to="https://navesoftonline.com/serviciosenlinea.html" target="_blank" rel="noreferrer noopener" className="link-tramites">Hapag-Lloyd</Link></span>
                </div>
                <div className='col-6 d-flex align-items-center justify-content-center'>
                  <span><Link to="https://formsweb.navesoft.com/ords_ecocol_croma/WEB_MENU_PRINEVER" target="_blank" rel="noreferrer noopener" className="link-tramites">Evergreen</Link></span>
                </div>
              </div>
            </div>
          </div>
          <div className='row justify-content-center mt-5'>
            <div className='col-auto'>
              <Link to="/preguntas-frecuentes"><button type="button" className="btn btn-primary"><Trans>tramites.otros_tramites_boton</Trans></button></Link>
            </div>
          </div>
          <div className="height-space-med"></div>
        </div >
      </div >
    );
  };


  return (
    <div>
      <MetaDescription content="Tramites y consultas para Hapag-Lloyd, Evergreen y NYK." />
      <TramitesPrincipal />
      <Plataforma />
      <OtrosTramites />
    </div>
  );
}

export default TramitesPage;
