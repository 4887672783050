import React from 'react';
import Slider from 'react-slick';
import { parse, format } from 'date-fns';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft, faChevronRight } from '@fortawesome/free-solid-svg-icons';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import './styles/SliderNews.css';
import image1 from '../assets/Transbordo directo de vehiculos entre dos buques de NYK-Puerto de Buenaventura-Navemar-ARTICULO_Mesa de trabajo 1.png';
import image2 from '../assets/Primer buque evergreen en barranquilla fue agenciado por Navemar-ARTICULO_Mesa de trabajo 1.png';
import image3 from '../assets/Hapag-Lloyd inicia actividades en el puerto de turbo colombia - agenciado por navemar- ARTICULO_Mesa de trabajo 1.png';
import { Link } from 'react-router-dom';


const SlideClients = () => {

    function convertirFecha(fechaString) {
        const fecha = parse(fechaString, 'dd/MM/yyyy', new Date());
        const fechaFormateada = format(fecha, 'dd MMMM, yyyy');

        return fechaFormateada;
    }

    const news = [
        { image: image1, topic: "Logística", title: "NYK RORO lleva a cabo transbordos excepcionales en el Puerto de Buenaventura", text: "En un movimiento inusual pero estratégico, la línea marítima NYK RORO ha llevado a cabo tres transbordos de vehículos en el Puerto de Buenaventura en los últimos meses...", fecha: convertirFecha("14/05/2024"), link: "/trasbordo-de-nyk-roro-en-el-puerto-de-buenaventura" },
        { image: image2, topic: "Logística", title: "Buque de la naviera Evergreen atraca en el puerto de Barranquilla tras 12 años", text: "Barranquilla, Colombia - En un acontecimiento que ha sorprendido a la comunidad portuaria y comercial de Barranquilla, el emblemático buque portacontenedores Ever Career...", fecha: convertirFecha("07/05/2024"), link: "/buque-de-evergreen-atraca-en-el-puerto-de-barranquilla" },
        { image: image3, topic: "Logística", title: "Hapag-Lloyd inicia operaciones en el puerto de Turbo, Colombia.", text: "La naviera alemana Hapag-Lloyd ha dado inicio a sus operaciones en el puerto de Turbo, Colombia, un enclave de vital importancia en la exportación de productos como banano...", fecha: convertirFecha("01/05/2024"), link: "/hapag-lloyd-inicia-operaciones-en-turbo" },
    ];


    const CustomPrevArrow = ({ onClick }) => (
        <div className="custom-prev-arrow" onClick={onClick}>
            <FontAwesomeIcon icon={faChevronLeft} />
        </div>
    );

    const CustomNextArrow = ({ onClick }) => (
        <div className="custom-next-arrow" onClick={onClick}>
            <FontAwesomeIcon icon={faChevronRight} />
        </div>
    );


    const settings = {
        dots: false,
        infinite: true,
        speed: 1000,
        slidesToShow: 3,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 4000,
        prevArrow: <CustomPrevArrow />,
        nextArrow: <CustomNextArrow />,
        responsive: [

            {
                breakpoint: 1200,
                settings: {
                    slidesToShow: 2
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 1
                }
            },
        ],

    };



    return (
        <div className="slider-container-news">
            <Slider {...settings}>
                {news.map((item, index) => (
                    <div key={index} className="mb-4 p-3">
                        <Link className="link-news" to={item.link}>
                            <div className="card">
                                <div className="news-slide d-flex justify-content-center align-items-top news-border">
                                    <img src={item.image} className="card-img-top" alt="Imagen" />
                                </div>
                                <div className="card-body news-border">
                                    <p className="card-title topic-blog mb-4">{item.topic}</p>
                                    <p className="card-subtitle mb-2 titulo-blog text-start">{item.title}</p>
                                    <p className="card-text text-start">{item.text}</p>

                                </div>
                                <div className="card-footer card-footer-border p-3 d-flex justify-content-end align-items-center">
                                    <span className='me-2'>{item.fecha}</span>
                                </div>
                            </div>
                        </Link>
                    </div>
                ))}
            </Slider>
        </div>
    );

};

export default SlideClients;
