import React, { useState, useEffect } from 'react';
import './styles/PreguntasPage.css';
import config from '../configuration/config';
import BeatLoader from "react-spinners/BeatLoader";
import { Trans } from 'react-i18next';
import { Link } from 'react-router-dom';
import Oficinas from './Oficinas';

const TabsComponent = ({ lineas, apiEndpoint }) => {
    const [activeTab, setActiveTab] = useState(lineas[0].valor);
    const [tabContent, setTabContent] = useState(null);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchTabContent = async () => {
            setLoading(true);
            setError(null);

            try {
                const response = await fetch(`${config.apiUrl}/preguntas-frecuentes`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({ linea: activeTab }),
                });
                console.log(response.ok);
                if (!response.ok) {
                    throw new Error('Error al obtener contenido del tab');
                }

                const result = await response.json();
                setTabContent(result);
            } catch (err) {
                setError(err.message);
            } finally {
                setLoading(false);
            }
        };

        fetchTabContent();
    }, [activeTab, apiEndpoint]);

    const PreguntasComponent = ({ apiResponse = { PROCESO: {} } }) => {
        const processNames = Object.keys(apiResponse.PROCESO);
        const [selectedProcess, setSelectedProcess] = useState(null);
        const [expandedQuestion, setExpandedQuestion] = useState(null);

        const allQuestions = Object.entries(apiResponse.PROCESO);
        const selectedQuestions = selectedProcess
            ? [[selectedProcess, apiResponse.PROCESO[selectedProcess]]]
            : allQuestions;

        return (
            <div>
                <div className="process-buttons">
                    {processNames.map((process, index) => (
                        <React.Fragment key={index}>
                            <button
                                className={`process-button ${selectedProcess === process ? "selected" : ""}`}
                                onClick={() => setSelectedProcess(selectedProcess === process ? null : process)}
                            >
                                {process}
                            </button>
                        </React.Fragment>
                    ))}
                </div>


                <div className="questions-container">
                    <p className="subtitulo mb-5">
                        {activeTab === 'EVER' ? (
                            <Trans i18nKey="preguntas_frecuentes.ever_texto" components={{ text_blue: <Link className="texto-azul-link" to="https://formsweb.navesoft.com/ords_ecocol_croma/WEB_MENU_PRINEVER" target="_blank"></Link> }} />
                        ) : activeTab === 'HAPAG' ? (
                            <Trans i18nKey="preguntas_frecuentes.hapag_texto" components={{ text_blue: <Link className="texto-azul-link" to="https://navesoftonline.com/serviciosenlinea.html" target="_blank"></Link> }} />
                        ) : (
                            <Trans i18nKey="preguntas_frecuentes.nyk_texto" components={{ text_blue: <Link className="texto-azul-link" to="/tramites-y-consultas"></Link> }} />
                        )}

                    </p>
                    {selectedQuestions.map(([process, questions], processIndex) => (
                        <div key={`process-${processIndex}`} className="process-section">
                            <h3 className="process-title">{process}</h3>
                            {questions.map((q, index) => {
                                const questionKey = `${processIndex}-${index}`;
                                return (
                                    <div key={questionKey} className="question-item">
                                        <p
                                            className="question-button"
                                            onClick={() => setExpandedQuestion(
                                                expandedQuestion === questionKey ? null : questionKey
                                            )}
                                        >
                                            {q.PREGUNTA}
                                            <svg id="Capa_1" data-name="Capa 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 27 27" className={`icono-mas ${expandedQuestion === questionKey ? 'rotated' : ''} `}>
                                                <path id="Trazado_2601" data-name="Trazado 2601" d="M11.57,0v11.57H0v3.86h11.57v11.57h3.86v-11.57h11.57v-3.86h-11.57V0h-3.86Z" />
                                            </svg>
                                        </p>
                                        {expandedQuestion === questionKey && (
                                            <div className="answer">
                                                <p>{q.RESPUESTA}</p>
                                            </div>
                                        )}
                                    </div>
                                );
                            })}
                        </div>
                    ))}

                </div>
                <Oficinas linea={activeTab} />
            </div>
        );
    };


    return (
        <>
            <div className="tab-group">
                {lineas.map((item, index) => (
                    <button
                        key={index}
                        className={`tab ${activeTab === item.valor ? "active" : ""}`}
                        onClick={() => setActiveTab(item.valor)}
                    >
                        {item.linea}
                    </button>
                ))}
            </div>

            <div className="tab-content">
                {loading ? (
                    <p className="ms-5"><BeatLoader color="#0082c6" size={20} margin={5} /></p>
                ) : error ? (
                    <p>Error: {error}</p>
                ) : tabContent ? (
                    <PreguntasComponent apiResponse={tabContent} />
                ) : (
                    <p>No hay contenido para mostrar.</p>
                )}
            </div>
        </>
    );
};

export default TabsComponent;
