import React, { useEffect } from 'react';
import './styles/NoticiasFormatos.css';
import { Link } from 'react-router-dom';

function NoticiasFormato3() {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const ImagenPrincipal = () => {
        return (

            <div className="container-fluid noticiasformato-3">
                <div className="container-fluid custom-container">
                </div>
            </div>
        );
    };

    function compartirEnRedSocial() {
        if (navigator.share) {
            navigator.share({
                title: 'Hapag-Lloyd inicia operaciones en el puerto de Turbo, Colombia.',
                text: 'Hapag-Lloyd inicia operaciones en el puerto de Turbo, Colombia.',
                url: window.location.href
            })
        } else {
            console.log('La API de Web Share no está disponible en este navegador');
        }
    }
    const ContenidoNoticia = () => {
        return (
            <div className="container-fluid">
                <div className='custom-container margen-noticias'>
                    <div className="height-space-med"></div>
                    <div className="row justify-content-center">
                        <p className="topic-blog-titulo text-center">Logística</p>
                        <h1 className="text-center">Hapag-Lloyd inicia operaciones en el puerto de Turbo, Colombia</h1>
                    </div>
                    <div className="row justify-content-start">
                        <p className="texto-azul">1 de mayo de 2024</p>
                    </div>
                    <div className="row justify-content-center">
                        <p className="subtitulo mb-4 mb-md-5">Transformando el panorama del comercio exterior con sus rutas estratégicas</p>
                        <p className="">La naviera alemana Hapag-Lloyd ha dado inicio a sus operaciones en el puerto de Turbo, Colombia, un enclave de vital importancia en la exportación de productos como banano, plátano y aguacate. Este puerto, está emergiendo como un actor crucial en el comercio exterior debido a la abundancia y calidad de los productos que se producen en la región.<br/><br/>
                        Con una historia que se remonta a más de 175 años, Hapag-Lloyd es una de las principales líneas marítimas del mundo. Reconocida por su compromiso con la eficiencia, la innovación y la sostenibilidad, la compañía ha establecido una reputación sólida en la industria del transporte marítimo. <br/><br/>
                        La presencia de esta naviera en Turbo, abre una excelente oportunidad para establecer relaciones comerciales con los exportadores y propietarios de fincas bananeras de la zona. Esta nueva oferta incluye servicios de exportación desde Turbo a diferentes destinos en el mundo, impulsando así el desarrollo económico de la región y fortaleciendo los lazos comerciales de este puerto y los principales centros del comercio exterior mundial. <br/><br/>
                        Desde el inicio de este servicio el mercado exportador del Urabá ha contado con una escala semanal para embarque de exportaciones en las motonaves <span className="texto-bold">NOAH</span> y <span className="texto-bold">CONTSHIP SKY</span>. <br /><br />
                        Una fecha clave en esta nueva etapa fue el 18 de enero de 2024, cuando representantes de Navemar visitaron Turbo para conocer de cerca las instalaciones de Uniban y Banacol, los operadores logísticos de los principales exportadores de banano y plátano de la región. Durante esta visita, se destacó el alto nivel de seguridad, tecnología y eficiencia en el manejo de cargas que caracteriza a estas instalaciones.</p>
                    </div>
                    <div className="row justify-content-start justify-content-xl-center mt-3 mt-md-5">
                        <p className="subtitulo mb-4 mb-md-5">Eficientes operaciones marítimas de Hapag-Lloyd en Turbo: un impulso al comercio exterior colombiano </p>
                        <p className="">El servicio de Hapag-Lloyd cuenta con una recalada semanal en Turbo, arribando los domingos. Los depósitos en Turbo reciben carga de los exportadores de lunes a viernes, realizando llenados entre jueves y viernes, incluso durante la madrugada del sábado, para permitir la inspección de los contenedores antes del embarque.  <br /><br />
                        Los contenedores son llenados en cada depósito, y tras pasar por las inspecciones requeridas, son liberados para el embarque. Luego, son transportados en barcaza a través del río León hasta la zona de fondeo, donde se encuentran los buques de Hapag-Lloyd para la carga final. <br /><br />
                        Navemar, como agente de la línea Hapag-Lloyd, se encarga del manejo de toda la documentación necesaria para la operación, en colaboración con agentes de aduanas y clientes. Así mismo, facilita la documentación requerida para que los contenedores sean recibidos por los terminales marítimos en los puertos de transbordo, embarcados hacia su destino final y documentados ante las autoridades aduaneras.  <br /><br />
                        Este hito no solo marca el inicio de una nueva era para Turbo como un punto clave en la cadena logística internacional, sino que también fortalece la posición de Colombia como actor relevante en el escenario global del comercio marítimo. </p>
                    </div>
                    <div className="botones-nuestros-servicios botones-bpo text-center mt-3 mt-md-5">
                        <Link to="">
                            <button type="button" className="btn btn-primary me-0 me-md-5 mb-3 mb-md-0" onClick={compartirEnRedSocial}>
                                Compartir Articulo
                            </button>
                        </Link>
                        <Link to="/inicio#blog">
                            <button type="button" className="btn btn-primary">
                                Volver al Inicio
                            </button>
                        </Link>
                    </div>
                    <div className="height-space-med"></div>
                </div>
            </div>
        );
    };


    return (
        <div>
            <ImagenPrincipal />
            <ContenidoNoticia />

        </div>
    );
}

export default NoticiasFormato3;
