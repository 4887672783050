import React, { useEffect } from 'react';
import '../styles/Politicas.css';
import politica from '../../assets/Politica-Terminos-y-Condiciones-Navemar.png';
import politica_sm from '../../assets/Politicas-Prueba-Responsive-Mobile.png';
import { Link } from 'react-router-dom';
import { Trans } from 'react-i18next';
import { saveAs } from 'file-saver';
import pdfFile from '../../components/politicas/Terminos y condiciones Navemar.pdf';

/**
 * Página de "Politica Prevencion Lavado Activos".
 * @component
 * @returns {JSX.Element} Elemento de la página "Politica Prevencion Lavado Activos".
 */
function TerminosCondiciones() {
    const handleDownload = () => {
        saveAs(pdfFile, 'Terminos y condiciones Navemar.pdf');
    };
    useEffect(() => {
        window.scrollTo(0, 0);
      }, []);

    return (
        <div className="container-fluid custom-container">
            <div className="politica-container mt-5">
                <div className="politica-content mb-5">
                    <img src={politica} alt="Politica" className="img-fluid default-imagen" />
                    <img src={politica_sm} alt="Politica" className="img-fluid imagen-mobile" />
                    <h3 className="text-center"><Trans>politica_terminos.titulo</Trans></h3>
                    <p><Trans>politica_terminos.texto</Trans></p>

                    <ol className="politica-terminos-ol">
                        <li><Trans i18nKey="politica_terminos.titulo_1" components={{ text_bold: <span className="texto-bold"/>}}/>
                            <ol>
                                <li><Trans>politica_terminos.texto_1.1</Trans></li>
                                <li><Trans>politica_terminos.texto_1.2</Trans></li>
                                <li><Trans>politica_terminos.texto_1.3</Trans></li>
                            </ol>
                        </li>
                        <li><Trans i18nKey="politica_terminos.titulo_2" components={{ text_bold: <span className="texto-bold"/>}}/>
                            <ol>
                            <p><Trans>politica_terminos.texto_2.1</Trans></p>
                            </ol>
                        </li>
                        <li><Trans i18nKey="politica_terminos.titulo_3" components={{ text_bold: <span className="texto-bold"/>}}/>
                            <ol>
                            <p><Trans>politica_terminos.texto_3.1</Trans></p>
                            <p><Trans>politica_terminos.texto_3.2</Trans></p>
                            </ol>
                        </li>
                        <li><Trans i18nKey="politica_terminos.titulo_4" components={{ text_bold: <span className="texto-bold"/>}}/>
                            <ol>
                            <p><Trans>politica_terminos.texto_4.1</Trans></p>
                            </ol>
                        </li>
                        <li><Trans i18nKey="politica_terminos.titulo_5" components={{ text_bold: <span className="texto-bold"/>}}/>
                            <ol>
                            <p><Trans>politica_terminos.texto_5.1</Trans></p>
                            </ol>
                        </li>
                        <li><Trans i18nKey="politica_terminos.titulo_6" components={{ text_bold: <span className="texto-bold"/>}}/>
                            <ol>
                            <p><Trans>politica_terminos.texto_6.1</Trans></p>
                            </ol>
                        </li>
                        <li><Trans i18nKey="politica_terminos.titulo_7" components={{ text_bold: <span className="texto-bold"/>}}/>
                            <ol>
                            <p><Trans>politica_terminos.texto_7.1</Trans></p>
                            </ol>
                        </li>
                        <li><Trans i18nKey="politica_terminos.titulo_8" components={{ text_bold: <span className="texto-bold"/>}}/>
                            <ol>
                            <p><Trans>politica_terminos.texto_8.1</Trans></p>
                            </ol>
                        </li>
                        <li><Trans i18nKey="politica_terminos.titulo_9" components={{ text_bold: <span className="texto-bold"/>}}/>
                            <ol>
                            <p><Trans>politica_terminos.texto_9.1</Trans></p>
                            </ol>
                        </li>
                        <li><Trans i18nKey="politica_terminos.titulo_10" components={{ text_bold: <span className="texto-bold"/>}}/>
                            <ol>
                            <p><Trans>politica_terminos.texto_10.1</Trans></p>
                            </ol>
                        </li>
                        <li><Trans i18nKey="politica_terminos.titulo_11" components={{ text_bold: <span className="texto-bold"/>}}/>
                            <ol>
                            <p><Trans>politica_terminos.texto_11.1</Trans></p>
                            </ol>
                        </li>
                        <li><Trans i18nKey="politica_terminos.titulo_12" components={{ text_bold: <span className="texto-bold"/>}}/>
                            <ol>
                                <li><Trans i18nKey="politica_terminos.titulo_12.1" components={{ text_bold: <span className="texto-bold"/>}}/></li>
                                    <p><Trans>politica_terminos.texto_12.1.1</Trans></p>
                                <li><Trans i18nKey="politica_terminos.titulo_12.2" components={{ text_bold: <span className="texto-bold"/>}}/></li>
                                    <p><Trans>politica_terminos.texto_12.2.1</Trans></p>
                                        <ol>
                                            <p>a) <Trans i18nKey="politica_terminos.titulo_12.1.a" components={{ text_bold: <span className="texto-bold"/>}}/></p>
                                                <p><Trans>politica_terminos.texto_12.1.a</Trans></p>
                                            <p>b) <Trans i18nKey="politica_terminos.titulo_12.1.b" components={{ text_bold: <span className="texto-bold"/>}}/></p>
                                                <p><Trans>politica_terminos.texto_12.1.b</Trans></p>
                                            <p>c) <Trans i18nKey="politica_terminos.titulo_12.1.c" components={{ text_bold: <span className="texto-bold"/>}}/></p>
                                                <p><Trans>politica_terminos.texto_12.1.c</Trans></p>
                                        </ol>
                                <p><Trans i18nKey="politica_terminos.texto_12.3" components={{ text_blue: <Link className="texto-azul" to='/politica-de-tratamiento-de-datos-personales'/>,text_blue1: <Link className="texto-azul" to='http://sig.rednavemar.com/index.php/es/' target="_blank" rel="noreferrer noopener"/>}}/></p>
                            </ol>
                        </li>
                        <li><Trans i18nKey="politica_terminos.titulo_13" components={{ text_bold: <span className="texto-bold"/>}}/>
                            <ol>
                            <p><Trans i18nKey="politica_terminos.texto_13.1" components={{ text_blue: <Link className="texto-azul" to='/politica-de-tratamiento-de-datos-personales'/>,text_blue1: <Link className="texto-azul" to='http://sig.rednavemar.com/index.php/es/' target="_blank" rel="noreferrer noopener"/>}}/></p>
                            </ol>
                        </li>
                        
                    </ol>
                   
                </div>
            </div>
            <div className="text-center mt-4">
                <button className="btn btn-primary btn-politicas" onClick={handleDownload}><Trans>politica_terminos.boton_1</Trans></button>
            </div>
            <div className="height-space-med"></div>
        </div>
    );
}

export default TerminosCondiciones;