/**
 * Configuración de la aplicación.
 * @module config
 */

/**
 * Objeto de configuración que contiene la URL del endpoint de la API.
 * @typedef {Object} Config
 * @property {string} apiUrl - URL del endpoint de la API.
 */

/**
 * Configuración de la aplicación.
 * @type {Config}
 * @constant
 */
const config = {
    apiUrl: 'https://navemar.com/api',
    urlRut: 'https://navemar.com',
    carguePoderes :'https://formsweb.navesoft.com/ords_halocol_croma/EDOCUMENT.WEB_LOGIN_PRINCIPAL',
    consultaPoderes :'https://formsweb.navesoft.com/ords_halocol_croma/EDOCUMENT.WEB_LOGIN_SEARSHCLI',
    buquesHapag :'https://formsweb.navesoft.com/ords_halocol_croma/WEB_BUQUES_V2',
    buquesEver : 'https://formsweb.navesoft.com/ords_ecocol_croma/WEB_BUQUES_V2',
    buquesNyk : 'https://formsweb.navesoft.com/ords_navemar_croma/WEB_BUQUES_V2',
    secret : 'fcDU5vZCFj2F[7f?(BYQtYY)U5vRe8zL',
    fletesNyk : "https://formsweb.navesoft.com/ords_navemar_croma/CERT_WEB_FLE",
    estadoNyk : "https://formsweb.navesoft.com/ords_navemar_croma/ESTADO_CUENTA_WEB",
    facturasNyk : "https://formsweb.navesoft.com/ords_navemar_croma/ENVIO_BL_FAC",
    mailMasivo : "https://formsweb.navesoft.com/ords_navemar_croma/WEB_MAIL_MASIVO_CARGUE",
  };

  export default config;
  