import React from 'react';
import sucursal from '../assets/icons/Icono-ubicacion-Azul-Navemar.svg';
import { Trans } from 'react-i18next';

function Oficinas({ linea }) {
    const sucursales = [
        {
            image: sucursal,
            titulo: <Trans>preguntas_frecuentes.oficina_titulo_ever</Trans>,
            subtitulo: <Trans>preguntas_frecuentes.oficina_subtitulo_ever</Trans>,
            telefono: <Trans i18nKey="preguntas_frecuentes.oficina_telefono_ever" components={{ text_bold: <span className="texto-bold" /> }} />,
            linea: "EVER"
        },
        {
            image: sucursal,
            titulo: "",
            subtitulo: <Trans>preguntas_frecuentes.oficina_subtitulo_ever</Trans>,
            telefono: <Trans i18nKey="preguntas_frecuentes.oficina_telefono_ever2" components={{ text_bold: <span className="texto-bold" /> }} />,
            linea: "EVER"
        },
        {
            image: sucursal,
            titulo: "",
            subtitulo: <Trans>preguntas_frecuentes.oficina_subtitulo_ever</Trans>,
            telefono: <Trans i18nKey="preguntas_frecuentes.oficina_telefono_ever3" components={{ text_bold: <span className="texto-bold" /> }} />,
            linea: "EVER"
        },
        {
            image: sucursal,
            titulo: <Trans>preguntas_frecuentes.oficina_titulo_hapag</Trans>,
            subtitulo: <Trans>preguntas_frecuentes.oficina_subtitulo_hapag</Trans>,
            telefono: <Trans i18nKey="preguntas_frecuentes.oficina_telefono_hapag" components={{ text_bold: <span className="texto-bold" /> }} />,
            linea: "HAPAG"
        },
        {
            image: sucursal,
            titulo: "",
            subtitulo: <Trans>preguntas_frecuentes.oficina_subtitulo_hapag2</Trans>,
            telefono: <Trans i18nKey="preguntas_frecuentes.oficina_telefono_hapag2" components={{ text_bold: <span className="texto-bold" /> }} />,
            linea: "HAPAG"
        },
        {
            image: sucursal,
            titulo: "",
            subtitulo: <Trans>preguntas_frecuentes.oficina_subtitulo_hapag3</Trans>,
            telefono: <Trans i18nKey="preguntas_frecuentes.oficina_telefono_hapag3" components={{ text_bold: <span className="texto-bold" /> }} />,
            linea: "HAPAG"
        },
        {
            image: sucursal,
            titulo: "",
            subtitulo: <Trans>preguntas_frecuentes.oficina_subtitulo_hapag4</Trans>,
            telefono: <Trans i18nKey="preguntas_frecuentes.oficina_telefono_hapag4" components={{ text_bold: <span className="texto-bold" /> }} />,
            linea: "HAPAG"
        },
        {
            image: sucursal,
            titulo: "",
            subtitulo: <Trans>preguntas_frecuentes.oficina_subtitulo_hapag5</Trans>,
            telefono: <Trans i18nKey="preguntas_frecuentes.oficina_telefono_hapag5" components={{ text_bold: <span className="texto-bold" /> }} />,
            linea: "HAPAG"
        },
        {
            image: sucursal,
            titulo: "",
            subtitulo: <Trans>preguntas_frecuentes.oficina_subtitulo_hapag6</Trans>,
            telefono: <Trans i18nKey="preguntas_frecuentes.oficina_telefono_hapag6" components={{ text_bold: <span className="texto-bold" /> }} />,
            linea: "HAPAG"
        },
        {
            image: sucursal,
            titulo: "",
            subtitulo: <Trans>preguntas_frecuentes.oficina_subtitulo_hapag7</Trans>,
            telefono: <Trans i18nKey="preguntas_frecuentes.oficina_telefono_hapag7" components={{ text_bold: <span className="texto-bold" /> }} />,
            linea: "HAPAG"
        },
        {
            image: sucursal,
            titulo: "",
            subtitulo: <Trans>preguntas_frecuentes.oficina_subtitulo_hapag8</Trans>,
            telefono: <Trans i18nKey="preguntas_frecuentes.oficina_telefono_hapag8" components={{ text_bold: <span className="texto-bold" /> }} />,
            linea: "HAPAG"
        },
        {
            image: sucursal,
            titulo: <Trans>preguntas_frecuentes.oficina_titulo_nyk</Trans>,
            subtitulo: <Trans>preguntas_frecuentes.oficina_subtitulo_nyk</Trans>,
            telefono: <Trans i18nKey="preguntas_frecuentes.oficina_telefono_nyk" components={{ text_bold: <span className="texto-bold" /> }} />,
            linea: "NYK"
        },
        {
            image: sucursal,
            titulo: "",
            subtitulo: <Trans>preguntas_frecuentes.oficina_subtitulo_nyk</Trans>,
            telefono: <Trans i18nKey="preguntas_frecuentes.oficina_telefono_nyk2" components={{ text_bold: <span className="texto-bold" /> }} />,
            linea: "NYK"
        },
        
        
    ];

    const renderOficinas = () => {
        const sucursalesFiltradas = sucursales.filter(sucursal => sucursal.linea === linea || linea === undefined);
        return (
            
            <div className="row justify-content-start">
                {sucursalesFiltradas.map((item, index) => (
                    <div key={index} className="col-11 col-md-6 col-lg-3 ps-5 ps-md-0">
                        <p className="titulo-oficinas texto-azul mb-2 mb-md-5">{item.titulo || ' '}</p>
                        <div className={`row align-items-start py-0 py-md-5 ${index !== 0 && index !== 4 ? 'border-left-blue' : ''} ${index % 2 !== 0 ? 'border-left-blue-1' : ''}`}>
                            <div className="col-1">
                                <img src={item.image} alt="Office" className="iconos-contacto" />
                            </div>
                            <div className="col-11 oficinas-texto">
                                <div className="subtitulo-oficinas mb-0 mb-md-3">{item.subtitulo}</div>
                                <p className="texto-oficinas">{item.telefono}</p>
                            </div>
                        </div>
                    </div>

                ))}
            
            </div>
        );
    };

    return (
        <div className='container-fluid'>
            <div className="height-space-med"></div>
            <div className="">
                <div className="row mb-5">
                    <div id="sucursales" className='col-12'>
                        <h2 className="text-center text-md-start">{<Trans>preguntas_frecuentes.oficinas_titulo</Trans>}</h2>
                        <p className="subtitulo-regular text-center text-md-start mt-4 mb-0">{<Trans>preguntas_frecuentes.oficinas_texto</Trans>}</p>
                    </div>
                </div>
            </div>
            {renderOficinas()}
            <div className="height-space-med"></div>
        </div>
    );
};

export default Oficinas;
