import React from 'react';
import './styles/TabsTramites.css';
import { Trans } from 'react-i18next';
import customArrowIcon from '../assets/icons/Icono-flecha-menu-negra.svg';
import Dropdown from 'react-bootstrap/Dropdown';
import { Link } from 'react-router-dom';

const SaesTab = () => {
    const CustomDropdownToggle = React.forwardRef(({ children, onClick }, ref) => (
        <Link
            className="custom-dropdown-toggle-link"
            href=""
            ref={ref}
            onClick={(e) => {
                e.preventDefault();
                onClick(e);
            }}
        >
            {children}
            <img src={customArrowIcon} alt="Custom Arrow Icon" className="custom-arrow-menu-tramites" />
        </Link>
    ));

    return (
        <div className="container-fluid custom-container">
            <div className="row">
                <div className="col-12 text-center borde_tramites-submenu">
                    <Dropdown>
                        <Dropdown.Toggle className="custom-dropdown-toggle" id="dropdown-basic" as={CustomDropdownToggle}>
                            <Trans>submenu_tramites.saes_cargue</Trans>
                        </Dropdown.Toggle>

                        <Dropdown.Menu className="custom-dropdown-tramites">
                            <Dropdown.Item href="https://formsweb.navesoft.com/ords_halocol_croma/web_carga_archivos" rel="noreferrer" target="_blank"><Trans>submenu_tramites.saes_cargue_hapag</Trans></Dropdown.Item>
                            <Dropdown.Item href="https://formsweb.navesoft.com/ords_ecocol_croma/WEB_MENU_PRINEVER?PSCREENNAME=Documentar mi embarque" rel="noreferrer" target="_blank"><Trans>submenu_tramites.saes_cargue_ever</Trans></Dropdown.Item>
                        </Dropdown.Menu>
                    </Dropdown>
                </div>
            </div>
            {/*<div className="row">
                <div className="col-12 text-center borde_tramites-submenu">
                    <Dropdown>
                        <Dropdown.Toggle className="custom-dropdown-toggle" id="dropdown-basic" as={CustomDropdownToggle}>
                            <Trans>submenu_tramites.saes_consulta</Trans>
                        </Dropdown.Toggle>

                        <Dropdown.Menu className="custom-dropdown-tramites">
                            <Dropdown.Item href="https://formsweb.navesoft.com/ords_halocol_croma/csavchile.web_consulta_archivos" rel="noreferrer" target="_blank"><Trans>submenu_tramites.saes_consulta_hapag</Trans></Dropdown.Item>
                            <Dropdown.Item href="https://formsweb.navesoft.com/ords_ecocol_croma/WEB_MENU_PRINEVER?PSCREENNAME=Documentar mi embarque" rel="noreferrer" target="_blank"><Trans>submenu_tramites.saes_consulta_ever</Trans></Dropdown.Item>
                        </Dropdown.Menu>
                    </Dropdown>
                </div>
            </div>*/}
        </div>
    );
};

export default SaesTab;