import React, { useEffect } from 'react';
import '../styles/Politicas.css';
import politica from '../../assets/Politica-Tratamiento-de-datos-personales-Navemar.png';
import politica_sm from '../../assets/Politica-Tratamiento-de-datos-personales-Navemar_Mobile.png';
import {Trans} from 'react-i18next';
import { Link } from 'react-router-dom';
import { saveAs } from 'file-saver';
import pdfFile from '../../components/politicas/Politica de Tratamiento de Datos Personales-V4-Navemar.pdf';

/**
 * Página de "PoliticaDatos".
 * @component
 * @returns {JSX.Element} Elemento de la página "Politica Tratamiento Datos Personales".
 */
function PoliticaDatos() {
    const handleDownload = () => {        
        saveAs(pdfFile, 'Politica de Tratamiento de Datos Personales-Navemar.pdf');
    };

    useEffect(() => {
        window.scrollTo(0, 0);
      }, []);

    return (
        <div className="container-fluid custom-container">
            <div className="politica-container mt-5">
                <div className="politica-content mb-4">
                    <img src={politica} alt="Politica" className="img-fluid default-imagen" />
                    <img src={politica_sm} alt="Politica" className="img-fluid imagen-mobile" />
                    <h3 className="text-center"><Trans>politica_datos.titulo</Trans></h3>
                    <p><Trans>politica_datos.texto</Trans></p>
                    <p className="text-justify margen-centro-bpo subt"><Trans>politica_datos.subtitulo_1</Trans></p>
                    <p><Trans>politica_datos.texto_1</Trans></p>
                        <p className="text-justify margen-centro-bpo subt"><Trans>politica_datos.subtitulo_2</Trans></p>
                        <ul className="politica-ul">
                            <li><p><Trans>politica_datos.texto_2</Trans></p></li>
                            <li><p><Trans>politica_datos.texto_2.1</Trans></p></li>
                            <li><p><Trans>politica_datos.texto_2.2</Trans></p></li>
                            <li><p><Trans>politica_datos.texto_2.3</Trans></p></li>
                            <li><p><Trans>politica_datos.texto_2.4</Trans></p></li>
                            <li><p><Trans>politica_datos.texto_2.5</Trans></p></li>
                        </ul>
                        <p className="text-justify margen-centro-bpo subt"><Trans>politica_datos.subtitulo_3</Trans></p>
                        <ul className="politica-ul">
                            <li><p><Trans>politica_datos.texto_3</Trans></p></li>
                            <li><p><Trans>politica_datos.texto_3.1</Trans></p></li>
                        </ul>
                        <p className="text-justify margen-centro-bpo subt"><Trans>politica_datos.subtitulo_4</Trans></p>
                        <ul className="politica-ul">
                            <li><p><Trans>politica_datos.texto_4</Trans></p></li>
                            <li><p><Trans>politica_datos.texto_4.1</Trans></p></li>
                        </ul>
                        <p className="text-justify margen-centro-bpo subt"><Trans>politica_datos.subtitulo_5</Trans></p>
                        <ul className="politica-ul">
                            <li><p><Trans>politica_datos.texto_5</Trans></p></li>
                            <li><p><Trans>politica_datos.texto_5.1</Trans></p></li>
                        </ul>
                        <p className="text-justify margen-centro-bpo subt"><Trans>politica_datos.subtitulo_6</Trans></p>
                        <ul className="politica-ul">
                            <li><p><Trans>politica_datos.texto_6</Trans></p></li>
                            <li><p><Trans>politica_datos.texto_6.1</Trans></p></li>
                            <li><p><Trans>politica_datos.texto_6.2</Trans></p></li>
                        </ul>
                        <p className="text-justify margen-centro-bpo subt"><Trans>politica_datos.subtitulo_7</Trans></p>
                        <ul className="politica-ul">
                            <li><p><Trans>politica_datos.texto_7</Trans></p></li>
                            <li><p><Trans>politica_datos.texto_7.1</Trans></p></li>
                            <li><p><Trans>politica_datos.texto_7.2</Trans></p></li>
                        </ul>
                        <p className="text-justify margen-centro-bpo subt"><Trans>politica_datos.subtitulo_8</Trans></p>
                        <ul className="politica-ul">
                            <li><p><Trans>politica_datos.texto_8</Trans></p></li>
                            <li><p><Trans>politica_datos.texto_8.1</Trans></p></li>
                            <li><p><Trans>politica_datos.texto_8.2</Trans></p></li>
                        </ul>
                </div>
            </div>
            <div className="text-center mt-4">
                <Link to="/quienes-somos"><button className="btn btn-primary btn-politicas me-0 me-md-5 mb-3 mb-md-0"><Trans>politica.boton_1</Trans></button></Link>
                <button className="btn btn-primary btn-politicas" onClick={handleDownload}><Trans>politica.boton_2</Trans></button>
            </div>
            <div className="height-space-med"></div>
        </div>
    );
}

export default PoliticaDatos;