import React, { useState, useEffect, useRef, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import './styles/SlideClients.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft, faChevronRight } from '@fortawesome/free-solid-svg-icons';
import { useTranslation } from 'react-i18next';
import logo_one from '../assets/clients/Logo_One.png';
import logo_msc from '../assets/clients/Logo_MSC.png';
import logo_lufthansa from '../assets/clients/Logo_Lufthansa.png';
import logo_hapag from '../assets/clients/Logo_Hapag.png';
import logo_ever from '../assets/clients/Logo_Evergreen.png';
import logo_air from '../assets/clients/Logo_Air_Canada.png';
import logo_masair from '../assets/clients/Logo_MasAir.png';
import logo_inter from '../assets/clients/Logo_Intermodal.png';
import logo_maersk from '../assets/clients/Logo_Maersk.png';
import logo_nyk from '../assets/clients/Logo_NYK.png';
import logo_zara from '../assets/clients/Logo-Zaratrans-Navemar.png';

const SlideClients = () => {
    const { t } = useTranslation();
    const clients = useMemo(() => [
        { image: logo_air, title: t('clientes.air'), url: "/nuestros-clientes?section=air" },
        { image: logo_ever, title: t('clientes.ever'), url: "/nuestros-clientes#ever" },
        { image: logo_hapag, title: t('clientes.hapag'), url: "/nuestros-clientes#hapag" },
        { image: logo_inter, title: t('clientes.inter'), url: "/nuestros-clientes#inter" },
        { image: logo_lufthansa, title: t('clientes.lufthansa'), url: "/nuestros-clientes#lufthansa" },
        { image: logo_maersk, title: t('clientes.maersk'), url: "/nuestros-clientes#maersk" },
        { image: logo_masair, title: t('clientes.masair'), url: "/nuestros-clientes#masair" },
        { image: logo_msc, title: t('clientes.msc'), url: "/nuestros-clientes#msc" },
        { image: logo_nyk, title: t('clientes.nyk'), url: "/nuestros-clientes#nyk" },
        { image: logo_one, title: t('clientes.one'), url: "/nuestros-clientes#one" },
        { image: logo_zara, title: t('clientes.zara'), url: "/nuestros-clientes#zaratrans" },
    ], [t]);

    const sliderRef = useRef(null);
    const [centerIndex, setCenterIndex] = useState(3);
    const [slidesToShow, setSlidesToShow] = useState(7);
    const [currentTitle, setCurrentTitle] = useState(clients[3]?.title || '');
    const navigate = useNavigate();

    const CustomPrevArrow = ({ onClick }) => (
        <div className="custom-prev-arrow" onClick={onClick}>
            <FontAwesomeIcon icon={faChevronLeft} />
        </div>
    );

    const CustomNextArrow = ({ onClick }) => (
        <div className="custom-next-arrow" onClick={onClick}>
            <FontAwesomeIcon icon={faChevronRight} />
        </div>
    );

    useEffect(() => {
        const updateSlidesToShow = () => {
            const windowWidth = window.innerWidth;
            if (windowWidth > 1024) {
                setSlidesToShow(7);
                setCenterIndex(3);
            } else if (windowWidth > 600) {
                setSlidesToShow(5);
                setCenterIndex(2);
            } else {
                setSlidesToShow(1);
                setCenterIndex(0);
            }
        };
        updateSlidesToShow();
        window.addEventListener('resize', updateSlidesToShow);
        return () => {
            window.removeEventListener('resize', updateSlidesToShow);
        };
    }, []);

    const settings = {
        dots: false,
        infinite: true,
        speed: 1000,
        slidesToShow: slidesToShow,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 1500,
        prevArrow: <CustomPrevArrow />,
        nextArrow: <CustomNextArrow />,
        swipeToSlide: true,
        responsive: [
            {
                breakpoint: 1100,
                settings: {
                    slidesToShow: 5
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 1
                }
            },
        ],
        afterChange: (current) => {
            if (slidesToShow === 1) {
                setCenterIndex(current);
                setCurrentTitle(clients[current]?.title || '');
            } else {
                let newCenterIndex = (current + Math.floor(slidesToShow / 2)) % clients.length;
                setCenterIndex(newCenterIndex);
                setCurrentTitle(clients[newCenterIndex]?.title || '');
            }
        }
    };

    const handleImageClick = (section) => {
        navigate(section);
    };

    useEffect(() => {
        setCurrentTitle(clients[centerIndex]?.title || '');
    }, [centerIndex, clients]);

    return (
        <div className="slider-container">
            <div className="title-container">
                <div className="">{currentTitle}</div>
            </div>
            <Slider ref={sliderRef} {...settings}>
                {clients.map((client, index) => (
                    <div key={index} className='py-5'>
                        <div key={index} className={`client-slide ${index === centerIndex ? 'center-slide' : ''}`}>
                            <img src={client.image} alt={client.title} onClick={() => handleImageClick(client.url)} />
                        </div>
                    </div>
                ))}
            </Slider>
        </div>
    );

};

export default SlideClients;
