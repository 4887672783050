import React from 'react';
import Slider from 'react-slick';
import { Link } from 'react-router-dom';
import { Trans } from 'react-i18next';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import './styles/SliderAgenciamiento.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft, faChevronRight } from '@fortawesome/free-solid-svg-icons';
import mascota from '../assets/Transporte de mascotas-vip lounge-lufthansa cargo-navemar.png';
import emergencia from '../assets/Transporte de emergencia-lufthansa cargo-navemar.png';
import mascota2 from '../assets/Transporte de mascotas-perros-lufthansa cargo-navemar.png';
import productos from '../assets/Envío de productos perecederos-verduras y frutas-lufthansa cargo-navemar.png';
import animales from '../assets/Transporte de animales vivos-gatos-lufthansa cargo-navemar.png';
import mercancia from '../assets/Transporte seguro de mercancías peligrosas-lufthansa cargo-navemar.jpg';
import productos2 from '../assets/Envío de productos perecederos-lufthansa cargo-navemar.png';
import mercancia_vulnerable from '../assets/Seguridad desarrollada para mercancias vulnerables-lufthansa cargo-navemar.png';
import carga_sensible from '../assets/Active Temp Control para carga sensible-lufthansa cargo-navemar.png';
import carga_sensible2 from '../assets/Temperaturas estables con Passive Temp Support-lufthansa cargo-navemar.png';


const SliderAerolineas = () => {

    const CustomPrevArrow = ({ onClick }) => (
        <div className="custom-prev-arrow" onClick={onClick}>
            <FontAwesomeIcon icon={faChevronLeft} />
        </div>
    );

    const CustomNextArrow = ({ onClick }) => (
        <div className="custom-next-arrow" onClick={onClick}>
            <FontAwesomeIcon icon={faChevronRight} />
        </div>
    );

    const slides = [
        {
            image: mascota,
            title: <Trans>servicios.aerolineas_slider_titulo1</Trans>,
            text: <Trans>servicios.aerolineas_slider_texto1</Trans>
        },
        {
            image: emergencia,
            title: <Trans>servicios.aerolineas_slider_titulo2</Trans>,
            text: <Trans>servicios.aerolineas_slider_texto2</Trans>
        },
        {
            image: mascota2,
            title: <Trans>servicios.aerolineas_slider_titulo3</Trans>,
            text: <Trans>servicios.aerolineas_slider_texto3</Trans>
        },
        {
            image: productos,
            title: <Trans>servicios.aerolineas_slider_titulo4</Trans>,
            text: <Trans>servicios.aerolineas_slider_texto4</Trans>
        },
        {
            image: animales,
            title: <Trans>servicios.aerolineas_slider_titulo5</Trans>,
            text: <Trans>servicios.aerolineas_slider_texto5</Trans>
        },
        {
            image: mercancia,
            title: <Trans>servicios.aerolineas_slider_titulo6</Trans>,
            text: <Trans>servicios.aerolineas_slider_texto6</Trans>
        },
        {
            image: productos2,
            title: <Trans>servicios.aerolineas_slider_titulo7</Trans>,
            text: <Trans>servicios.aerolineas_slider_texto7</Trans>
        },
        {
            image: mercancia_vulnerable,
            title: <Trans>servicios.aerolineas_slider_titulo8</Trans>,
            text: <Trans>servicios.aerolineas_slider_texto8</Trans>
        },
        {
            image: carga_sensible,
            title: <Trans>servicios.aerolineas_slider_titulo9</Trans>,
            text: <Trans>servicios.aerolineas_slider_texto9</Trans>
        },
        {
            image: carga_sensible2,
            title: <Trans>servicios.aerolineas_slider_titulo10</Trans>,
            text: <Trans>servicios.aerolineas_slider_texto10</Trans>
        },
    ];

    const settings = {
        dots: true,
        infinite: true,
        lazyLoad: true,
        speed: 1500,
        slidesToShow: 3,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 2000,
        pauseOnHover: true,
        prevArrow: <CustomPrevArrow />,
        nextArrow: <CustomNextArrow />,
        swipeToSlide: true,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 2
                }
            },
            {
                breakpoint: 767,
                settings: {
                    slidesToShow: 1
                }
            },
        ],
    };

    return (
        <div className="slider-container-agenciamiento">
            <div className="height-space-med"></div>
            <h2 className="text-center mb-5"><Trans>servicios.aerolineas_slider_titulo</Trans></h2>
            <Slider {...settings} >
                {slides.map((slide, index) => (
                    <div key={index} className="slide">
                        <div className="container">
                            <div className="row">
                                <div className="d-flex flex-column justify-content-start">
                                    <div className="image-container d-flex justify-content-center">
                                        <img src={slide.image} alt={`Slide ${index + 1}`} className="img-fluid" />
                                    </div>
                                    <div className="content-slider-aerolineas text-start">
                                        <p className="title-slider-agenciamiento">{slide.title}</p>
                                        <p>{slide.text}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                ))}
            </Slider>
            <div className="height-space-med"></div>
            <div className="container-fluid botones-nuestros-servicios botones-bpo text-center mt-5 mt-md-0">
                <Link to="/nuestros-clientes"><button type="button" className="btn btn-primary me-0 me-md-5 mb-3 mb-md-0"><Trans>servicios.aerolineas_servicios_boton_clientes</Trans></button></Link>
                <Link to="/nuestros-servicios"><button type="button" className="btn btn-primary"><Trans>servicios.aerolineas_servicios_boton_servicios</Trans></button></Link>
            </div>
            <div className="height-space-med"></div>
        </div>
    );
};

export default SliderAerolineas;
