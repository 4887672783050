import React, { useEffect } from 'react';
import './styles/NoticiasFormatos.css';
import { Link } from 'react-router-dom';
//import { LinkedinShare } from 'react-share-kit'
import buque_ever from '../assets/Primer buque evergreen en barranquilla fue agenciado por Navemar-ARTICULO-FOTO ABAJO.png'

function NoticiasFormato2() {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const ImagenPrincipal = () => {
        return (

            <div className="container-fluid noticiasformato-2">
                <div className="container-fluid custom-container">
                </div>
            </div>
        );
    };

    function compartirEnRedSocial() {
        if (navigator.share) {
            navigator.share({
                title: 'Buque de la naviera Evergreen atraca en el puerto de Barranquilla tras 12 años',
                text: 'Buque de la naviera Evergreen atraca en el puerto de Barranquilla tras 12 años',
                url: window.location.href
            })
        } else {
            console.log('La API de Web Share no está disponible en este navegador');
        }
    }
    const ContenidoNoticia = () => {
        return (
            <div className="container-fluid">
                <div className='custom-container margen-noticias'>
                    <div className="height-space-med"></div>
                    <div className="row justify-content-center">
                        <p className="topic-blog-titulo text-center">Logística</p>
                        <h1 className="text-center">Buque de la naviera Evergreen atraca en el puerto de Barranquilla tras 12 años</h1>
                    </div>
                    <div className="row justify-content-start">
                        <p className="texto-azul">7 de mayo de 2024</p>
                    </div>
                    <div className="row justify-content-center">
                        <p className="subtitulo mb-4 mb-md-5">La llegada de la MN Ever Career marca un hito en la historia portuaria de la ciudad colombiana</p>
                        <p className=""><span className="texto-bold texto-cursiva">Barranquilla, Colombia</span> - En un acontecimiento que ha sorprendido a la comunidad portuaria y comercial de Barranquilla, el emblemático buque portacontenedores <span className="texto-bold">Ever Career</span>, perteneciente a la renombrada línea marítima <span className="texto-bold">Evergreen</span>, ha hecho su arribo al puerto después de una ausencia notable de 12 años. Esta recalada, que se considera ocasional debido a diversos factores en el sector, marca un acontecimiento en la historia reciente de la actividad marítima de la región. <br /><br />
                        Esta imponente embarcación arribó con un calado de 7.7 metros, navega bajo la bandera de Taiwán y se distingue por sus características técnicas, incluida una eslora de 172 metros, una manga de 27 metros con un peso muerto de 22149 toneladas y una capacidad para cargar 1778 TEUs (Twenty-foot Equivalent Units). Otra de las particularidades de este buque es su tripulación internacional compuesta por 21 personas de nacionalidades  </p>
                    </div>
                    <div className="row justify-content-start justify-content-xl-center mt-3 mt-md-5">
                        <div className="col-12 col-md-6 col-xl-4 d-md-flex d-xl-block align-items-center justify-content-center ">
                            <img src={buque_ever} alt="buque ever" className="imgnoticias-formato-2 mb-4 mb-md-5" />
                        </div>
                        <div className="col-12 col-md-6 col-xl-8">
                            <p className="subtitulo mb-3 mb-md-5">Las operaciones de la MN Ever Career en el Puerto de Barranquilla </p>
                            <p className="">Durante su estadía, el buque movilizo carga en contenedores de importación y exportación con un tiempo de operación de 19 horas.
                            Esto refleja la eficiencia y el profesionalismo de la tripulación y el personal de la terminal portuaria. La presencia de este buque en el puerto de Barranquilla subraya la importancia estratégica de la ciudad como un nodo clave en el comercio marítimo internacional y resalta la capacidad de Barranquilla para adaptarse y responder a las demandas cambiantes del mercado global, sentando así las bases para un futuro prometedor en el ámbito logístico y comercial.</p>
                        </div>
                    </div>
                    <div className="botones-nuestros-servicios botones-bpo text-center mt-3 mt-md-5">
                        <Link to="">
                            <button type="button" className="btn btn-primary me-0 me-md-5 mb-3 mb-md-0" onClick={compartirEnRedSocial}>
                                Compartir Articulo
                            </button>
                        </Link>
                        <Link to="/inicio#blog">
                            <button type="button" className="btn btn-primary">
                                Volver al Inicio
                            </button>
                        </Link>
                    </div>
                    <div className="height-space-med"></div>
                </div>
            </div>
        );
    };


    return (
        <div>
            <ImagenPrincipal />
            <ContenidoNoticia />

        </div>
    );
}

export default NoticiasFormato2;
