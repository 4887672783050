import React, { useState, useRef, useEffect } from 'react';
import './styles/TabsTramites.css';
import PagosTab from './PagosTab';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft, faChevronRight } from '@fortawesome/free-solid-svg-icons';
import ItinerariosTab from './ItinerariosTab';
import FormatosTab from './FormatosTab';
import PoderesTab from './PoderesTab';
import BuquesTab from './BuquesTab';
import SaesTab from './SaesTab';
import CargosTab from './CargosTab';

const TabsTramites = ({ tramites, activeTabFromUrl }) => {
  const [activeTab, setActiveTab] = useState(activeTabFromUrl || tramites[0].valor);
  const [canScrollLeft, setCanScrollLeft] = useState(false);
  const [canScrollRight, setCanScrollRight] = useState(false);
  const tabsRef = useRef(null);

  useEffect(() => {
    const checkScroll = () => {
      if (tabsRef.current) {
        const { scrollLeft, scrollWidth, clientWidth } = tabsRef.current;
        setCanScrollLeft(scrollLeft > 0);
        setCanScrollRight(scrollLeft + clientWidth < scrollWidth);
      }
    };

    checkScroll();
    window.addEventListener('resize', checkScroll);
    return () => {
      window.removeEventListener('resize', checkScroll);
    };
  }, []);

  const scrollLeft = () => {
    if (tabsRef.current) {
      tabsRef.current.scrollLeft -= 150;
    }
  };

  const scrollRight = () => {
    if (tabsRef.current) {
      tabsRef.current.scrollLeft += 150;
    }
  };

  const handleScroll = () => {
    const { scrollLeft, scrollWidth, clientWidth } = tabsRef.current;
    setCanScrollLeft(scrollLeft > 0);
    setCanScrollRight(scrollLeft + clientWidth < scrollWidth);
  };

  return (
    <div className="tabs-container-tramites">
      {canScrollLeft && (
        <div className="scroll-btn left" onClick={scrollLeft}>
          <FontAwesomeIcon icon={faChevronLeft} />
        </div>
      )}
      <div className={`tabs-tramites ${canScrollLeft ? "fade-left" : ""} ${canScrollRight ? "fade-right" : ""}`} ref={tabsRef} onScroll={handleScroll}>
        {tramites.map((item, index) => (
          <button
            key={index}
            className={`tab-tramites ${activeTab === item.valor ? 'active' : ''}`}
            onClick={() => setActiveTab(item.valor)}
          >
            {item.opcion}
          </button>
        ))}
      </div>
      {canScrollRight && (
        <div className="scroll-btn right" onClick={scrollRight}>
          <FontAwesomeIcon icon={faChevronRight} />
        </div>
      )}
      <div className="tab-content-tramites">
        {activeTab === 'pagos' && <PagosTab />}
        {activeTab === 'itinerarios' && <ItinerariosTab/>}
        {activeTab === 'formatos' && <FormatosTab/>}
        {activeTab === 'poderes' && <PoderesTab/>}
        {activeTab === 'buques' && <BuquesTab/>}
        {activeTab === 'saes' && <SaesTab/>}
        {activeTab === 'cargos' && <CargosTab/>}
      </div>
    </div>
  );
};

export default TabsTramites;
