import React, { useState } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimesCircle } from '@fortawesome/free-solid-svg-icons';

import './styles/ContactForm.css'
import linkedin from '../assets/icons/Iconos-LinkedIn-1.svg';
import instagram from '../assets/icons/Iconos-Instagram-2.svg';
import facebook from '../assets/icons/Iconos-Facebook-3.svg';
import { useTranslation } from 'react-i18next';
import config from '../configuration/config';
import MessageModal from './MessageModal';


const ContactForm = () => {
  const { t } = useTranslation();
  const [validated, setValidated] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const openModal = () => setShowModal(true);
  const closeModal = () => setShowModal(false);
  const [formData, setFormData] = useState({
    nombre: '',
    correo: '',
    empresa: '',
    mensaje: '',
    aceptoPolitica: false,
  });

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: type === 'checkbox' ? checked : value,
    }));
  };

  const [submitting, setSubmitting] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    const form = e.currentTarget;

    if (form.checkValidity() === false || !formData.aceptoPolitica || formData.mensaje.length < 100) {
      e.stopPropagation();
      setValidated(true);
      return;
    }

    try {

      setSubmitting(true);

      const response = await fetch(`${config.apiUrl}/contactonavemar`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(formData),
      });

      const data = await response.json();
      if (response.ok && data.message === "OK") {
        openModal();
      }
      setFormData({
        nombre: '',
        correo: '',
        empresa: '',
        mensaje: '',
        aceptoPolitica: false,
      });

      setValidated(false);

    } catch (error) {
      const button_error = [
        { label: t('home_contacto.msj_error_boton_cerrar'), onClick: closeModal },
      ];
      <MessageModal
        show={showModal}
        handleClose={closeModal}
        title={t('home_contacto.msj_error_titulo')}
        body={t('home_contacto.msj_error_texto')}
        buttons={button_error}
        imageType="fail"
      />
    } finally {
      setSubmitting(false);
    }
  };

  const formInputs = [
    { id: 'nombre', type: 'text', placeholder: t('home_contacto.nombre'), msjalerta: t('home_contacto.msjnombre') },
    { id: 'correo', type: 'text', placeholder: t('home_contacto.correo'), msjalerta: t('home_contacto.msjcorreo') },
    { id: 'empresa', type: 'text', placeholder: t('home_contacto.empresa'), msjalerta: t('home_contacto.msjempresa') },
    { id: 'mensaje', type: 'textarea', placeholder: t('home_contacto.mensaje'), msjalerta: t('home_contacto.msjmensaje') }
  ]

  const buttons = [
    { label: t('home_contacto.msj_modal_boton_cerrar'), onClick: closeModal },
  ];


  return (
    <div className='container-fluid bgclaro d-flex align-items-center justify-content-center'>
      <div className='custom-container'>
        <div className="height-space-med"></div>
        <Container fluid>
          <Row className='align-items-center'>
            <Col xs={12} md={5}>
              <div>
                <h2>{t('home_contacto.titulo')}</h2>
                <p>
                  {t('home_contacto.texto')}
                </p>
                <div>
                  <a href="https://www.linkedin.com/company/navemar/" target="_blank" rel="noopener noreferrer">
                    <img src={linkedin} alt="linkedin" className="iconos-social" />
                  </a>
                  <a href="https://www.instagram.com/navemar.colombia/?igsh=MXMycXlmMGk0MHB6dQ%3D%3D" target="_blank" rel="noopener noreferrer">
                    <img src={instagram} alt="instagram" className="iconos-social" />
                  </a>
                  <a href="https://www.facebook.com/navemarcolombia/" target="_blank" rel="noopener noreferrer">
                    <img src={facebook} alt="facebook" className="iconos-social" />
                  </a>
                </div>
              </div>
            </Col>
            <Col xs={1} className="d-flex justify-content-center"></Col>
            <Col md={6} xs={12} className="d-flex justify-content-center">
              <div className="p-2 p-xl-5 contact-form mt-5 mt-md-0">
                <div className="height-space-min"></div>

                <form onSubmit={handleSubmit} noValidate validated={validated ? 'true' : undefined}>

                  {formInputs.map(input => (
                    <div className="mb-3" key={input.id}>
                      {input.type === 'textarea' ? (
                        <>
                          <textarea
                            className={`textarea-form form-control ${validated && (!formData[input.id] || formData[input.id].length < 100) ? 'is-invalid' : ''}`}
                            id={input.id}
                            name={input.id}
                            rows={6}
                            value={formData[input.id]}
                            onChange={handleChange}
                            placeholder={input.placeholder}
                            autoComplete="off"
                            required
                          />
                          {validated && (!formData[input.id] || formData[input.id].length < 100) && (
                            <div className="mensaje-validacion invalid-feedback">
                              <FontAwesomeIcon icon={faTimesCircle} className="me-2" />
                              {input.msjalerta}
                            </div>
                          )}
                        </>
                      ) : (
                        <>
                          <input
                            type={input.type}
                            className={`input-form form-control ${validated && !formData[input.id] ? 'is-invalid' : ''}`}
                            id={input.id}
                            name={input.id}
                            value={formData[input.id]}
                            onChange={handleChange}
                            placeholder={input.placeholder}
                            autoComplete="off"
                            required
                          />
                          {validated && !formData[input.id] && (
                            <div className="mensaje-validacion invalid-feedback">
                              <FontAwesomeIcon icon={faTimesCircle} className="me-2" />
                              {input.msjalerta}
                            </div>
                          )}
                        </>
                      )}
                    </div>
                  ))}
                  <div className="my-4" style={{ marginLeft: '5%' }}>
                    <div className="form-check form-check-inline">
                      <input
                        type="checkbox"
                        className={`form-check-input ${validated && !formData.aceptoPolitica && 'is-invalid'}`}
                        id="aceptoPolitica"
                        name="aceptoPolitica"
                        checked={formData.aceptoPolitica}
                        onChange={handleChange}
                        required
                      />
                      <label className="form-check-label ms-3" htmlFor="aceptoPolitica">
                        <span>{t('home_contacto.politica1')} <a href="/politica-de-tratamiento-de-datos-personales" className={`link-politica ${validated && !formData.aceptoPolitica && 'text-danger'}`} target="_blank">{t('home_contacto.politica2')}</a></span>
                      </label>
                    </div>
                    {validated && !formData.aceptoPolitica && (
                      <div className="invalid-feedback">
                        <FontAwesomeIcon icon={faTimesCircle} className="me-2" />
                        {t('home_contacto.msjpolitica')}
                      </div>
                    )}
                  </div>

                  <div className="d-flex justify-content-center">
                    {!submitting && (
                      <button type="submit" className="btn btn-primary">
                        {t('home_contacto.boton')}
                      </button>
                    )}
                    {submitting &&
                      <div className="d-flex justify-content-center">
                        <div className="spinner-border spinner-custom-color" role="status">
                          <span className="visually-hidden">Loading...</span>
                        </div>
                      </div>}
                  </div>
                </form>
                <div className="height-space-min"></div>
              </div>
            </Col>
          </Row>
        </Container>
        <div className="height-space-med"></div>
        <MessageModal
          show={showModal}
          handleClose={closeModal}
          title={t('home_contacto.msj_modal_titulo')}
          body={t('home_contacto.msj_modal_texto')}
          buttons={buttons}
          imageType="check"
        />
      </div>
    </div>
  );
};

export default ContactForm;
