import React, { useEffect } from 'react';
import './styles/NoticiasFormatos.css';
import { Link } from 'react-router-dom';
import Indicators from './Indicators';
//import { LinkedinShare } from 'react-share-kit'

function NoticiasFormato1() {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const ImagenPrincipal = () => {
        return (

            <div className="container-fluid noticiasformato-1">
                <div className="container-fluid custom-container">
                </div>
            </div>
        );
    };

    function compartirEnRedSocial() {
        if (navigator.share) {
            navigator.share({
                title: 'NYK RORO lleva a cabo transbordos excepcionales en el Puerto de Buenaventura',
                text: 'NYK RORO lleva a cabo transbordos excepcionales en el Puerto de Buenaventura',
                url: window.location.href
            })
        } else {
            console.log('La API de Web Share no está disponible en este navegador');
        }
    }
    const ContenidoNoticia = () => {
        return (
            <div className="container-fluid">
                <div className='custom-container margen-noticias'>
                    <div className="height-space-med"></div>
                    <div className="row justify-content-center">
                        <p className="topic-blog-titulo text-center">Logística</p>
                        <h1 className="text-center titulo-noticias">NYK RORO lleva a cabo transbordos excepcionales en el Puerto de Buenaventura</h1>
                    </div>
                    <div className="row justify-content-start">
                        <p className="texto-azul">14 de mayo de 2024</p>
                    </div>
                    <div className="row justify-content-center">
                        <p className="subtitulo mb-4 mb-md-5">La prestigiosa línea marítima responde a desafíos logísticos con decisiones innovadoras</p>
                        <p className="">En un movimiento inusual pero estratégico, la línea marítima <span className="texto-bold">NYK RORO</span> ha llevado a cabo tres operaciones de transbordo directo (buque a buque) de vehículos en el <span className="texto-bold">Puerto de Buenaventura</span> en los últimos meses, desafiando las convenciones y demostrando su compromiso con la eficiencia operativa en medio de las crecientes congestiones en los puertos de Centroamérica.<br/><br/>
                        El transbordo de vehículos en Buenaventura no es una práctica establecida por NYK RORO. Más bien, es una decisión tomada en respuesta a las complicaciones logísticas y comerciales que han surgido en la región. Las fluctuaciones en los costos, los compromisos de itinerario de los buques y las consideraciones de logística han impulsado a la compañía a tomar medidas excepcionales para garantizar la entrega oportuna de las cargas.</p>
                    </div>
                    <div className="row justify-content-center mt-3 mt-md-5">
                        <p className="subtitulo mb-3 mb-md-5">Los transbordos de NYK RORO redefinen la eficiencia logística en Buenaventura</p>
                        <p className="">Desde octubre de 2023 hasta abril de 2024, se han realizado tres operaciones de transbordo directo de vehículos de NYK, destacando la capacidad de adaptación y planificación de la empresa. En octubre de 2023, se transbordaron más de 1.000 vehículos desde la <span className="texto-bold">MN Cygnus</span> Leader a la <span className="texto-bold">MN Zenit Leader</span>, con destino al Puerto Caldera. En febrero de 2024, la cantidad de vehículos transbordados supero las 2.000 unidades que se movilizaron directamente de la <span className="texto-bold">MN Pluto Leader</span> y la <span className="texto-bold">MN Phoenix Leader</span> a la<span className="texto-bold">MN Metis Leader</span>. Por último, en abril de 2024, se efectuó el transbordo de algo más de 1.000 unidades desde la <span className="texto-bold">MN Demeter Leader</span> a la <span className="texto-bold">MN Jupiter Leader</span>.<br/><br/>
                        Estos transbordos no son simples operaciones de carga y descarga. Requieren una meticulosa planificación y coordinación tanto antes como durante el proceso. La llegada sincronizada de las naves, la asignación de atracaderos, la coordinación del personal de manejo de carga, el trincado y destrincado de los vehículos en ambas embarcaciones, así como la coordinación con las autoridades pertinentes (como La Dirección de Antinarcóticos de la Policía Nacional), para garantizar la seguridad de las cargas, son solo algunas de las tareas que se llevan a cabo con precisión por <span className="texto-bold">Navemar en representación de la línea marítima.</span></p>
                    </div>
                    <div className="row justify-content-start justify-content-xl-center mt-3 mt-md-5">
                        <div className="col-12 col-md-7 col-xl-5">
                            <h2 className="border-top-azul"><Indicators endNumber={4000} prefix="+" decimals={0} time={20} /></h2>
                            <p className="subtitulo mb-3 mb-md-5 border-bottom-azul">Vehículos trasladados directamente entre buques de NYK en los últimos 7 meses</p>
                        </div>
                        <div className="col-12 col-xl-7">
                            <p className="">Aunque los transbordos directos son una práctica común en los buques de contenedores, en los buques RORO son eventos excepcionales que resaltan la versatilidad y excelencia operativa del Puerto de Buenaventura. Sin embargo, cuando surgen estas ocasiones, no solo ponen de relieve la habilidad del puerto para gestionar operaciones poco convencionales con maestría, sino también subrayan el compromiso y la colaboración entre NYK RORO y NAVEMAR. <br/><br/>Estos transbordos destacan la coordinación efectiva entre la empresa y las autoridades portuarias. Esta colaboración sinérgica garantiza un flujo continuo y eficiente de mercancías en la región, consolidando así la posición de Buenaventura como un puerto de referencia en el ámbito logístico marítimo. </p>
                        </div>
                    </div>
                    {/*<div className="row justify-content-center mt-3 mt-md-5">
                        <p className="text-center subtitulo mb-2">Compartir este articulo</p>
                        <div className='col-6 col-md-4 marco-tramites text-center'>
                            <div className="col-12">
                                <LinkedinShare className="share-button" url={url} title={mensaje} blankTarget>
                                    <img src={linkedin} alt="linkedin" className="iconos-social" onClick={compartirEnLinkedin} />
                                </LinkedinShare>
                                <img src={linkedin} alt="linkedin" className="iconos-noticias share-link" onClick={compartirEnRedSocial} />
                                <img src={instagram} alt="instagram" className="iconos-noticias share-link" onClick={compartirEnRedSocial} />
                                <img src={facebook} alt="facebook" className="iconos-noticias share-link" onClick={compartirEnRedSocial}/>
                            </div>
                        </div>
                    </div>*/}
                    <div className="botones-nuestros-servicios botones-bpo text-center mt-3 mt-md-5">
                        <Link to="">
                            <button type="button" className="btn btn-primary me-0 me-md-5 mb-3 mb-md-0" onClick={compartirEnRedSocial}>
                                Compartir Articulo
                            </button>
                        </Link>
                        <Link to="/inicio#blog">
                            <button type="button" className="btn btn-primary">
                                Volver al Inicio
                            </button>
                        </Link>
                    </div>
                    <div className="height-space-med"></div>
                </div>
            </div>
        );
    };


    return (
        <div>
            <ImagenPrincipal />
            <ContenidoNoticia />

        </div>
    );
}

export default NoticiasFormato1;
