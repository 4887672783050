import React, { useEffect } from 'react';
import '../styles/ServicesPage.css';
import { useTranslation, Trans } from 'react-i18next';
import ServiciosComponent from '../ServiciosComponent';
import MetaDescription from '../MetaDescription';

import { Link } from 'react-router-dom';

function ServicesPage() {
    const { t } = useTranslation();
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const ImagenPrincipal = () => {
        return (
            <div className="portadaservicesimg">
                <div className="container-fluid custom-container">
                    <div className="content-portada">
                        <h1><Trans>servicios.titulo_principal</Trans></h1>
                        <div className="buttons-container">
                            <Link to="/contactenos"><button className="btn btn-primary me-4"><Trans>servicios.boton_principal1</Trans></button></Link>
                        </div>
                    </div>
                </div>
            </div>
        );
    };

    const NuestrosServicios = () => {
        return (
            <div className="container-fluid">
                    <div className="height-space-med"></div>
                    <h3 className="text-center"><Trans i18nKey="servicios.texto_servicios" components={{ text_blue: <span className="texto-azul"/>}} /></h3>
                    <div className="height-space-min"></div>
                    <div className='row'>
                        <ServiciosComponent text="-blue" />
                    </div>
                    <div className="botones-servicios text-center mt-4 mt-md-5">
                        <Link to="/contactenos"><button type="button" className="btn btn-third me-0 me-md-5 mb-3 mb-md-0"><Trans>servicios.boton_contacto</Trans></button></Link>
                        <Link to="/nuestros-clientes"><button type="button" className="btn btn-primary"><Trans>servicios.boton_clientes</Trans></button></Link>
                    </div>
                    <div className="height-space-med"></div>
                </div>
        );
    };




    return (
        <div>
            <MetaDescription content={t('servicios.meta_descripcion')}/>
            <ImagenPrincipal />
            <NuestrosServicios />
        </div>
    );
}

export default ServicesPage;
