import React from 'react';
import '../styles/PreguntasPage.css';
import { Trans } from 'react-i18next';
import TabsComponent from '../TabsComponent';

function PreguntasPage() {

    const PreguntasFrecuentes = () => {
        const lineas = [
            { linea: "Evergreen", valor: "EVER" },
            { linea: "Hapag-Lloyd", valor: "HAPAG" },
            { linea: "NYK", valor: "NYK" },
        ];

        return (
            <div className="container-fluid">
                <div className="height-space-med"></div>
                <div className="custom-container">
                    <h1><Trans>preguntas_frecuentes.titulo</Trans></h1>
                    <p className="subtitulo mt-4"><Trans>preguntas_frecuentes.texto</Trans></p>
                </div>
                <div className="custom-container mt-5">
                    <TabsComponent lineas={lineas} />
                </div>
                <div className="height-space-min"></div>
            </div>
        );
    };

    return (
        <div>
            <PreguntasFrecuentes />
            
        </div>
    );
}

export default PreguntasPage;
