import React, { useEffect } from 'react';
import '../styles/DiversidadPage.css';
import SliderDiversidad from '../SliderDiversidad';
import { Trans } from 'react-i18next';
import { Link } from 'react-router-dom';
import imageDiversidad from '../../assets/Diversidad Equidad de Genero e Inclusion-2-Navemar.png';
import imageDiversidad2 from '../../assets/Diversidad Equidad de Genero e Inclusion-3-Navemar.png';

function DiversidadPage() {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const ImagenPrincipal = () => {
        return (
            <div className="portadaimg-diversidad">
                <div className="container-fluid custom-container">
                    <div className="content-portada-diversidad">
                        <h3><Trans>diversidad.titulo_principal</Trans></h3>
                        <p><Trans>diversidad.texto_principal</Trans></p>
                        <div className="buttons-container">
                            <Link to="/contactenos"><button className="btn btn-primary me-4"><Trans>diversidad.boton_principal1</Trans></button></Link>
                        </div>
                    </div>
                </div>
            </div>
        );
    };

    const SeccionUno = () => {
        return (
            <div className="container-fluid custom-container">
                <div className="height-space-med"></div>
                <div className='row'>
                    <div className='col-12 col-md-6 text-center text-lg-end d-flex align-items-center justify-content-lg-start order-2 order-xl-1'>
                        <img src={imageDiversidad} alt="Quienes Somos" className="img-fluid imgdiversidad-social" />
                    </div>
                    <div className='col-12 col-xl-6 d-flex align-items-center order-1 order-xl-2'>
                        <div className="text-min">
                            <h2 className='mb-4'><Trans>diversidad.seccion_uno_titulo</Trans></h2>
                            <p className="d-none d-xl-block"><Trans i18nKey="diversidad.seccion_uno_texto" components={{ text_bold: <span className="texto-bold"/>}} /></p>
                        </div>
                    </div>
                    <div className='col-12 col-md-6 text-justify mt-4 d-block d-xl-none d-flex align-items-center order-3 order-xl-3'>
                        <p className=""><Trans i18nKey="diversidad.seccion_uno_texto" components={{ text_bold: <span className="texto-bold"/>}}/></p>
                    </div>
                </div>
                <div className="height-space-med"></div>
            </div>
        );
    };

    const SeccionDos = () => {
        return (
            <div className="container-fluid bgclaro">
                <div className="custom-container">
                    <div className="height-space-med"></div>
                    <div className='row'>
                        <div className='col-12 col-xl-6 d-flex align-items-center order-1 order-xl-2'>
                            <div className="text-min">
                                <h3 className='mb-4'><Trans>diversidad.seccion_dos_titulo</Trans></h3>
                                <p className="d-none d-xl-block"><Trans i18nKey="diversidad.seccion_dos_texto" components={{ text_bold: <span className="texto-bold"/>}} /></p>
                            </div>
                        </div>
                        <div className='col-12 col-md-6 text-justify mt-4 d-block d-xl-none d-flex align-items-center order-3 order-md-2'>
                            <p><Trans i18nKey="diversidad.seccion_dos_texto" components={{ text_bold: <span className="texto-bold"/>}} /></p>
                        </div>
                        <div className='col-12 col-md-6 text-center text-lg-end d-flex align-items-center justify-content-lg-end order-2 order-md-3'>
                            <img src={imageDiversidad2} alt="Quienes Somos" className="img-fluid imgdiversidad-social" />
                        </div>
                    </div>
                    <div className="height-space-med"></div>
                </div>
            </div>
        );
    };

    return (
        <div>
            <ImagenPrincipal />
            <SeccionUno />
            <SeccionDos />
            <SliderDiversidad />
        </div>
    );
}

export default DiversidadPage;
