import React from 'react';
import { Modal, Button } from 'react-bootstrap';
import './styles/MessageModal.css';
import check from '../assets/icons/Icono-Check-Azul-Navemar.svg';
import fail from '../assets/icons/Icono-error-azul-navemar.svg';

const MessageModal = ({ show, handleClose, title, body, buttons, imageType }) => {

  const getImageSrc = () => {
    switch (imageType) {
      case 'check':
        return check;
      case 'fail':
        return fail;
      default:
        return null;
    }
  };

  return (
    <Modal
      show={show}
      onHide={handleClose}
      centered
      className="rounded-modal"
      backdrop="static"  // Agregado para evitar el cierre al hacer clic fuera del modal
      keyboard={false}   // Agregado para evitar el cierre con la tecla Esc
    >
      <Modal.Header className="modal-header d-flex flex-column align-items-center mt-md-5">
        {getImageSrc() && (
          <div className="d-flex align-items-center justify-content-center">
            <img src={getImageSrc()} alt="Icono" className="modal_imagen" />
          </div>
        )}
        <h3 className="modal-title-centered">{title}</h3>
      </Modal.Header>
      <Modal.Body className="modal-body text-center mensaje-modal">{body}</Modal.Body>
      <Modal.Footer className="modal-footer d-flex flex-column align-items-center mb-md-5">
        {buttons.map((button, index) => (
          <Button
            key={index}
            onClick={button.onClick}
            className={button.clase}
          >
            {button.label}
          </Button>
        ))}
      </Modal.Footer>
    </Modal>
  );
};

export default MessageModal;
