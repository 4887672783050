import React, { useState, useEffect } from 'react';
import '../styles/RegistroPage.css';
import { Trans } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import config from '../../configuration/config';
import fileCarga from '../../assets/icons/icono-cargas-documentos-navemar-azul.svg';
import fileInformacion from '../../assets/icons/Icono-informacion-navemar-gris.svg';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Popover from 'react-bootstrap/Popover';
import MessageModal from '../MessageModal';

const RegistroPage = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    
    const openModal = () => setShowModal(true);
    const closeModal = () => setShowModal(false);
    const navigate = useNavigate();
    const [showModal, setShowModal] = useState(false);
    const redirectToPage = (e) => {
        navigate('/');
    };
    const buttons = [
        { label: <Trans>registro.msj_modal_boton_inicio</Trans>, onClick: redirectToPage },
    ];

    const buttons_error = [
        { label: <Trans>registro.msj_modal_boton_cerrar</Trans>, onClick: closeModal },
    ];
    const [formData, setFormData] = useState({
        nit: '',
        razonSocial: '',
        nombreContacto: '',
        cargoContacto: '',
        telefonoFijo: '',
        telefonoMovil: '',
        email: '',
        emailFacturacion: '',
        direccion: '',
        ciudad: '',
        baq: 'N',
        bog: 'N',
        bun: 'N',
        smr: 'N',
        ctg: 'N',
        regimenIVA: '',
        regimenRetefuente: '',
        regimenReteiva: '',
        codigoCIIU: '',
        rut: null,
        password: '',
        confirmPassword: '',
        aceptaPolitica: false,
    });

    const [selectedFileName, setSelectedFileName] = useState('');
    const [responseMessage, setResponseMessage] = useState('');
    const [showButton, setshowButton] = useState(true);
    const [citiesapi, setCities] = useState([]);
    const [ivaApi, setIva] = useState([]);
    const [retFuenteApi, setRetFuente] = useState([]);
    const [retIvaApi, setRetIva] = useState([]);
    const [tituloModal, setTitulo] = useState('');
    const [textoModal, setTexto] = useState('');
    const [iconoModal, setIcono] = useState('check');
    const [buttonModal, setButton] = useState(buttons);

    const handleChange = (e) => {
        const { id, name, value, type, files, checked } = e.target;
        const newValue = type === 'file' ? files[0] : type === 'checkbox' ? (checked ? id : 'N') : value;

        if (type === 'file') {
            const selectedFile = e.target.files[0];
            const fileExtension = selectedFile.name.split('.').pop().toLowerCase();

            if (fileExtension !== 'pdf') {
                setTitulo(<Trans>registro.error_archivo_titulo</Trans>);
                setTexto(<Trans>registro.error_archivo_texto</Trans>);
                setIcono('fail');
                setButton(buttons_error);
                e.target.value = '';
                openModal();
                return;
            }

            setSelectedFileName(selectedFile ? selectedFile.name : '');
        }

        setFormData({
            ...formData,
            [name]: newValue,
        });
    };

    const validatePassword = (password) => {
        const regex = /^(?=.*[A-Z])(?=.*\d).+$/;
        return regex.test(password);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        const requiredFields = ['nit', 'razonSocial', 'nombreContacto', 'cargoContacto', 'telefonoFijo', 'telefonoMovil', 'email', 'emailFacturacion', 'direccion', 'ciudad', 'regimenIVA', 'regimenRetefuente', 'regimenReteiva', 'codigoCIIU', 'rut', 'password', 'confirmPassword', 'aceptaPolitica'];
        const missingFields = requiredFields.filter(field => !formData[field]);

        if (missingFields.length > 0) {
            setTitulo(<Trans>registro.error_registro_titulo</Trans>);
            setTexto(<Trans>registro.error_registro_texto</Trans>);
            setIcono('fail');
            setButton(buttons_error);
            openModal();
            return;
        }

        const isAnyCityChecked = ['baq', 'bog', 'bun', 'smr', 'ctg'].some(city => formData[city] === city.toUpperCase());
        if (!isAnyCityChecked) {
            setTitulo(<Trans>registro.error_registro_titulo</Trans>);
            setTexto(<Trans>registro.error_ciudad_texto</Trans>);
            setIcono('fail');
            setButton(buttons_error);
            openModal();
            return;
        }

        if (formData.password !== formData.confirmPassword) {
            setTitulo(<Trans>registro.error_registro_titulo</Trans>);
            setTexto(<Trans>registro.error_password_texto</Trans>);
            setIcono('fail');
            setButton(buttons_error);
            openModal();
            return;
        }

        if (!validatePassword(formData.password)) {
            setTitulo(<Trans>registro.error_registro_titulo</Trans>);
            setTexto(<Trans>registro.error_password_reglas_texto</Trans>);
            setIcono('fail');
            setButton(buttons_error);
            openModal();
            return;
        }

        const formDataToSend = new FormData();
        for (const key in formData) {
            formDataToSend.append(key, formData[key]);
        }

        try {
            setshowButton(false);
            const response = await fetch(`${config.apiUrl}/registro-navemar`, {
                method: 'POST',
                body: formDataToSend,
            });
            const data = await response.json();
            if (data.status === 'success' && data.message === 'Registro exitoso') {
                setResponseMessage('');
                setTitulo(<Trans>registro.msj_modal_titulo</Trans>);
                setTexto(<Trans>registro.msj_modal_texto</Trans>);
                setIcono('check');
                setButton(buttons);
                openModal();
            } else {
                setResponseMessage(data.message);
                setshowButton(true);
            }
        } catch (error) {
            setResponseMessage('Error en el registro '+error);
            setshowButton(true);
        }
    };

    const cities = [
        { valor: 'BAQ', nombre: 'baq' },
        { valor: 'BOG', nombre: 'bog' },
        { valor: 'BUN', nombre: 'bun' },
        { valor: 'CTG', nombre: 'ctg' },
        { valor: 'SMR', nombre: 'smr' },
    ];

    const [isTablet, setIsTablet] = useState(window.innerWidth <= 1024);

    useEffect(() => {
        const handleResize = () => {
            setIsTablet(window.innerWidth <= 1024);
        };
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    const placement = isTablet ? 'top' : 'right';

    const TooltipPopover = ({ texto }) => {
        return (
            <OverlayTrigger
                trigger={['click', 'focus', 'hover']}
                key={`top`}
                placement={placement}
                overlay={
                    <Popover className="custom-popover-registro">
                        <Popover.Body>
                            <p>{texto}</p>
                        </Popover.Body>
                    </Popover>
                }
            >
                <img src={fileInformacion} alt="Imagen" className="icon-form" />
            </OverlayTrigger>
        );
    };

    const fetchDataAndSetData = async (endpoint, setData) => {
        try {
            const response = await fetch(endpoint, {
                method: 'POST',
            });
            const data = await response.json();
            setData(data);
        } catch (error) {
            console.error(`Error al obtener datos desde ${endpoint}:`, error);
            setData([]);
        }
    };

    useEffect(() => {
        fetchDataAndSetData(`${config.apiUrl}/consulta-ciudades`, setCities);
        fetchDataAndSetData(`${config.apiUrl}/consulta-regimeniva`, setIva);
        fetchDataAndSetData(`${config.apiUrl}/consulta-retfuente`, setRetFuente);
        fetchDataAndSetData(`${config.apiUrl}/consulta-retiva`, setRetIva);
    }, []);


    return (
        <div className="container-fluid registro-container">
            <div className="custom-container">
                <h1 className="registro-title"><Trans>registro.titulo</Trans></h1>
                <p className="text-center"><Trans>registro.texto</Trans></p>
                {responseMessage && <div className="alert alert-info">{responseMessage}</div>}
                <form className="registro-form" onSubmit={handleSubmit}>
                    <div className="row mb-4">
                        <div className="col-12 col-md-6 mb-4 mb-md-0">
                            <label className="form-check-label" htmlFor="nit"><Trans>registro.nit</Trans></label>
                            <input type="text" name="nit" value={formData.nit} onChange={handleChange} maxLength={12} required />
                        </div>
                        <div className="col-12 col-md-6">
                            <label className="form-check-label" htmlFor="razonSocial"><Trans>registro.razon_social</Trans></label>
                            <input type="text" name="razonSocial" value={formData.razonSocial} onChange={handleChange} maxLength={100} required />
                        </div>
                    </div>
                    <div className="row mb-4">
                        <div className="col-12 col-md-6 mb-4 mb-md-0">
                            <label className="form-check-label" htmlFor="nombreContacto"><Trans>registro.nombre_contacto</Trans></label>
                            <input type="text" name="nombreContacto" value={formData.nombreContacto} onChange={handleChange} maxLength={100} required />
                        </div>
                        <div className="col-12 col-md-6">
                            <label className="form-check-label" htmlFor="cargoContacto"><Trans>registro.cargo_contacto</Trans></label>
                            <input type="text" name="cargoContacto" value={formData.cargoContacto} onChange={handleChange} maxLength={50} required />
                        </div>
                    </div>
                    <div className="row mb-4">
                        <div className="col-12 col-md-6 mb-4 mb-md-0">
                            <label className="form-check-label" htmlFor="telefonoFijo"><Trans>registro.telefono_fijo</Trans></label>
                            <input type="tel" name="telefonoFijo" value={formData.telefonoFijo} onChange={handleChange} maxLength={50} required />
                        </div>
                        <div className="col-12 col-md-6">
                            <label className="form-check-label" htmlFor="telefonoMovil"><Trans>registro.telefono_movil</Trans></label>
                            <input type="tel" name="telefonoMovil" value={formData.telefonoMovil} onChange={handleChange} maxLength={50} required />
                        </div>
                    </div>
                    <div className="row mb-4">
                        <div className="col-12">
                            <label className="form-check-label" htmlFor="email"><Trans>registro.correo</Trans></label>
                            <input type="email" name="email" value={formData.email} onChange={handleChange} maxLength={200} required />
                        </div>
                    </div>
                    <div className="row mb-4">
                        <div className="col-12">
                            <label className="form-check-label" htmlFor="emailFacturacion"><Trans>registro.correo_facturas</Trans><TooltipPopover texto="Correo oficial al que debe llegar la factura electrónica." /></label>
                            <input type="email" name="emailFacturacion" value={formData.emailFacturacion} onChange={handleChange} maxLength={200} required />
                        </div>
                    </div>
                    <div className="row mb-4">
                        <div className="col-12 col-md-8 mb-4 mb-md-0">
                            <label className="form-check-label" htmlFor="direccion"><Trans>registro.direccion</Trans></label>
                            <input type="text" name="direccion" value={formData.direccion} onChange={handleChange} maxLength={100} required />
                        </div>
                        <div className="col-12 col-md-4">
                            <label className="form-check-label" htmlFor="ciudad"><Trans>registro.ciudad</Trans></label>
                            <select className="custom-select-registro" name="ciudad" onChange={handleChange} required>
                                <option value=""></option>
                                {citiesapi.map((city) => (
                                    <option key={city.codigo} value={city.codigo}>
                                        {city.nombre}
                                    </option>
                                ))}
                            </select>
                        </div>
                    </div>
                    <div className="row mb-4">
                        <div className="col-12">
                            <div>
                                <label><Trans>registro.tributa</Trans></label>
                                <div className="d-flex flex-wrap gap-2">
                                    {cities.map((city) => (
                                        <div className="switch-container" key={city.valor}>
                                            <label className="form-check-label">{city.valor}</label>
                                            <div className="form-check form-switch">
                                                <input
                                                    className="form-check-input"
                                                    type="checkbox"
                                                    id={city.valor}
                                                    name={city.nombre}
                                                    onChange={handleChange}
                                                />
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row mb-4">
                        <div className="col-12 col-md-6 mb-4 mb-md-0">
                            <label className="form-check-label" htmlFor="regimenIVA"><Trans>registro.regimen_iva</Trans></label>
                            <select className="custom-select-registro" name="regimenIVA" onChange={handleChange} required>
                                <option value=""></option>
                                {ivaApi.map((iva) => (
                                    <option key={iva.codigo} value={iva.codigo}>
                                        {iva.nombre}
                                    </option>
                                ))}
                            </select>
                        </div>
                        <div className="col-12 col-md-6">
                            <label className="form-check-label" htmlFor="regimenRetefuente"><Trans>registro.ret_fuente</Trans></label>
                            <select className="custom-select-registro" name="regimenRetefuente" value={formData.regimenRetefuente} onChange={handleChange} required>
                                <option value=""></option>
                                {retFuenteApi.map((retfuente) => (
                                    <option key={retfuente.codigo} value={retfuente.codigo}>
                                        {retfuente.nombre}
                                    </option>
                                ))}
                            </select>
                        </div>
                    </div>
                    <div className="row mb-4">
                        <div className="col-12 col-md-6 mb-4 mb-md-0">
                            <label className="form-check-label" htmlFor="regimenReteiva"><Trans>registro.ret_iva</Trans></label>
                            <select className="custom-select-registro" name="regimenReteiva" value={formData.regimenReteiva} onChange={handleChange} required>
                                <option value=""></option>
                                {retIvaApi.map((retiva) => (
                                    <option key={retiva.codigo} value={retiva.codigo}>
                                        {retiva.nombre}
                                    </option>
                                ))}
                            </select>
                        </div>
                        <div className="col-12 col-md-6">
                            <label className="form-check-label" htmlFor="codigoCIIU"><Trans>registro.codigo_ciiu</Trans><TooltipPopover texto="Clasificación industrial internacional uniforme de todas las actividades económicas (CIIU)" /></label>
                            <input type="text" name="codigoCIIU" value={formData.codigoCIIU} onChange={handleChange} maxLength={5} required />
                        </div>
                    </div>
                    <div className="row mb-4">
                        <div className="col-12">
                            <label className="form-check-label" htmlFor="rut"><Trans>registro.rut</Trans><TooltipPopover texto="El documento debe tener fecha de generación inferior a 30 días. Recibirá un correo de confirmación una vez que su documento haya sido verificado y aprobado." /></label>
                            <div className="container-file">
                                <input name="rut" type="file" onChange={handleChange} accept="application/pdf" required />
                                <span>{selectedFileName}</span>
                                <img src={fileCarga} alt="Imagen" />
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12 col-md-6 mb-4 mb-md-0">
                            <label className="form-check-label" htmlFor="password"><Trans>registro.password</Trans></label>
                            <input type="password" name="password" value={formData.password} onChange={handleChange} minLength={8} maxLength={8} required />
                        </div>
                        <div className="col-12 col-md-6">
                            <label className="form-check-label" htmlFor="confirmPassword"><Trans>registro.confirma_password</Trans></label>
                            <input type="password" name="confirmPassword" value={formData.confirmPassword} onChange={handleChange} minLength={8} maxLength={8} required />
                        </div>
                    </div>
                    <div className="row justify-content-center mt-5">
                        <div className="col-12 text-center">
                            {showButton && <button type="submit" className="btn-primary registro-button"><Trans>registro.boton</Trans></button>}
                        </div>
                    </div>
                    <div className="row justify-content-center">
                        <div className="col-12 text-center">
                            <div className="my-4">
                                <div className="form-check form-check-inline text-center">
                                    <input
                                        type="checkbox"
                                        className={`form-check-input`}
                                        id="aceptaPolitica"
                                        name="aceptaPolitica"
                                        checked={formData.aceptaPolitica}
                                        onChange={handleChange}
                                        required
                                    />
                                    <label className="form-check-label ms-3" htmlFor="aceptaPolitica">
                                        <span><Trans>contacto.politica1</Trans> <a href="/politica-de-tratamiento-de-datos-personales" className={`link-politica`} target="_blank"><Trans>contacto.politica2</Trans></a></span>
                                    </label>
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
            <MessageModal
                show={showModal}
                title={tituloModal}
                body={textoModal}
                buttons={buttonModal}
                imageType={iconoModal}
            />
        </div>
    );
};

export default RegistroPage;
