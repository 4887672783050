import React from 'react';
import '../styles/NotFoundPage.css';
import { Trans } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import warningIcono from '../../assets/icons/Icono-warning-Pagina-no-econtrada-Navemar.svg';

const NotFoundPage = () => {
  const navigate = useNavigate();
  const handleGoHome = () => {
    navigate('/');
  };
  return (
    <div className="container-fluid bgclaro">
      <div className="custom-container">
        <div className="height-space"></div>
        <div className="not-found-container">
        <div className="d-flex align-items-center justify-content-center">
            <img src={warningIcono} alt="Icono" className="modal_imagen" />
          </div>
          <h1><Trans>notfound.titulo</Trans></h1>
          <p className="text-center"><Trans>notfound.texto</Trans></p>
          <button type="button" className="btn btn-primary" onClick={handleGoHome}><Trans>notfound.boton</Trans></button>
        </div>
        <div className="height-space"></div>
        <div className="height-space-min"></div>
      </div>
    </div>
  );
}

export default NotFoundPage;