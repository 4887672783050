import React from 'react';
import { Trans } from 'react-i18next';
import comercial from '../assets/icons_aerolinea/Icono-soporte-comercial-navemar.svg'
import entrenamiento from '../assets/icons_aerolinea/Icono-entrenamiento-personalizado-navemar.svg'
import oficinas from '../assets/icons_aerolinea/icono-oficinas y personal calificado-navemar.svg'
import conocimiento from '../assets/icons_aerolinea/Icono-conocimiento del mercado colombiano-navemar.svg'
import mercadeo from '../assets/icons_aerolinea/Icono-servicios de mercadeo y relaciones publicas-navemar.svg'
import gestion_administrativa from '../assets/icons_aerolinea/Icono-gestion administrativa completa-navemar.svg'
import gestion_ventas from '../assets/icons_aerolinea/Icono.gestion de ventas y coordinacion operativa-navemar.svg'
import gestion_financiera from '../assets/icons_aerolinea/Icono Gestion financiera integral-navemar.svg'

/**
 * Página de "ServiciosAerolinea".
 * @component
 * @returns {JSX.Element} Componente con los servicios que brinda de la empresa "ServiciosAerolinea".
 */
function ServiciosAerolinea(props) {

    const services = [
        {
            image: comercial,
            title: <Trans>servicios.aerolineas_servicios_comercial_titulo</Trans>,
            description: <Trans>servicios.aerolineas_servicios_comercial_texto</Trans>,
        },
        {
            image: entrenamiento,
            title: <Trans>servicios.aerolineas_servicios_entrenamiento_titulo</Trans>,
            description: <Trans>servicios.aerolineas_servicios_entrenamiento_texto</Trans>,
        },
        {
            image: oficinas,
            title: <Trans>servicios.aerolineas_servicios_oficinas_titulo</Trans>,
            description: <Trans>servicios.aerolineas_servicios_oficinas_texto</Trans>,
        },
        {
            image: conocimiento,
            title: <Trans>servicios.aerolineas_servicios_conocimiento_titulo</Trans>,
            description: <Trans>servicios.aerolineas_servicios_conocimiento_texto</Trans>,
        },
        {
            image: mercadeo,
            title: <Trans>servicios.aerolineas_servicios_mercadeo_titulo</Trans>,
            description: <Trans>servicios.aerolineas_servicios_mercadeo_texto</Trans>,
        },
        {
            image: gestion_administrativa,
            title: <Trans>servicios.aerolineas_servicios_gestion_administrativa_titulo</Trans>,
            description: <Trans>servicios.aerolineas_servicios_gestion_administrativa_texto</Trans>,
        },
        {
            image: gestion_ventas,
            title: <Trans>servicios.aerolineas_servicios_gestion_ventas_titulo</Trans>,
            description: <Trans>servicios.aerolineas_servicios_gestion_ventas_texto</Trans>,
        },
        {
            image: gestion_financiera,
            title: <Trans>servicios.aerolineas_servicios_gestion_financiera_titulo</Trans>,
            description: <Trans>servicios.aerolineas_servicios_gestion_financiera_texto</Trans>,
        },
    ];

    return services.map((item, index) => (
        <div key={index} className='col-12 col-md-6 col-xl-3 p-4 p-xl-3'>
            <div className={`card card-services${props.text||''}`}>
                <div className="card-body text-center">
                    <div className="card-title mb-4">
                        <img src={item.image} alt={index} className="iconos-aerolinea" />
                    </div>
                    <p className="subtitulo">{item.title}</p>
                    <p className="card-text-rep">{item.description}</p>
                </div>
            </div>
        </div>
    ));
};


export default ServiciosAerolinea;