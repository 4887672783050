import React, { useState, useEffect } from 'react';
import Menu from './Menu';
import MenuMobile from './MenuMobile';
//import { useTranslation } from 'react-i18next';


const Header = () => {
    const [isMobile, setIsMobile] = useState(window.innerWidth <= 1024);

    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth <= 1024);
        };

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);
    return (
        <header>
            <div>
                <div>
                    {isMobile ? <MenuMobile /> : <Menu />}
                </div>
            </div>

        </header>

    );
};

export default Header;