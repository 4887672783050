import React from 'react';
import './styles/Map.css'

const Map = () => {
  const apiKey = 'AIzaSyAsZjNCfl8DGBAFcgYw0Yy1Axe3vUviq6E';
  const location = '4.681462696548758,-74.04039421720533';
  const zoom = 13;
  // Usa el tamaño de 100%x100% para que la imagen llene completamente el contenedor
  const size = '600x300&scale=2'; // Este valor ya no será relevante para el estilo responsive
  const mapType = 'roadmap';
  const marker = 'size:mid%7Ccolor:red%7CNavemar';

  const mapUrl = `https://maps.googleapis.com/maps/api/staticmap?center=${location}&zoom=${zoom}&size=${size}&maptype=${mapType}&markers=${marker}&key=${apiKey}`;

  return (
    <div className="map-container">
      <img src={mapUrl} alt="Static Map" style={{width: '100%', height: 'auto'}} />
    </div>
  );
};

export default Map;

