import React, { useEffect } from 'react';
import MetaDescription from '../MetaDescription';
import '../styles/AboutPage.css';
import { Trans, useTranslation } from 'react-i18next';
import privacidad from '../../assets/icons/Icono-Privacidad y Compromiso-Navemar-1.svg';
import etica from '../../assets/icons/Icono-Etica e integridad-Navemar-2.svg';
import transparencia from '../../assets/icons/Icono-Transparencia-Navemar-3.svg';
import respeto from '../../assets/icons/Icono-Respeto-Navemar-4.svg';
//import historiaimg from '../../assets/base-linea del tiempo-historia-navemar.png'
import Sucursales from '../Sucursales';
import MapaClientes from '../MapClients';
import { Link, useLocation  } from 'react-router-dom';

function AboutPage() {
  const { t } = useTranslation();
  const location = useLocation();

  useEffect(() => {
    if (location.hash) {
      const id = location.hash.replace('#', '');
      const element = document.getElementById(id);
      if (element) {
        element.scrollIntoView({ behavior: 'smooth' });
      }
    } else {
      window.scrollTo({ top: 0, behavior: 'smooth' });
    }
  }, [location]);

  const ImagenPrincipal = () => {
    return (
      <div className="portadaimg">
        <div className="container-fluid custom-container">
          <div className="content-portada">
            <h1><Trans>quienes_somos.titulo_principal</Trans></h1>
            <p><Trans>quienes_somos.texto_principal</Trans></p>
            <div className="buttons-container">
              <Link to="/contactenos"><button className="btn btn-third me-4"><Trans>quienes_somos.boton_principal1</Trans></button></Link>
              <Link to="/nuestros-servicios"><button className="btn btn-primary"><Trans>quienes_somos.boton_principal2</Trans></button></Link>
            </div>
          </div>
        </div>
      </div>
    );
  };

  const NuestraHistoria = () => {
    return (
      <div className="container-fluid custom-container">
        <div className="height-space-med"></div>
        <h2 className="text-center"><Trans>quienes_somos.nuestra_historia</Trans></h2>
        <div className="timeline-container mt-4 mt-md-3">
          <div className="timeline-event-1">
            <p className="year-title subtitulo mb-2">1953</p>
            <p><Trans>quienes_somos.nuestra_historia_evento1</Trans></p>
          </div>
          <div className="timeline-event-2">
            <p className="year-title subtitulo mb-2">1968</p>
            <p><Trans>quienes_somos.nuestra_historia_evento2</Trans></p>
          </div>
          <div className="timeline-event-3">
            <p className="year-title subtitulo mb-2">1974</p>
            <p><Trans>quienes_somos.nuestra_historia_evento3</Trans></p>
          </div>
          <div className="timeline-event-4">
            <p className="year-title subtitulo mb-2">2000</p>
            <p><Trans i18nKey="quienes_somos.nuestra_historia_evento4" components={{ text_bold: <span className="texto-bold"/>}}/></p>
          </div>
          <div className="timeline-event-5">
            <p className="year-title subtitulo mb-2">2008</p>
            <p><Trans>quienes_somos.nuestra_historia_evento5</Trans></p>
          </div>
          <div className="timeline-event-6">
            <p className="year-title subtitulo mb-2">2015</p>
            <p><Trans>quienes_somos.nuestra_historia_evento6</Trans></p>
          </div>
          <div className="timeline-event-7">
            <p className="year-title subtitulo mb-2">2018</p>
            <p><Trans>quienes_somos.nuestra_historia_evento7</Trans></p>
          </div>
          <div className="timeline-event-8">
            <p className="year-title subtitulo mb-2">2019</p>
            <p><Trans>quienes_somos.nuestra_historia_evento8</Trans></p>
          </div>
          <div className="timeline-event-9">
            <p className="year-title subtitulo mb-2">2024</p>
            <p><Trans>quienes_somos.nuestra_historia_evento9</Trans></p>
          </div>
        </div>
      </div>
    );
  };

  const ImagenOlas = () => {
    return (
      <div>
        <div className="height-space-med"></div>
        <div className="img-olas">
        </div>
      </div>
    );
  };


  const valores = [
    {
      image: privacidad,
      title: t('quienes_somos.nuestros_valores_privacidad_titulo'),
      description: t('quienes_somos.nuestros_valores_privacidad_texto'),
      buttonText: t('quienes_somos.nuestros_valores_privacidad_boton'),
      url: '/politica-de-tratamiento-de-datos-personales'
    },
    {
      image: etica,
      title: t('quienes_somos.nuestros_valores_etica_titulo'),
      description: t('quienes_somos.nuestros_valores_etica_texto'),
      buttonText: t('quienes_somos.nuestros_valores_etica_boton'),
      url: '/manual-de-prevencion-de-lavado-de-activos-y-financiacion-del-terrorismo'
    },
    {
      image: transparencia,
      title: t('quienes_somos.nuestros_valores_transparencia_titulo'),
      description: t('quienes_somos.nuestros_valores_transparencia_texto'),
      buttonText: t('quienes_somos.nuestros_valores_transparencia_boton'),
      url: '/responsabilidad-social-y-empresarial'
    },
    {
      image: respeto,
      title: t('quienes_somos.nuestros_valores_respeto_titulo'),
      description: t('quienes_somos.nuestros_valores_respeto_texto'),
      buttonText: t('quienes_somos.nuestros_valores_respeto_boton'),
      url: '/diversidad-equidad-de-genero-e-inclusion'
    }
  ];

  const renderValores = () => {
    return valores.map((item, index) => (
      <div key={index} className="col-12 col-sm-6 col-md-6 col-xl-3 p-4">
        <div className="text-center">
          <div className="card-valores mb-4">
            <img src={item.image} alt={index} className="img-fluid" />
          </div>
          <p className="subtitulo">{item.title}</p>
          <p>{item.description}</p>
          <div className="d-grid gap-2 justify-content-center">
            <Link to={item.url}><button className="btn btn-primary" type="button">{item.buttonText}</button></Link>
          </div>
        </div>
      </div>
    ));
  };


  const NuestrosValores = () => {
    return (
      <div id="nuestros-valores" className='container-fluid bgclaro'>
        <div className='custom-container'>
          <div className="height-space-med"></div>
          <div className='row'>
            <div className='col-12 mt-4'>
              <h3 className="mb-5"><Trans>quienes_somos.titulo_nuestros_valores</Trans></h3>
              <p className="text-justify"><Trans i18nKey="quienes_somos.texto_nuestros_valores" components={{ text_bold: <Link className="texto-azul-link" to='/nuestros-clientes'/>}} /></p>
            </div>
          </div>
          <div className='row mb-5'></div>
          <div className='row'>
            {renderValores()}
          </div>
          <div className='row'></div>
          <div className="height-space-med"></div>

        </div>
      </div>
    );
  };

  return (
    <div>
      <MetaDescription content="Líderes en soluciones logísticas integrales y tecnológicas para el transporte marítimo y aéreo internacional. Con más de 60 años de experiencia, brindamos eficiencia y apoyo en operaciones de comercio exterior en Colombia y la región."/>
      <ImagenPrincipal />
      <NuestraHistoria />
      <ImagenOlas />
      <NuestrosValores />
      <MapaClientes />
      <Sucursales />
    </div>
  );
}

export default AboutPage;
