import React, { useState, useEffect } from 'react';
import '../styles/SolucionesTecnologiaPage.css';
import { useTranslation, Trans } from 'react-i18next';
import { Link } from 'react-router-dom';
import MetaDescription from '../MetaDescription';
import Indicators from '../Indicators';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Popover from 'react-bootstrap/Popover';
import logo_navesoft from '../../assets/Logo_Navesoft.svg'
import sianSelfmngnt from '../../assets/suite-sian/SIAN-Selfmngnt-Services-on-the-web-for-self-management-NAVESOFT-Navemar.png';
import sianCheck from '../../assets/suite-sian/SIAN-Check-Billing-simulator-local-chargers-and-delays-NAVESOFT-Navemar.png';
import sianWMS from '../../assets/suite-sian/SIAN WMS - Warehouse Management System- Navesoft-Navemar.png';
import sianBooks from '../../assets/suite-sian/SIAN-Books-Accounting-NAVESOFT-Navemar.png';
import sianContainer from '../../assets/suite-sian/SIAN-Container-Container-and-cargo-tracking-NAVESOFT-navemar.png';
import sianEcustoms from '../../assets/suite-sian/SIAN-E-Customs-Electronic-Transmission-to-customs-NAVESOFT-Navemar.png';
import sianAvizat from '../../assets/suite-sian/SIAN-AvizaT-Online-container-logistics-monitoring-APP-NAVESOFT-Navemar.png';
import sianDepot from '../../assets/suite-sian/SIAN-Depot-Container-depot.system-NAVESOFT-Navemar.png';
import sianEtrack from '../../assets/suite-sian/SIAN-E-Track-Management-of-ground-transportation-services-NAVESOFT-Navemar.png';
import sianEpays from '../../assets/suite-sian/SIAN-E-Pays-Electronic-payment-button-NAVESOFT-Navemar.png';
import sian from '../../assets/suite-sian/SIAN-NAVESOFT-Navemar.png';
import sianBilling from '../../assets/suite-sian/SIAN-Billing-Billing-and-Collection-NAVESOFT-Navemar.png';
import sianMobile from '../../assets/suite-sian/SIAN Mobile-Navesoft-Navemar.png';

function SolucionesTecnologiaPage() {
    const { t } = useTranslation();
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);



    const ImagenPrincipal = () => {
        return (
            <div className="tecnologiaimg">
                <div className="container-fluid custom-container">
                    <div className="content-portada">
                        <h1><Trans>servicios.tecnologia_titulo_principal</Trans></h1>
                        <p><Trans>servicios.tecnologia_texto_principal</Trans></p>
                        <div className="buttons-container">
                            <Link to="/contactenos"><button className="btn btn-primary me-4"><Trans>servicios.tecnologia_boton_principal1</Trans></button></Link>
                        </div>
                    </div>
                </div>
            </div>
        );
    };

    const Indicator = () => {
        return (
            <div className="container">
                <div className='row text-justify mt-2 mt-md-4 ms-5 ms-md-0'>
                    <div className='col-12 col-md-6 col-xl-3 border-left py-4 mt-5 mt-xl-0'>
                        <h3><Indicators endNumber={430513} suffix="" decimals={0} time={30} /></h3>
                        <p className="titulo_indicador my-3"><Trans>servicios.tecnologia_indicador_titulo</Trans></p>
                        <p className="pe-5"><Trans>servicios.tecnologia_indicador_texto</Trans></p>
                    </div>
                    <div className='col-12 col-md-6 col-xl-3 border-left py-4 mt-5 mt-xl-0'>
                        <h3><Indicators endNumber={38535851} suffix="" decimals={0} time={30} /></h3>
                        <p className="titulo_indicador my-3 "><Trans>servicios.tecnologia_indicador_titulo1</Trans></p>
                        <p className="pe-5"><Trans>servicios.tecnologia_indicador_texto1</Trans></p>
                    </div>
                    <div className='col-12 col-md-6 col-xl-3 border-left py-4 mt-5 mt-xl-0'>
                        <h3><Indicators endNumber={59185} suffix="" decimals={0} time={30} /></h3>
                        <p className="titulo_indicador my-3"><Trans>servicios.tecnologia_indicador_titulo2</Trans></p>
                        <p className="pe-5"><Trans>servicios.tecnologia_indicador_texto2</Trans></p>
                    </div>
                    <div className='col-12 col-md-6 col-xl-3 border-left py-4 mt-5 mt-xl-0'>
                        <h3><Indicators endNumber={698934} suffix="" decimals={0} time={30} /></h3>
                        <p className="titulo_indicador my-3"><Trans>servicios.tecnologia_indicador_titulo3</Trans></p>
                        <p className="pe-5"><Trans>servicios.tecnologia_indicador_texto3</Trans></p>
                    </div>
                </div>
            </div>
        );
    };

    const SeccionIndicadores = () => {
        return (
            <div className='container-fluid'>
                <div className='custom-container'>
                    <div className="height-space-med"></div>
                    <div className='row mt-4'>
                        <div className='col-8 col-md-9 col-xl-10 '>
                            <h2 className="mb-5"><Trans>servicios.tecnologia_titulo_indicadores</Trans></h2>
                            <p className="text-justify d-none d-md-block"><Trans>servicios.tecnologia_texto_indicadores</Trans></p>
                        </div>
                        <div className='col-4 col-md-3 col-xl-2 d-flex justify-content-end mt-0 mt-md-5 mt-lg-3 mt-xl-0'>
                            <div className="logo-container-tecnologia">
                            <Link to="https://navesoftonline.com/"><img src={logo_navesoft} alt="Logo" className="img-fluid" /></Link>
                            </div>
                        </div>
                    </div>
                    <div className='row d-block d-md-none'>
                        <div className='col-12'>
                            <p className="text-justify"><Trans>servicios.tecnologia_texto_indicadores</Trans></p>
                        </div>
                    </div>

                    <div className='row'>
                        <Indicator />
                    </div>
                    <div className='row'></div>
                    <div className="height-space-med"></div>
                </div>
            </div>
        );
    };

    const [isMobile, setIsMobile] = useState(window.innerWidth <= 767);
    const [isTablet, setIsTablet] = useState(window.innerWidth <= 1024);

    useEffect(() => {
        const handleResize = () => {
            setIsTablet(window.innerWidth <= 1024);
        };

        const handleResizeMobile = () => {
            setIsMobile(window.innerWidth <= 767);
        };

        window.addEventListener('resize', handleResize);
        window.addEventListener('resize', handleResizeMobile);

        return () => {
            window.removeEventListener('resize', handleResize);
            window.removeEventListener('resize', handleResizeMobile);
        };
    }, []);

    const placement = isTablet ? 'top' : 'right';

    const sianSuite = [
        {
            image: sianSelfmngnt,
            title: <Trans>servicios.titulo_sianselfmngnt</Trans>,
            description: [
                <Trans>servicios.texto_sianselfmngnt1</Trans>,
                <Trans>servicios.texto_sianselfmngnt2</Trans>,
                <Trans>servicios.texto_sianselfmngnt3</Trans>,
                <Trans>servicios.texto_sianselfmngnt4</Trans>,
                <Trans>servicios.texto_sianselfmngnt5</Trans>,
                <Trans>servicios.texto_sianselfmngnt6</Trans>,
            ]
        },
        {
            image: sianCheck,
            title: <Trans>servicios.titulo_siancheck</Trans>,
            description: [
                <Trans>servicios.texto_siancheck1</Trans>,
            ]
        },
        {
            image: sianBooks,
            title: <Trans>servicios.titulo_sianbooks</Trans>,
            description: [
                <Trans>servicios.texto_sianbooks1</Trans>,
                <Trans>servicios.texto_sianbooks2</Trans>,
                <Trans>servicios.texto_sianbooks3</Trans>,
                <Trans>servicios.texto_sianbooks4</Trans>,
                <Trans>servicios.texto_sianbooks5</Trans>,
                <Trans>servicios.texto_sianbooks6</Trans>,
            ]
        },
        {
            image: sianWMS,
            title: <Trans>servicios.titulo_sianwms</Trans>,
            description: [
                <Trans>servicios.texto_sianwms1</Trans>,
            ]
        },
        {
            image: sianContainer,
            title: <Trans>servicios.titulo_siancontainer</Trans>,
            description: [
                <Trans>servicios.texto_siancontainer1</Trans>,
                <Trans>servicios.texto_siancontainer2</Trans>,
                <Trans>servicios.texto_siancontainer3</Trans>,
            ]
        },
        {
            image: sianEcustoms,
            title: <Trans>servicios.titulo_sianecustoms</Trans>,
            description: [
                <Trans>servicios.texto_sianecustoms1</Trans>,
                <Trans>servicios.texto_sianecustoms2</Trans>,
                <Trans>servicios.texto_sianecustoms3</Trans>,
                <Trans>servicios.texto_sianecustoms4</Trans>,
                <Trans>servicios.texto_sianecustoms5</Trans>,
            ]
        },
        {
            image: sianAvizat,
            title: <Trans>servicios.titulo_sianavizat</Trans>,
            description: [
                <Trans>servicios.texto_sianavizat1</Trans>,
                <Trans>servicios.texto_sianavizat2</Trans>,
            ]
        },
        {
            image: sianDepot,
            title: <Trans>servicios.titulo_siandepot</Trans>,
            description: [
                <Trans>servicios.texto_siandepot1</Trans>,
                <Trans>servicios.texto_siandepot2</Trans>,
                <Trans>servicios.texto_siandepot3</Trans>,
                <Trans>servicios.texto_siandepot4</Trans>,
            ]
        },
        {
            image: sianEtrack,
            title: <Trans>servicios.titulo_sianetrack</Trans>,
            description: [
                <Trans>servicios.texto_sianetrack1</Trans>,
                <Trans>servicios.texto_sianetrack2</Trans>,
                <Trans>servicios.texto_sianetrack3</Trans>,
            ]
        },
        {
            image: sianEpays,
            title: <Trans>servicios.titulo_sianepays</Trans>,
            description: [
                <Trans>servicios.texto_sianepays1</Trans>,
                <Trans>servicios.texto_sianepays1</Trans>,
            ]
        },
        {
            image: sian,
            title: <Trans>servicios.titulo_sian</Trans>,
            description: [
                <Trans>servicios.texto_sian1</Trans>,
            ]
        },
        {
            image: sianBilling,
            title: <Trans>servicios.titulo_sianbilling</Trans>,
            description: [
                <Trans>servicios.texto_sianbilling1</Trans>,
                <Trans>servicios.texto_sianbilling2</Trans>,
                <Trans>servicios.texto_sianbilling3</Trans>,
                <Trans>servicios.texto_sianbilling4</Trans>,
                <Trans>servicios.texto_sianbilling5</Trans>,
                <Trans>servicios.texto_sianbilling6</Trans>,
                <Trans>servicios.texto_sianbilling7</Trans>,
            ]
        },
        {
            image: sianMobile,
            title: <Trans>servicios.titulo_sianmobile</Trans>,
            description: [
                <Trans>servicios.texto_sianmobile1</Trans>,
            ]
        },
    ];

    const ImagePopover = ({ suite, index }) => {
        return (
            <div className="row">
                <div className="col borde-sian m-2 mb-4 mb-xl-3">
                    <OverlayTrigger
                        trigger={['click', 'focus', 'hover']}
                        key={`top-${index}`}
                        placement={placement}
                        overlay={
                            <Popover id={`l-${index}`} className="custom-popover">
                                <Popover.Body>
                                    <p>{suite.title}</p>
                                    <ul className="custom-list">
                                        {suite.description.map((item, i) => (
                                            <li key={i}>{item}</li>
                                        ))}
                                    </ul>
                                </Popover.Body>
                            </Popover>
                        }
                    >
                        <img src={suite.image} alt={`Imagen ${index + 1}`} className="img-fluid my-2" />
                    </OverlayTrigger>
                </div>
            </div>
        );
    };

    const SuiteSian = () => {
        return (
            <div className='container-fluid bgclaro'>
                <div className='custom-container'>
                    <div className="height-space-med"></div>
                    <div className="container-fluid">
                        <div className="row">
                            {!isMobile ? (
                                <>
                                    <div className="col-6 col-md-6 col-xl-3 ancho_imagenes order-2 order-xl-1">
                                        {sianSuite.slice(0, 4).map((suite, index) => (
                                            <div key={index + 1}>
                                                <ImagePopover suite={suite} index={index} />
                                            </div>
                                        ))}
                                    </div>
                                    <div className="col-6 col-md-4 col-xl-3 mt-0 mt-md-5 ancho_imagenes order-2 order-xl-1">
                                        {sianSuite.slice(4, 7).map((suite, index) => (
                                            <div key={index + 4}>
                                                <ImagePopover suite={suite} index={index} />
                                            </div>
                                        ))}
                                    </div>
                                    <div className="col-6 col-md-4 col-xl-3 ancho_imagenes order-2 order-xl-1">
                                        {sianSuite.slice(7, 10).map((suite, index) => (
                                            <div key={index + 7}>
                                                <ImagePopover suite={suite} index={index} />
                                            </div>
                                        ))}
                                    </div>
                                    <div className="col-6 col-md-4 col-xl-3 mt-0 mt-md-5 ancho_imagenes order-2 order-xl-1">
                                        {sianSuite.slice(10, 13).map((suite, index) => (
                                            <div key={index + 10}>
                                                <ImagePopover suite={suite} index={index} />
                                            </div>
                                        ))}
                                    </div>
                                </>
                            ) : (
                                <>
                                    <div className="col-6 col-md-6 col-xl-3 ancho_imagenes order-2 order-xl-1">
                                        {sianSuite.slice(0, 7).map((suite, index) => (
                                            <div key={index + 1}>
                                                <ImagePopover suite={suite} index={index} />
                                            </div>
                                        ))}
                                    </div>
                                    <div className="col-6 col-md-4 col-xl-3 mt-0 mt-md-5 ancho_imagenes order-2 order-xl-1">
                                        {sianSuite.slice(7, 13).map((suite, index) => (
                                            <div key={index + 4}>
                                                <ImagePopover suite={suite} index={index} />
                                            </div>
                                        ))}
                                    </div>
                                </>
                            )}
                            <div className="col-12 col-xl-6 order-1 order-xl-2 ms-0 ps-0 ms-xl-5 ps-xl-5 d-flex flex-column justify-content-center">
                                <h2><Trans>servicios.tecnologia_sian_titulo</Trans></h2>
                                <p className="mb-5 mt-4 my-xl-0"><Trans i18nKey="servicios.tecnologia_sian_texto" components={{ text_bold: <span className="texto-bold" />}} /></p>
                                <div className='d-none d-xl-block mt-4'>
                                    <Link to="https://navesoftonline.com/"><button className="btn btn-primary"><Trans>servicios.tecnologia_sian_boton</Trans></button></Link>
                                </div>
                            </div>
                            <div className='d-block d-xl-none order-3 text-center mt-4'>
                                <Link to="#"><button className="btn btn-primary"><Trans>servicios.tecnologia_sian_boton</Trans></button></Link>
                            </div>
                        </div>
                    </div>
                    <div className="height-space-med"></div>
                </div>
            </div>
        );
    }

    const ServiciosTecnologia = () => {
        return (
            <div className="container-fluid">
                <div className='custom-container'>
                    <div className="height-space-med"></div>
                    <h4 className="text-justify"><Trans>servicios.tecnologia_servicios_titulo</Trans></h4>
                    <div className="height-space-min"></div>
                    <div className="list-container">
                        <ul className="centered-list custom-list-services">
                            <li>
                                <p className="text-justify margen-centro-bpo subt"><Trans>servicios.tecnologia_servicios_1</Trans></p>
                                <p className="text-justify margen-centro-bpo mb-5"><Trans>servicios.tecnologia_servicios_texto_1</Trans></p>
                            </li>
                            <li>
                                <p className="text-justify margen-centro-bpo subt"><Trans>servicios.tecnologia_servicios_2</Trans></p>
                                <p className="text-justify margen-centro-bpo mb-5"><Trans>servicios.tecnologia_servicios_texto_2</Trans></p>
                            </li>
                            <li>
                                <p className="text-justify margen-centro-bpo subt"><Trans>servicios.tecnologia_servicios_3</Trans></p>
                                <p className="text-justify margen-centro-bpo mb-5"><Trans>servicios.tecnologia_servicios_texto_3</Trans></p>
                            </li>
                            <li>
                                <p className="text-justify margen-centro-bpo subt"><Trans>servicios.tecnologia_servicios_4</Trans></p>
                                <p className="text-justify margen-centro-bpo mb-5"><Trans>servicios.tecnologia_servicios_texto_4</Trans></p>
                            </li>
                            <li>
                                <p className="text-justify margen-centro-bpo subt"><Trans>servicios.tecnologia_servicios_5</Trans></p>
                                <p className="text-justify margen-centro-bpo mb-5"><Trans>servicios.tecnologia_servicios_texto_5</Trans></p>
                            </li>
                            <li>
                                <p className="text-justify margen-centro-bpo subt"><Trans>servicios.tecnologia_servicios_6</Trans></p>
                                <p className="text-justify margen-centro-bpo mb-5"><Trans>servicios.tecnologia_servicios_texto_6</Trans></p>
                            </li>
                        </ul>
                    </div>
                    <div className="botones-nuestros-servicios botones-bpo text-center mt-0 mt-md-5">
                        <Link to="/nuestros-clientes"><button type="button" className="btn btn-primary me-0 me-md-5 mb-3 mb-md-0"><Trans>servicios.bpo_servicios_boton_clientes</Trans></button></Link>
                        <Link to="/nuestros-servicios"><button type="button" className="btn btn-primary"><Trans>servicios.bpo_servicios_boton_servicios</Trans></button></Link>
                    </div>
                    <div className="height-space-med"></div>
                </div>
            </div>
        );
    };



    return (
        <div>
            <MetaDescription content={t('servicios.tecnologia_meta_descripcion')}/>
            <ImagenPrincipal />
            <SeccionIndicadores />
            <SuiteSian />
            <ServiciosTecnologia />
        </div>
    );
}

export default SolucionesTecnologiaPage;
