import React, { useState, useEffect, useRef } from 'react';

const Indicators = ({ endNumber, suffix = '', decimals = 0, time = 20, prefix = '' }) => {
    const [count, setCount] = useState(0);
    const countRef = useRef(null);

    useEffect(() => {
        const observer = new IntersectionObserver(entries => {
            entries.forEach(entry => {
                if (entry.isIntersecting) {
                    const increment = endNumber > 1 ? (endNumber / 100) : (1 / 100);
                    const interval = setInterval(() => {
                        setCount((prevCount) => {
                            if (prevCount < endNumber - increment) {
                                return prevCount + increment;
                            } else {
                                clearInterval(interval);
                                return endNumber;
                            }
                        });
                    }, time); 
                }
            });
        }, {
            root: null,
            threshold: 0.1
        });

        if (countRef.current) {
            observer.observe(countRef.current);
        }

        return () => {
            observer.disconnect();
        };
    }, [endNumber, time]);

    const formattedNumber = count.toLocaleString(undefined, {
        minimumFractionDigits: decimals,
        maximumFractionDigits: decimals
    });
    // Formatear número manualmente para invertir los símbolos de miles y decimales
    //formattedNumber = count.toFixed(formattedNumber).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.').replace('.', ',');

    return (
        <span ref={countRef}>
            {prefix}{formattedNumber}{suffix}
        </span>
    );
};

export default Indicators;
