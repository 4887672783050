import React, { useEffect } from 'react';
import MetaDescription from '../MetaDescription';
import '../styles/RepresentacionAerolineasPage.css';
import { useTranslation, Trans } from 'react-i18next';
import { Link } from 'react-router-dom';
import ServiciosAerolinea from '../ServiciosAerolinea';
import Indicators from '../Indicators';
import SliderAerolineas from '../SliderAerolineas';
import representacion_air from '../../assets/Air Canada-foto-representacion de aerolineas-Navemar.png';
import representacion_lufthansa from '../../assets/Lufthansa Cargo-foto-representacion de aerolineas-Navemar.jpg';

function RepresentacionAerolineasPage() {
    const { t } = useTranslation();
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const ImagenPrincipal = () => {
        return (
            <div className="aerolineasimg">
                <div className="container-fluid custom-container">
                    <div className="content-portada col-12 col-xl-6">
                        <h1><Trans>servicios.aerolineas_titulo_principal</Trans></h1>
                        <p><Trans>servicios.aerolineas_texto_principal</Trans></p>
                        <div className="buttons-container">
                            <Link to="/contactenos"><button className="btn btn-primary me-4"><Trans>servicios.aerolineas_boton_principal1</Trans></button></Link>
                        </div>
                    </div>
                </div>
            </div>
        );
    };

    const ServiciosRepresentacion = () => {
        return (
            <div className="container-fluid">
                <div className='custom-container'>
                    <div className="height-space-med"></div>
                    <div className='row'>
                        <div className="col-12 col-xl-11">
                            <h2 className="text-justify"><Trans>servicios.aerolineas_servicios_representacion_titulo</Trans></h2>
                        </div>
                    </div>
                    <div className='row'>
                        <div className="col-12 col-xl-9 my-3 my-xl-5">
                            <p className="text-justify"><Trans>servicios.aerolineas_servicios_representacion_texto</Trans></p>
                        </div>
                    </div>
                    <div className='row'>
                        <div className="col-12 col-xl-6 mt-2 mt-xl-5 mb-0 mb-xl-3">
                            <p className="text-justify subtitulo" style={{ color: '#0082c6' }}><Trans>servicios.aerolineas_servicios_representacion_subtitulo</Trans></p>
                        </div>
                    </div>
                    <div className='row'>
                        <ServiciosAerolinea text="-gray" />
                    </div>
                    <div className="botones-servicios text-center mt-4 mt-md-5">
                        <Link to="/contactenos"><button type="button" className="btn btn-primary mb-3 mb-md-0"><Trans>servicios.boton_contacto</Trans></button></Link>
                    </div>
                    <div className="height-space-med"></div>
                </div>
            </div>
        );
    };

    const IndicatorAirCanada = () => {
        return (
            <div className="container-fluid">
                <div className='row text-justify my-2 my-md-5'>
                    <div className='col-12 col-md-6 border-left py-4 py-md-0 ps-5 p-0 p-md-4 mt-5 mt-xl-0'>
                        <h3><Indicators endNumber={167788} suffix="" decimals={0} time={30} /></h3>
                        <p className="titulo_indicador my-3"><Trans>servicios.aerolineas_indicador_titulo_air</Trans></p>
                        <p className="pe-5"><Trans>servicios.aerolineas_indicador_texto_air</Trans></p>
                    </div>
                    <div className='col-12 col-md-6 border-left py-4 py-md-0 ps-5 p-0 p-md-4 mt-5 mt-xl-0'>
                        <h3><Indicators endNumber={20} suffix="" decimals={0} time={30} /></h3>
                        <p className="titulo_indicador my-3 "><Trans>servicios.aerolineas_indicador2_titulo_air</Trans></p>
                        <p className="pe-5"><Trans>servicios.aerolineas_indicador2_texto_air</Trans></p>
                    </div>
                </div>
            </div>
        );
    };

    const IndicatorLufhtansa = () => {
        return (
            <div className="container-fluid">
                <div className='row text-justify my-2 my-md-5'>
                    <div className='col-12 col-md-4 border-left py-4 py-md-0 ps-5 p-0 p-md-4 mt-5 mt-xl-0'>
                        <h3><Indicators endNumber={4000} suffix="M2" decimals={0} time={30} /></h3>
                        <p className="titulo_indicador my-3"><Trans>servicios.aerolineas_indicador_titulo_lufthansa</Trans></p>
                        <p className="pe-5"><Trans>servicios.aerolineas_indicador_texto_lufthansa</Trans></p>
                    </div>
                    <div className='col-12 col-md-4 border-left py-4 py-md-0 ps-5 p-0 p-md-4 mt-5 mt-xl-0'>
                        <h3><Indicators endNumber={7.5} suffix="B" decimals={1} time={30} /></h3>
                        <p className="titulo_indicador my-3 "><Trans>servicios.aerolineas_indicador2_titulo_lufthansa</Trans></p>
                        <p className="pe-5"><Trans>servicios.aerolineas_indicador2_texto_lufthansa</Trans></p>
                    </div>
                    <div className='col-12 col-md-4 border-left py-4 py-md-0 ps-5 p-0 p-md-4 mt-5 mt-xl-0'>
                        <h3><Indicators endNumber={300} prefix="+" decimals={0} time={30} /></h3>
                        <p className="titulo_indicador my-3 "><Trans>servicios.aerolineas_indicador3_titulo_lufthansa</Trans></p>
                        <p className="pe-5"><Trans>servicios.aerolineas_indicador3_texto_lufthansa</Trans></p>
                    </div>
                </div>
            </div>
        );
    };

    const SeccionIndicadores = () => {
        return (
            <div className='container-fluid bgclaro'>
                <div className='custom-container'>
                    <div className="height-space-med"></div>
                    <div className='row'>
                        <div className='col-12 mt-4 text-center'>
                            <h2 className="mb-5"><Trans>servicios.aerolineas_titulo_indicadores</Trans></h2>
                        </div>
                    </div>
                    <div className='row'>
                        <div className='col-12 col-xl-6 mt-4 text-justify'>
                            <h3 className="mb-5" style={{ color: '#0082c6' }}><Trans>servicios.aerolineas_subtitulo_indicadores_air</Trans></h3>
                            <p className="text-justify"><Trans i18nKey="servicios.aerolineas_texto_indicadores_air" components={{ text_bold: <span className="texto-bold" to='/nuestros-clientes'/>}} /></p>
                            
                            <div className="d-none d-xl-block">
                                <IndicatorAirCanada />
                                <a href="https://www.aircanada.com/co/es/aco/home.html" rel="noreferrer" target="_blank"><button type="button" className="btn btn-primary me-0 me-md-5 mb-3 mb-md-0"><Trans>servicios.aerolineas_boton_indicadores_air</Trans></button></a>
                            </div>
                        </div>
                        <div className='col-12 col-xl-6 mt-4 text-center'>
                            <img src={representacion_air} alt="Air Canada" className="img-fluid img-indicadores" />
                        </div>
                        <div className='col mt-4 d-block d-xl-none'>
                            <div className="indicador-aerolineas">
                                <IndicatorAirCanada />
                            </div>
                            <div className="text-center mt-5 mt-md-0">
                                <a href="https://www.aircanada.com/co/es/aco/home.html" rel="noreferrer" target="_blank">
                                    <button type="button" className="btn btn-primary mb-3 mb-md-0">
                                        <Trans>servicios.aerolineas_boton_indicadores_air</Trans>
                                    </button>
                                </a>
                            </div>
                        </div>
                    </div>
                    <div className='row mt-5'>
                        <div className='col-12 col-xl-6 mt-4 text-center text-xl-start order-2 order-xl-1'>
                            <img src={representacion_lufthansa} alt="Lufthansa" className="img-fluid img-indicadores" />
                        </div>
                        <div className='col-12 col-xl-6 mt-4 text-justify order-1 order-xl-2'>
                            <h3 className="mb-5" style={{ color: '#0082c6' }}><Trans>servicios.aerolineas_subtitulo_indicadores_lufthansa</Trans></h3>
                            <p className="text-justify"><Trans i18nKey="servicios.aerolineas_texto_indicadores_lufthansa" components={{ text_bold: <span className="texto-bold" to='/nuestros-clientes'/>}} /></p>
                            <div className="d-none d-xl-block">
                                <IndicatorLufhtansa />
                                <a href="https://www.lufthansa-cargo.com/es/home" rel="noreferrer" target="_blank"><button type="button" className="btn btn-primary me-0 me-md-5 mb-3 mb-md-0"><Trans>servicios.aerolineas_boton_indicadores_lufthansa</Trans></button></a>
                            </div>
                        </div>
                        <div className='col mt-4 d-block d-xl-none order-3'>
                            <div className="indicador-aerolineas">
                                <IndicatorLufhtansa />
                            </div>
                            <div className="text-center mt-5 mt-md-0">
                                <a href="https://www.lufthansa-cargo.com/es/home" rel="noreferrer" target="_blank">
                                    <button type="button" className="btn btn-primary mb-3 mb-md-0">
                                        <Trans>servicios.aerolineas_boton_indicadores_lufthansa</Trans>
                                    </button>
                                </a>
                            </div>
                        </div>
                    </div>
                    <div className="height-space-min"></div>
                </div>
            </div>
        );
    };


    return (
        <div>
            <MetaDescription content={t('servicios.aerolineas_meta_descripcion')}/>
            <ImagenPrincipal />
            <ServiciosRepresentacion />
            <SeccionIndicadores />
            <SliderAerolineas />
        </div>
    );
}

export default RepresentacionAerolineasPage;
