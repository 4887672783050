import React, { useState, useEffect } from 'react';
import '../styles/LoginModal.css';
import { Trans } from 'react-i18next';

import config from '../../configuration/config';
import BeatLoader from "react-spinners/BeatLoader";
import Alert from '@mui/material/Alert';
import MessageModal from '../MessageModal';

function RestablecerPasswordPage() {
  const [password, setPassword] = useState('');
  const [nitUsuario, setNitUsuario] = useState('');
  const [confirmaPassword, setConfirmaPassword] = useState('');
  const [confirmaButton, setConfirmaButton] = useState(true);
  const [responseMessage, setResponseMessage] = useState('');
  const [passwordError, setPasswordError] = useState(false);
  const [matchError, setMatchError] = useState(false);
  const [validToken, setValidToken] = useState(false);
  const [isTokenChecked, setIsTokenChecked] = useState(false);
  const [loading, setLoading] = useState(true);
  const [showModal, setShowModal] = useState(false);

  const openModal = () => setShowModal(true);
  const closeModal = () => setShowModal(false);
  

  const redirectToPage = () => {
    closeModal();
    window.location.replace('/');
  };

  const buttons = [
    { label: <Trans>restablece_clave.boton_mensaje</Trans>, onClick: redirectToPage },
  ];

  useEffect(() => {
    window.scrollTo(0, 0);

    const params = new URLSearchParams(window.location.search);
    const id = params.get('id');

    if (id && !isTokenChecked) {
      setIsTokenChecked(true);
      const [nit, token] = id.split('_');


      fetch(`${config.apiUrl}/validar-token`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ nit, token })
      })
        .then(response => response.json())
        .then(data => {
          setLoading(false);
          if (data.status === "success" && data.message === "OK") {
            setValidToken(true);
            setNitUsuario(nit);
          } else {
            setResponseMessage(data.message);
          }
        })
        .catch(error => {
          setLoading(false);
          setResponseMessage('Error: ' + error);
        });
    } else if (!id) {
      setLoading(false);
      setResponseMessage('Parámetro id no encontrado.');
    }
  }, [isTokenChecked]);

  const validatePassword = (password) => {
    const minLength = 8;

    if (password.length !== minLength) {
      setPasswordError(true);
      return false;
    }

    const hasAlpha = /[A-Za-z]/.test(password);
    const hasNumeric = /[0-9]/.test(password);
    const hasSpecial = /[!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?]/.test(password);

    if ((hasAlpha && hasNumeric) || (hasAlpha && hasSpecial) || (hasNumeric && hasSpecial)) {
      setPasswordError(false);
      return true;
    } else {
      setPasswordError(true);
      return false;
    }
  };

  const handlePasswordChange = (e) => {
    const newPassword = e.target.value;
    setPassword(newPassword);
    validatePassword(newPassword);
  };

  const handleConfirmaPasswordChange = (e) => {
    const newConfirmaPassword = e.target.value;
    setConfirmaPassword(newConfirmaPassword);

    if (password !== newConfirmaPassword) {
      setMatchError(true);
    } else {
      setMatchError(false);
    }
  };

  const handlePassword = async (event) => {
    event.preventDefault();
    setResponseMessage('');

    if (!validatePassword(password)) {
      setConfirmaButton(true);
      return;
    }

    if (password !== confirmaPassword) {
      setMatchError(true);
      setConfirmaButton(true);
      return;
    }

    setConfirmaButton(false);

    try {
      const response = await fetch(`${config.apiUrl}/restablecer-clave`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ nitUsuario, password })
      });
      const data = await response.json();
      setResponseMessage(data.message);
      setConfirmaButton(true);
      if (response.ok && data.message === "OK") {
        openModal();
      }
    } catch (error) {
      setResponseMessage('Error: ' + error);
      setConfirmaButton(true);
    }
  };

  const isFormValid = password && confirmaPassword && !passwordError && !matchError;

  return (
    <div className="reset-wrapper">
      <div className="modal-container-reset">
        {loading ? (
          <BeatLoader color="#0082c6" size={15} margin={5} />
        ) : validToken ? (
          <>
            <h1 className="modal-title-reset mb-4"><Trans>restablece_clave.titulo</Trans></h1>
            <div className="modal-margin-reset">
              {responseMessage && <Alert severity="error" onClose={() => { }} className="alert-left">{responseMessage}</Alert>}
              <form className="login-form-reset" onSubmit={handlePassword}>
                <input
                  type="password"
                  className="password-field form-control"
                  id="ptweb_clave"
                  name="ptweb_clave"
                  placeholder="Contraseña*"
                  value={password}
                  onChange={handlePasswordChange}
                  maxLength={8}
                  required
                />
                {passwordError && (
                  <div className="password-error">
                    <p><Trans>restablece_clave.alerta_mensaje1</Trans></p>
                    <ul>
                      <li><Trans>restablece_clave.alerta_mensaje2</Trans></li>
                      <li><Trans>restablece_clave.alerta_mensaje3</Trans></li>
                    </ul>
                  </div>
                )}
                <input
                  type="password"
                  className="password-field form-control"
                  id="ptweb_confirmaclave"
                  name="ptweb_confirmaclave"
                  placeholder="Confirma contraseña*"
                  value={confirmaPassword}
                  onChange={handleConfirmaPasswordChange}
                  maxLength={8}
                  required
                />
                {matchError && (
                  <div className="password-error">
                    <p><Trans>restablece_clave.alerta_mensaje4</Trans></p>
                  </div>
                )}
                {confirmaButton ? <button type="submit" className="login-button" disabled={!isFormValid}><Trans>restablece_clave.boton</Trans></button> : <BeatLoader color="#0082c6" size={15} margin={5} />}
              </form>
            </div>
            <MessageModal
              show={showModal}
              handleClose={closeModal}
              title={<Trans>restablece_clave.titulo_mensaje</Trans>}
              body={<Trans>restablece_clave.texto_mensaje</Trans>}
              buttons={buttons}
              imageType="check"
            />
          </>
        ) : (
          <>
            <h1 className="modal-title-reset mb-4"><Trans>restablece_clave.titulo_error</Trans></h1>
            <p>{responseMessage}</p>
          </>
        )}
      </div>
    </div>
  );
}

export default RestablecerPasswordPage;
