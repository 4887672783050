import React, { useEffect } from 'react';
import MetaDescription from '../MetaDescription';
import '../styles/ResponsabilidadSocialPage.css';
import { Trans } from 'react-i18next';
import { Link } from 'react-router-dom';
import imageSostenible from '../../assets/Responsabilidad-social-y-empresarial-Navemar.png'
import imageEtica from '../../assets/Etica-empresarial-Navemar.png'

function ResponsabilidadSocialPage() {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const ImagenPrincipal = () => {
        return (
            <div className="portadaimg-responsabilidad">
                <div className="container-fluid custom-container">
                    <div className="content-portada">
                        <h1><Trans>responsabilidad_social.titulo_principal</Trans></h1>
                        <p><Trans>responsabilidad_social.texto_principal</Trans></p>
                        <div className="buttons-container">
                            <Link to="/contactenos"><button className="btn btn-third me-4"><Trans>responsabilidad_social.boton_principal1</Trans></button></Link>
                        </div>
                    </div>
                </div>
            </div>
        );
    };

    const SeccionSostenible = () => {
        return (
            <div className="container-fluid custom-container">
                <div className="height-space-med"></div>
                <div className='row imgresponsabilidad'>
                    <div className='col-md-12 col-sm-12 col-xl-6 d-flex align-items-center order-1 order-md-1'>
                        <div className="text-min">
                            <div className="title-quienes-somos mb-3">
                                <div className=""><Trans>responsabilidad_social.seccion_sostenible_subtitulo</Trans></div>
                            </div>
                            <h2 className='mb-4'><Trans>responsabilidad_social.seccion_sostenible_titulo</Trans></h2>
                            <p className="d-none d-xl-block"><Trans i18nKey="responsabilidad_social.seccion_sostenible_texto" components={{ text_bold: <span className="texto-bold"/>}} /></p>
                        </div>
                    </div>
                    <div className='col-md-6 col-sm-12 text-justify mt-4 d-block d-xl-none d-flex align-items-center order-3 order-md-2'>
                        <p className=""><Trans i18nKey="responsabilidad_social.seccion_sostenible_texto" components={{ text_bold: <span className="texto-bold"/>}} /></p>
                    </div>
                    <div className='col-md-6 col-sm-12 text-center text-lg-end d-flex align-items-center justify-content-lg-end order-2 order-md-3'>
                        <img src={imageSostenible} alt="Quienes Somos" className="img-fluid ms-0 ms-lg-4 " />
                    </div>
                </div>
                <div className="height-space-med"></div>
            </div>
        );
    };

    const ImagenResponsabilidad = () => {
        return (
            <div>
                <div className="img-responsabilidad">
                </div>
                <div className="height-space-med"></div>
            </div>
        );
    };

    const SeccionEtica = () => {
        return (
            <div className="container-fluid custom-container">

                <div className='row imgresponsabilidad'>
                    <div className='col-md-6 col-sm-12 text-center text-lg-end d-flex align-items-center justify-content-lg-start order-2 order-xl-1'>
                        <img src={imageEtica} alt="Quienes Somos" className="img-fluid me-0 me-lg-4 " />
                    </div>
                    <div className='col-md-12 col-sm-12 col-xl-6 d-flex align-items-center order-1 order-xl-2'>
                        <div className="text-min">
                            <div className="title-quienes-somos mb-3">
                                <div className=""><Trans>responsabilidad_social.seccion_etica_subtitulo</Trans></div>
                            </div>
                            <h3 className='mb-4'><Trans>responsabilidad_social.seccion_etica_titulo</Trans></h3>
                            <p className="d-none d-xl-block"><Trans i18nKey="responsabilidad_social.seccion_etica_texto" components={{ text_bold: <span className="texto-bold"/>}} /></p>
                        </div>
                    </div>
                    <div className='col-md-6 col-sm-12 text-justify mt-4 d-block d-xl-none d-flex align-items-center order-3 order-xl-3'>
                        <p className=""><Trans i18nKey="responsabilidad_social.seccion_etica_texto" components={{ text_bold: <span className="texto-bold"/>}}/></p>
                    </div>
                </div>
                <div className="height-space-min"></div>
                <div className="container-fluid custom-container text-center mt-4">
                    <Link to="/quienes-somos"><button className="btn btn-primary"><Trans>responsabilidad_social.boton_slider</Trans></button></Link>
                </div>
                <div className="height-space-med"></div>
            </div>
        );
    };



    const SeccionResponsabilidad = () => {

    };

    return (
        <div>
            <MetaDescription content="Descubre cómo nuestra empresa de logística responde a las necesidades sociales y ambientales en constante evolución."/>
            <ImagenPrincipal />
            <SeccionSostenible />
            <ImagenResponsabilidad />
            <SeccionEtica />
            <SeccionResponsabilidad />
        </div>
    );
}

export default ResponsabilidadSocialPage;
