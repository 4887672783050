import React, { useEffect, useRef } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import './styles/MapClients.css';
import Indicators from './Indicators';
import { Tooltip } from 'bootstrap/dist/js/bootstrap.bundle.min';
import markerIcon from '../assets/icons/Icono-ubicación-paises-Navemar.svg';
import mapamerica from '../assets/Mapa de centro america-sur america-tenemos presencia en-Navemar-.png';

const MapaClientes = () => {
    const { t } = useTranslation();
    const divMapaRef = useRef(null);

    const marcadores = [
        {   coordenadas: "marcador marcador_chile",
            title: t('mapa_clientes.chile'),
            description: t('mapa_clientes.chile_texto'),
        },
        {   coordenadas: "marcador marcador_colombia",
            title: t('mapa_clientes.colombia'),
            description: t('mapa_clientes.colombia_texto'),
        },
        {   coordenadas: "marcador marcador_ecuador",
            title: t('mapa_clientes.ecuador'),
            description: t('mapa_clientes.ecuador_texto'),
        },
        {   coordenadas: "marcador marcador_panama",
            title: t('mapa_clientes.panama'),
            description: t('mapa_clientes.panama_texto'),
        },
        {   coordenadas: "marcador marcador_peru",
            title: t('mapa_clientes.peru'),
            description: t('mapa_clientes.peru_texto'),
        },
        {   coordenadas: "marcador marcador_venezuela",
            title: t('mapa_clientes.venezuela'),
            description: t('mapa_clientes.venezuela_texto'),
        }
    ];

    const marcadoresNoTooltip = [
        {coordenadas: "marcador marcador_colombia1"},
        {coordenadas: "marcador marcador_colombia2"},
        {coordenadas: "marcador marcador_colombia3"},
        {coordenadas: "marcador marcador_colombia4"},
        {coordenadas: "marcador marcador_colombia5"},
        {coordenadas: "marcador marcador_colombia6"}
    ];

    useEffect(() => {
        const tooltipTriggerList = [].slice.call(divMapaRef.current.querySelectorAll('[data-bs-toggle="tooltip"]'));
        tooltipTriggerList.map(function (tooltipTriggerEl) {
            return new Tooltip(tooltipTriggerEl, {
                customClass: 'tooltip-personalizado'
            })
        });
    }, [])

    const Indicator = () => {
        return (
            <div>
                <div className="height-space"></div>
                <div className='row text-center'>
                    <div className='col-6 col-md-3 col-lg-3'>
                        <h4><Indicators endNumber={60} suffix="" decimals={0} /></h4>
                        <p><Trans>mapa.indicador1</Trans></p>
                    </div>
                    <div className='col-6 col-md-3 col-lg-3'>
                        <h4><Indicators endNumber={92} suffix="" decimals={0} /></h4>
                        <p><Trans>mapa.indicador2</Trans></p>
                    </div>
                    <div className='col-6 col-md-3 col-lg-3'>
                        <h4><Indicators endNumber={6} suffix="" decimals={0} /></h4>
                        <p><Trans>mapa.indicador3</Trans></p>
                    </div>
                    <div className='col-6 col-md-3 col-lg-3'>
                        <h4><Indicators endNumber={13} suffix="" decimals={0} /></h4>
                        <p><Trans>mapa.indicador4</Trans></p>
                    </div>
                </div>
            </div>
        );
    };

    return (
        <div className="container-fluid custom-container">
            <div className='row rowmapa'>
                <div className='col-xl-7 order order-2 order-xl-1'>
                    <div ref={divMapaRef} className="divmapa">
                        <img src={mapamerica} alt="Nuestra Historia" className="img-fluid imgmapa" />
                        {marcadores.map((marker, index) => (
                            <div key={index} className={marker.coordenadas}>
                                <img src={markerIcon} alt="Marcador" className="img-fluid" data-bs-toggle="tooltip" data-bs-placement="top" title={`${marker.title}`}/>
                            </div>
                        ))}
                        {marcadoresNoTooltip.map((marker, index) => (
                            <div key={index} className={marker.coordenadas}>
                                <img src={markerIcon} alt="Marcador" className="img-fluid"
                                />
                            </div>
                        ))}
                    </div>
                </div>
                <div className='col-xl-5 mt-4 order-1 order-xl-2'>
                    <div className="container-fluid">
                        <div className="row mb-4">
                            <div className="col-12 text-justify mt-5">
                                <h3><Trans>mapa_clientes.titulo</Trans></h3>
                                <p><Trans i18nKey="mapa_clientes.texto" components={{ text_blue: <Link className="texto-azul" to="https://navesoftonline.com/" target="_blank"/>}}/></p>
                            </div>
                        </div>
                        {marcadores.map((marker, index) => (
                            <div key={index} className="row ms-0 ms-lg-1">
                                <div className="col text-end">
                                    <img src={markerIcon} alt="Marcador" className="img-fluid ubicacion" />
                                </div>
                                <div className="col-11">
                                    <p className='subtitulo mb-0'>{marker.title}</p>
                                    <p>{marker.description}</p>
                                </div>
                            </div>
                        ))}

                    </div>
                </div>
                <div className="indicadores-mapa order-xl-3">
                    <Indicator />
                    <div className="botones-mapa text-center mt-4 mt-md-5">
                        <Link to="/contactenos"><button type="button" className="btn btn-third me-0 me-md-5 mb-3 mb-md-0"><Trans>mapa_clientes.boton_contacto</Trans></button></Link>
                        <Link to="/nuestros-servicios"><button type="button" className="btn btn-primary"><Trans>mapa_clientes.boton_servicios</Trans></button></Link>
                    </div>
                </div>
                
            </div>


        </div>
    );
};

export default MapaClientes;
