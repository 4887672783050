import React from 'react';
import './styles/TabsTramites.css';
import { Trans } from 'react-i18next';

const ItinerariosTab = () => {

    return (
        <div className="container-fluid custom-container">
            <div className="row">
                <div className="col-12 text-center borde_tramites">
                    <a className="link-tramites" href="https://www.hapag-lloyd.com/es/online-business/schedule/schedule-download-solution.html" rel="noreferrer" target="_blank" ><Trans>submenu_tramites.itinerarios_hapag</Trans></a>
                </div>
                <div className="col-12 text-center mt-4 borde_tramites">
                    <a className="link-tramites" href="https://www.shipmentlink.com/co/" rel="noreferrer" target="_blank" ><Trans>submenu_tramites.itinerarios_ever</Trans></a>
                </div>
                <div className="col-12 text-center mt-4 borde_tramites">
                    <a className="link-tramites" href="https://www.nykroro.com/customer/schedules/" rel="noreferrer" target="_blank" ><Trans>submenu_tramites.itinerarios_nyk</Trans></a>
                </div>
            </div>
        </div>
    );
};

export default ItinerariosTab;