import React, { useEffect } from 'react';
import '../styles/NuestrosClientesPage.css';
import { Trans } from 'react-i18next';
import { Link } from 'react-router-dom';
import aircanada from '../../assets/Air-Canada-Representacion-de-aerolineas-Navemar.png';
import ever from '../../assets/Everegreen-Agenciamiento-maritimo-comercial-y-portuario-Navemar.png';
import hapag from '../../assets/Hapag-Lloyd-Agenciamiento-maritimo-comercial-y-portuario-Navemar.png';
import intermodal from '../../assets/Intermodal-Soluciones de tecnologia-Navemar.png';
import lufthansa from '../../assets/Lufthansa cargo-Representacion-de-aerolineas-Navemar.png';
import maersk from '../../assets/Maersk-Soluciones de tecnologia-Navemar.png';
import masair from '../../assets/Mas Air-Soluciones de tecnologia-Navemar.png';
import msc from '../../assets/MSC-Soluciones de tecnologia-Navemar.png';
import nyk from '../../assets/NYK-Agenciamiento-maritimo-comercial-y-portuario-Navemar.png';
import one from '../../assets/ONE-Soluciones-de-Tecnologia-Navemar.png';
import zaratrans from '../../assets/Zaratrans-servicio de gestion documental en puerto para agencias de carga-Navemar.jpg';

function NuestrosClientesPage() {

    const searchParams = new URLSearchParams(window.location.search);
    const sectionid = searchParams.get('section');

    useEffect(() => {
        const idToScroll = sectionid || 'nuestros_clientes';
        const section = document.getElementById(idToScroll);
        section.scrollIntoView({ behavior: 'smooth' });
    }, [sectionid]);

    const ImagenPrincipal = () => {
        return (
            <div className="clientesimg" id="nuestros_clientes">
                <div className="container-fluid custom-container">
                    <div className="content-portada">
                        <h1><Trans>nuestros_clientes.titulo_principal</Trans></h1>
                        <div className="buttons-container">
                            <Link to="/contactenos"><button className="btn btn-primary me-4"><Trans>nuestros_clientes.boton_principal</Trans></button></Link>
                        </div>
                    </div>
                </div>
            </div>
        );
    };

    const ClienteBotones = ({ clientes }) => {
        const handleClick = (e, seccion) => {
            e.preventDefault();
            const sectionElement = document.querySelector(seccion);
            if (sectionElement) {
                sectionElement.scrollIntoView({ behavior: 'smooth' });
            }
        };
        return (
            <div className="container-fluid custom-container ">
                <div className="height-space-med"></div>
                <div className="container">
                    <div className="row justify-content-start justify-content-md-center">
                        {clientes.map((item, index) => (
                            <React.Fragment key={index}>
                                <div key={`div-${index}`} className={`col-${index > 5 && index < 10 ? "3" : "auto"} col-xl-2 d-flex justify-content-start justify-content-md-center align-items-center mb-4 mx-0`}>
                                    <Link to={item.seccion} onClick={(e) => handleClick(e, item.seccion)}>
                                        <button className="btn btn-light-clientes">{item.cliente}</button>
                                    </Link>
                                </div>
                                {index === 4 && <div key="break" className="w-100 d-none d-xl-block"></div>}
                            </React.Fragment>
                        ))}
                    </div>
                    <div className="height-space-med"></div>
                    <div className="row justify-content-center">
                        <div className="col-12 col-md-6 col-xl-12 border-bottom-blue"></div>
                    </div>
                </div>
            </div>
        );
    }


    const clientes = [
        { cliente: "Air Canada", seccion: "#air" },
        { cliente: "Evergreen", seccion: "#ever" },
        { cliente: "Hapag-Lloyd", seccion: "#hapag" },
        { cliente: "Intermodal", seccion: "#inter" },
        { cliente: "Lufthansa Cargo", seccion: "#lufhtansa" },
        { cliente: "Maersk", seccion: "#maersk" },
        { cliente: "MasAir", seccion: "#masair" },
        { cliente: "MSC", seccion: "#msc" },
        { cliente: "NYK", seccion: "#nyk" },
        { cliente: "ONE", seccion: "#one" },
        { cliente: "Zaratrans", seccion: "#zaratrans" },
    ];

    const clientesDescripcion = [
        {
            cliente: "Air Canada",
            imagen: aircanada,
            subtitulo: <Trans>nuestros_clientes.air_canada_subtitulo</Trans>,
            descripcion: <Trans>nuestros_clientes.air_canada_texto1</Trans>,
            descripcion2: <Trans>nuestros_clientes.air_canada_texto2</Trans>,
            seccion: "air",
            servicios: [
                <Trans>nuestros_clientes.air_canada_servicio1</Trans>,
                <Trans>nuestros_clientes.air_canada_servicio2</Trans>,
                <Trans>nuestros_clientes.air_canada_servicio3</Trans>,
            ]
        },
        {
            cliente: "Evergreen",
            imagen: ever,
            subtitulo: <Trans>nuestros_clientes.ever_subtitulo</Trans>,
            descripcion: <Trans>nuestros_clientes.ever_texto1</Trans>,
            descripcion2: <Trans>nuestros_clientes.ever_texto2</Trans>,
            seccion: "ever",
            servicios: [
                <Trans>nuestros_clientes.ever_servicio1</Trans>,
                <Trans>nuestros_clientes.ever_servicio2</Trans>,
            ]
        },
        {
            cliente: "Hapag-Lloyd",
            imagen: hapag,
            subtitulo: <Trans>nuestros_clientes.hapag_subtitulo</Trans>,
            descripcion: <Trans>nuestros_clientes.hapag_texto1</Trans>,
            descripcion2: <Trans>nuestros_clientes.hapag_texto2</Trans>,
            seccion: "hapag",
            servicios: [
                <Trans>nuestros_clientes.hapag_servicio1</Trans>,
                <Trans>nuestros_clientes.hapag_servicio2</Trans>,
                <Trans>nuestros_clientes.hapag_servicio3</Trans>,
            ]
        },
        {
            cliente: "Intermodal",
            imagen: intermodal,
            subtitulo: <Trans>nuestros_clientes.intermodal_subtitulo</Trans>,
            descripcion: <Trans>nuestros_clientes.intermodal_texto1</Trans>,
            descripcion2: <Trans>nuestros_clientes.intermodal_texto2</Trans>,
            seccion: "inter",
            servicios: [
                <Trans>nuestros_clientes.intermodal_servicio1</Trans>,
            ]
        },
        {
            cliente: "Lufthansa Cargo",
            imagen: lufthansa,
            subtitulo: <Trans>nuestros_clientes.lufthansa_subtitulo</Trans>,
            descripcion: <Trans>nuestros_clientes.lufthansa_texto1</Trans>,
            descripcion2: <Trans>nuestros_clientes.lufthansa_texto2</Trans>,
            seccion: "lufhtansa",
            servicios: [
                <Trans>nuestros_clientes.lufthansa_servicio1</Trans>,
                <Trans>nuestros_clientes.lufthansa_servicio2</Trans>,
            ]
        },
        {
            cliente: "Maersk",
            imagen: maersk,
            subtitulo: <Trans>nuestros_clientes.maersk_subtitulo</Trans>,
            descripcion: <Trans>nuestros_clientes.maersk_texto1</Trans>,
            descripcion2: <Trans>nuestros_clientes.maersk_texto2</Trans>,
            seccion: "maersk",
            servicios: [
                <Trans>nuestros_clientes.maersk_servicio1</Trans>,
            ]
        },
        {
            cliente: "MasAir",
            imagen: masair,
            subtitulo: <Trans>nuestros_clientes.masair_subtitulo</Trans>,
            descripcion: <Trans>nuestros_clientes.masair_texto1</Trans>,
            descripcion2: <Trans>nuestros_clientes.masair_texto2</Trans>,
            seccion: "masair",
            servicios: [
                <Trans>nuestros_clientes.masair_servicio1</Trans>,
            ]
        },
        {
            cliente: "MSC - Mediterranean Shipping Company",
            imagen: msc,
            subtitulo: <Trans>nuestros_clientes.msc_subtitulo</Trans>,
            descripcion: <Trans>nuestros_clientes.msc_texto1</Trans>,
            descripcion2: <Trans>nuestros_clientes.msc_texto2</Trans>,
            seccion: "msc",
            servicios: [
                <Trans>nuestros_clientes.msc_servicio1</Trans>,
            ]
        },
        {
            cliente: "NYK Line - Nippon Yusen Kaisha",
            imagen: nyk,
            subtitulo: <Trans>nuestros_clientes.nyk_subtitulo</Trans>,
            descripcion: <Trans>nuestros_clientes.nyk_texto1</Trans>,
            descripcion2: <Trans>nuestros_clientes.nyk_texto2</Trans>,
            seccion: "nyk",
            servicios: [
                <Trans>nuestros_clientes.nyk_servicio1</Trans>,
            ]
        },
        {
            cliente: "ONE - Ocean Network Express",
            imagen: one,
            subtitulo: <Trans>nuestros_clientes.one_subtitulo</Trans>,
            descripcion: <Trans>nuestros_clientes.one_texto1</Trans>,
            descripcion2: <Trans>nuestros_clientes.one_texto2</Trans>,
            seccion: "one",
            servicios: [
                <Trans>nuestros_clientes.one_servicio1</Trans>,
            ]
        },
        {
            cliente: "Zaratrans",
            imagen: zaratrans,
            subtitulo: <Trans>nuestros_clientes.zaratrans_subtitulo</Trans>,
            descripcion: <Trans>nuestros_clientes.zaratrans_texto1</Trans>,
            descripcion2: <Trans>nuestros_clientes.zaratrans_texto2</Trans>,
            seccion: "zaratrans",
            servicios: [
                <Trans>nuestros_clientes.zaratrans_servicio1</Trans>,
                <Trans>nuestros_clientes.zaratrans_servicio2</Trans>,
            ]
        },
    ];

    const SeccionIzquierda = ({ keyindex, imagen, titulo, subtitulo, texto1, texto2, serviciosUsados, seccion }) => {
        return (
            <div className={`container-fluid ${keyindex === 10  ? "border-bottom-blue" : null}`} id={seccion}>
            
                <div className="custom-container">
                    <div className="height-space-med"></div>
                    <div className="row">
                        <div className="col-12 col-xl-6 d-flex align-items-center order-1 order-xl-2">
                            <div className="text-min">
                                <h3 className="mb-4">{titulo}</h3>
                                <p className="subtitle-nuestros-clientes subtitulo">{subtitulo}</p>
                                <p className="texto-semibold-clientes">{texto1}</p>
                                <p className="">{texto2}</p>
                                <div className='row d-none d-xl-block'>
                                    <div className='col-12'>
                                        <p className="subtitulo mt-4"><Trans>nuestros_clientes.servicios_usados</Trans></p>
                                    </div>
                                    <div className='col-12 mt-4 d-flex'>
                                        {serviciosUsados.map((item, i) => (
                                            <p key={i} className="servicios-usados">{item}</p> 
                                        ))}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='col-12 text-justify mt-4 d-block d-xl-none d-flex align-items-center order-3 order-xl-3'>
                            <p className="subtitulo mt-4"><Trans>nuestros_clientes.servicios_usados</Trans></p>
                        </div>
                        <div className='col-12 text-justify mt-2 d-block d-xl-none d-flex align-items-center order-3 order-xl-3'>
                            {serviciosUsados.map((item, i) => (
                                <span key={i} className="servicios-usados">{item}</span>
                            ))}
                        </div>
                        <div className="col-12 col-xl-6 text-center text-lg-end d-flex align-items-center justify-content-center justify-content-lg-end order-2 order-md-2 mt-4">
                            <img src={imagen} alt="Descripción de la imagen" className="img-fluid img-nuestros-clientes" />
                        </div>
                    </div>
                    {keyindex === 10 ? <div className="botones-nuestros-servicios botones-bpo text-center mt-5 mt-md-5">
                        <Link to="/contactenos"><button type="button" className="btn btn-primary me-0 me-md-5 mb-3 mb-md-0 mt-0 mt-xl-5"><Trans>nuestros_clientes.boton_contacto</Trans></button></Link>
                        <Link to="/nuestros-servicios"><button type="button" className="btn btn-primary mt-0 mt-xl-5"><Trans>nuestros_clientes.boton_nuestros_servicios</Trans></button></Link>
                    </div> : ''}
                    <div className="height-space-med"></div>
                    {keyindex !== 10 ? <div className="row justify-content-center">
                        <div className="col-12 col-md-6 col-xl-8 border-bottom-blue"></div>
                    </div> : ''}

                </div>
            </div>
        );
    };


    const SeccionDerecha = ({ imagen, titulo, subtitulo, texto1, texto2, serviciosUsados, seccion }) => {
        return (
            <div className="container-fluid custom-container" id={seccion}>
                <div className="height-space-med"></div>
                <div className='row'>
                    <div className='col-12 col-xl-6 text-center text-lg-end d-flex align-items-center justify-content-center justify-content-lg-start order-2 order-xl-1 mt-4'>
                        <img src={imagen} alt="Descripción de la imagen" className="img-fluid img-nuestros-clientes" />
                    </div>
                    <div className='col-12 col-xl-6 d-flex align-items-center order-1 order-xl-2'>
                        <div className="text-min">
                            <h3 className="mb-4">{titulo}</h3>
                            <p className="subtitle-nuestros-clientes subtitulo">{subtitulo}</p>
                            <p className="texto-semibold-clientes">{texto1}</p>
                            <p className="">{texto2}</p>
                            <div className='row d-none d-xl-block d-flex'>
                                <div className='col-12'>
                                    <p className="subtitulo mt-4"><Trans>nuestros_clientes.servicios_usados</Trans></p>
                                </div>
                                <div className='col-12 mt-4'>
                                    {serviciosUsados.map((item, i) => (
                                        <span key={i} className="servicios-usados">{item}</span>
                                    ))}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='col-12 text-justify mt-4 d-block d-xl-none d-flex align-items-center order-3 order-xl-3'>
                        <p className="subtitulo mt-4"><Trans>nuestros_clientes.servicios_usados</Trans></p>
                    </div>
                    <div className='col-12 text-justify mt-2 d-block d-xl-none d-flex align-items-center order-3 order-xl-3'>
                        {serviciosUsados.map((item, i) => (
                            <span key={i} className="servicios-usados">{item}</span>
                        ))}
                    </div>
                </div>
                <div className="height-space-med"></div>
                <div className="row justify-content-center">
                    <div className="col-12 col-md-6 col-xl-8 border-bottom-blue"></div>
                </div>
            </div>
        );
    };

    const ClienteSecciones = () => {
        return (
            <div>
                {clientesDescripcion.map((item, index) => (
                    <React.Fragment key={index}>
                        {index % 2 === 0 ? (
                            <SeccionIzquierda
                                keyindex={index}
                                imagen={item.imagen}
                                titulo={item.cliente}
                                subtitulo={item.subtitulo}
                                texto1={item.descripcion}
                                texto2={item.descripcion2}
                                serviciosUsados={item.servicios}
                                seccion={item.seccion}
                            />
                        ) : (
                            <SeccionDerecha
                                imagen={item.imagen}
                                titulo={item.cliente}
                                subtitulo={item.subtitulo}
                                texto1={item.descripcion}
                                texto2={item.descripcion2}
                                serviciosUsados={item.servicios}
                                seccion={item.seccion}
                            />
                        )}
                    </React.Fragment>
                ))}
            </div>
        );
    };

    return (
        <div>
            <ImagenPrincipal />
            <ClienteBotones clientes={clientes} />
            <ClienteSecciones />
        </div>
    );
}

export default NuestrosClientesPage;
