import Cookies from 'js-cookie';

export const setCookie = (name, value, options) => {
  Cookies.set(name, value, { ...options });
  console.log(Cookies.get(name));
};

export const getCookie = (name) => {
  return Cookies.get(name);

};

export const removeCookie = (name) => {
  Cookies.remove(name);
};