import React, { useState } from 'react';
import './styles/TabsTramites.css';
import { Trans } from 'react-i18next';
import { Link } from 'react-router-dom';
import LoginModal from './pages/LoginModal';
import { useAuth } from '../AuthContext';

const PagosTab = () => {
    const [isLoginOpen, setIsLoginOpen] = useState(false);
    const { user } = useAuth();

    const openLogin = () => setIsLoginOpen(true);
    const closeLogin = () => setIsLoginOpen(false);

    const openPagos = () => {
        const form = document.getElementById('f_pagos_nyk');
        if (form) {
            form.target = '_blank';
            const nit = document.getElementById('PTWEB_NITPAGOS');
            const nitsistema = document.getElementById('PTWEB_NITSISTEMAPAGOS');
            if (nit && nitsistema) {
                nit.value = user.nit;
                nitsistema.value = user.nitsistema;
                form.submit();
            }
        }
    };

    const handlePagosClick = (event) => {
        event.preventDefault();
        if (user) {
            openPagos();
        } else {
            openLogin();
        }
    };

    return (
        <div className="container-fluid custom-container">
            <div className="row">
                <div className="col-12 text-center borde_tramites">
                    <a className="link-tramites" href="https://navesoftonline.com/serviciosenlinea.html" rel="noreferrer" target="_blank" ><Trans>submenu_tramites.pagos_hapag</Trans></a>
                </div>
                <div className="col-12 text-center mt-4 borde_tramites">
                    <a className="link-tramites" href="https://formsweb.navesoft.com/ords_ecocol_croma/WEB_MENU_PRINEVER" rel="noreferrer" target="_blank" ><Trans>submenu_tramites.pagos_ever</Trans></a>
                </div>
                <div className="col-12 text-center mt-4 borde_tramites">
                    <Link className="link-tramites" to="#" onClick={handlePagosClick}><Trans>submenu_tramites.pagos_nyk</Trans></Link>
                </div>
            </div>
            {user &&
                <form id="f_pagos_nyk" name="f_pagos_nyk" action="https://formsweb.navesoft.com/ords_navemar_croma/envio_factura" method="post" >
                    <input type="hidden" name="PACCION" id="PACCIONPAGOS" value="M" />
                    <input type="hidden" name="PTWEB_NIT" id="PTWEB_NITPAGOS" />
                    <input type="hidden" name="PTWEB_NITSISTEMA" id="PTWEB_NITSISTEMAPAGOS" />
                </form>
            }
            {!user && <LoginModal isOpen={isLoginOpen} onClose={closeLogin} rutaRedirect="/tramites-y-consultas" />}
        </div>
    );
};

export default PagosTab;
