import React, { useState, useEffect } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimesCircle } from '@fortawesome/free-solid-svg-icons';
import { useNavigate } from 'react-router-dom';
import '../styles/ContactForm.css'
import linkedin from '../../assets/icons/Iconos-LinkedIn-1.svg';
import instagram from '../../assets/icons/Iconos-Instagram-2.svg';
import facebook from '../../assets/icons/Iconos-Facebook-3.svg';
import { useTranslation } from 'react-i18next';
import config from '../../configuration/config';
import MessageModal from '../MessageModal';
import direccion from '../../assets/icons/Iconos_Direccion-2.svg';
import telefono from '../../assets/icons/Iconos_Telefono-3.svg';
import ubicacion from '../../assets/icons/Iconos_Ubicacion-1.svg';
import Map from '../Map';
import Sucursales from '../Sucursales';


const ContactPage = () => {
    const { t } = useTranslation();
    const ContactForm = () => {

        const [validated, setValidated] = useState(false);
        const [showModal, setShowModal] = useState(false);
        const navigate = useNavigate();
        const openModal = () => setShowModal(true);
        const closeModal = () => setShowModal(false);
        const searchParams = new URLSearchParams(window.location.search);
        const sectionid = searchParams.get('section');
        const origen = searchParams.get('origen') || '';
        const [formData, setFormData] = useState({
            nombre: '',
            correo: '',
            empresa: '',
            mensaje: '',
            aceptoPolitica: false,
            origen: origen
        });

        useEffect(() => {
            const idToScroll = sectionid || 'contactform';
            const section = document.getElementById(idToScroll);
            section.scrollIntoView({ behavior: 'smooth' });
        }, [sectionid]);

        const handleChange = (e) => {
            const { name, value, type, checked } = e.target;
            setFormData((prevData) => ({
                ...prevData,
                [name]: type === 'checkbox' ? checked : value,
            }));
        };

        const [submitting, setSubmitting] = useState(false);

        const handleSubmit = async (e) => {
            e.preventDefault();
            const form = e.currentTarget;

            if (form.checkValidity() === false || !formData.aceptoPolitica || formData.mensaje.length < 100) {
                e.stopPropagation();
                setValidated(true);
                return;
            }

            try {

                setSubmitting(true);

                const response = await fetch(`${config.apiUrl}/contactonavemar`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify(formData),
                });

                const data = await response.json();
                if (response.ok && data.message === "OK") {
                    openModal();
                }
                setFormData({
                    nombre: '',
                    correo: '',
                    empresa: '',
                    mensaje: '',
                    aceptoPolitica: false,
                    origen: origen,
                });

                setValidated(false);

            } catch (error) {
                const button_error = [
                    { label: t('contacto.msj_error_boton_cerrar'), onClick: closeModal },
                ];
                <MessageModal
                    show={showModal}
                    handleClose={closeModal}
                    title={t('contacto.msj_error_titulo')}
                    body={t('contacto.msj_error_texto')}
                    buttons={button_error}
                    imageType="fail"
                />
            } finally {
                setSubmitting(false);
            }
        };

        const redirectToPage = (e) => {
            navigate('/');
        };

        const formInputs = [
            { id: 'nombre', type: 'text', placeholder: t('contacto.nombre'), msjalerta: t('contacto.msjnombre') },
            { id: 'correo', type: 'text', placeholder: t('contacto.correo'), msjalerta: t('contacto.msjcorreo') },
            { id: 'empresa', type: 'text', placeholder: t('contacto.empresa'), msjalerta: t('contacto.msjempresa') },
            { id: 'mensaje', type: 'textarea', placeholder: t('contacto.mensaje'), msjalerta: t('contacto.msjmensaje') }
        ]

        const buttons = [
            { label: t('contacto.msj_modal_boton_inicio'), onClick: redirectToPage },
            { label: t('contacto.msj_modal_boton_cerrar'), onClick: closeModal, clase: "btn-third" },
        ];


        return (
            <div id="contactform" className='container-fluid bgclaro d-flex align-items-center justify-content-center'>
                <div className='custom-container'>
                    <div className="height-space-med"></div>
                    <Container fluid>
                        <Row className='align-items-center'>
                            <Col xs={12} md={5}>
                                <div>
                                    <h1>{t('contacto.titulo')}</h1>
                                    <p>
                                        {t('contacto.texto')}
                                    </p>
                                    <div className="height-space-min"></div>
                                    <div >
                                        <a href="https://www.linkedin.com/company/navemar/" target="_blank" rel="noopener noreferrer">
                                            <img src={linkedin} alt="linkedin" className="iconos-social" />
                                        </a>
                                        <a href="https://www.instagram.com/navemar.colombia/?igsh=MXMycXlmMGk0MHB6dQ%3D%3D" target="_blank" rel="noopener noreferrer">
                                            <img src={instagram} alt="instagram" className="iconos-social" />
                                        </a>
                                        <a href="https://www.facebook.com/navemarcolombia/" target="_blank" rel="noopener noreferrer">
                                            <img src={facebook} alt="facebook" className="iconos-social" />
                                        </a>
                                    </div>
                                    <div className="height-space-min"></div>
                                    <div className="row mt-4">
                                        <div className="col"><ul className="list-unstyled">
                                            <li className="d-flex align-items-center">
                                                <img src={direccion} alt="Icono de dirección" className="mb-4 iconos me-3" />
                                                <p>{t('footer.direccion')}</p>
                                            </li>
                                            <li className="d-flex align-items-center">
                                                <img src={ubicacion} alt="Icono de ubicación" className="mb-4 iconos me-3" />
                                                <p>{t('footer.ubicacion')}</p>
                                            </li>
                                            <li className="d-flex align-items-center">
                                                <img src={telefono} alt="Icono de teléfono" className="mb-4 iconos me-3" />
                                                <p>{t('footer.telefono')}</p>
                                            </li>
                                        </ul></div>
                                    </div>

                                </div>
                            </Col>
                            <Col xs={1} className="d-flex justify-content-center"></Col>
                            <Col md={6} xs={12} className="d-flex justify-content-center">
                                <div className="p-2 p-xl-5 contact-form mt-5 mt-md-0">
                                    <div className="height-space-min"></div>
                                    <form onSubmit={handleSubmit} noValidate validated={validated ? 'true' : undefined}>
                                        {formInputs.map(input => (
                                            <div className="mb-3" key={input.id}>
                                                {input.type === 'textarea' ? (
                                                    <>
                                                        <textarea
                                                            className={`textarea-form form-control ${validated && (!formData[input.id] || formData[input.id].length < 100) ? 'is-invalid' : ''}`}
                                                            id={input.id}
                                                            name={input.id}
                                                            rows={6}
                                                            value={formData[input.id]}
                                                            onChange={handleChange}
                                                            placeholder={input.placeholder}
                                                            autoComplete="off"
                                                            required
                                                        />
                                                        {validated && (!formData[input.id] || formData[input.id].length < 100) && (
                                                            <div className="mensaje-validacion invalid-feedback">
                                                                <FontAwesomeIcon icon={faTimesCircle} className="me-2" />
                                                                {input.msjalerta}
                                                            </div>
                                                        )}
                                                    </>
                                                ) : (
                                                    <>
                                                        <input
                                                            type={input.type}
                                                            className={`input-form form-control ${validated && !formData[input.id] ? 'is-invalid' : ''}`}
                                                            id={input.id}
                                                            name={input.id}
                                                            value={formData[input.id]}
                                                            onChange={handleChange}
                                                            placeholder={input.placeholder}
                                                            autoComplete="off"
                                                            required
                                                        />
                                                        {validated && !formData[input.id] && (
                                                            <div className="mensaje-validacion invalid-feedback">
                                                                <FontAwesomeIcon icon={faTimesCircle} className="me-2" />
                                                                {input.msjalerta}
                                                            </div>
                                                        )}
                                                    </>
                                                )}
                                            </div>
                                        ))}
                                        <div className="my-4" style={{ marginLeft: '5%' }}>
                                            <div className="form-check form-check-inline">
                                                <input
                                                    type="checkbox"
                                                    className={`form-check-input ${validated && !formData.aceptoPolitica && 'is-invalid'}`}
                                                    id="aceptoPolitica"
                                                    name="aceptoPolitica"
                                                    checked={formData.aceptoPolitica}
                                                    onChange={handleChange}
                                                    required
                                                />
                                                <label className="form-check-label ms-3" htmlFor="aceptoPolitica">
                                                    <span>{t('contacto.politica1')} <a href="/politica-de-tratamiento-de-datos-personales" className={`link-politica ${validated && !formData.aceptoPolitica && 'text-danger'}`} target="_blank">{t('contacto.politica2')}</a></span>
                                                </label>
                                            </div>
                                            {validated && !formData.aceptoPolitica && (
                                                <div className="invalid-feedback">
                                                    <FontAwesomeIcon icon={faTimesCircle} className="me-2" />
                                                    {t('contacto.msjpolitica')}
                                                </div>
                                            )}
                                        </div>

                                        <div className="d-flex justify-content-center">
                                            {!submitting && (
                                                <button type="submit" className="btn btn-primary">
                                                    Enviar
                                                </button>
                                            )}
                                            {submitting &&
                                                <div className="d-flex justify-content-center">
                                                    <div className="spinner-border spinner-custom-color" role="status">
                                                        <span className="visually-hidden">Loading...</span>
                                                    </div>
                                                </div>}
                                        </div>
                                    </form>
                                    <div className="height-space-min"></div>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                    <div className="height-space-med"></div>
                    <MessageModal
                        show={showModal}
                        handleClose={closeModal}
                        title={t('contacto.msj_modal_titulo')}
                        body={t('contacto.msj_modal_texto')}
                        buttons={buttons}
                        imageType="check"
                    />
                </div>
            </div>
        );
    };


    const Mapa = () => {
        return (
            <div className='container-fluid custom-container'>
                <div className="height-space-med"></div>
                <div className=''>
                    <Map />
                </div>
                <div className="height-space-med"></div>
            </div >
        );
    };

    return (
        <div>
            <ContactForm />
            <Mapa />
            <Sucursales />
        </div>

    );
};
export default ContactPage;
