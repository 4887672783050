import {  NavLink } from 'react-router-dom';
import logo from '../assets/Logo_Navemar.svg';
import './styles/Menu.css';



function HeaderRestablecer() {
    return (
        <nav className="navbar navbar-expand-lg navbar-light custom-navbar menu-fijo" style={{ paddingTop: '0', paddingBottom: '0' }}>
            <div className="container-fluid" style={{ marginLeft: '5%', marginRight: '5%', paddingTopTop: '0' }}>
                <NavLink className="navbar-brand" to="">
                    <img src={logo} alt="Logo Navemar" className="logo_navemar" />
                </NavLink>
                </div>
        </nav>

    );
}

export default HeaderRestablecer;
