import React, { useEffect } from 'react';
import '../styles/Politicas.css';
import politica from '../../assets/Politica-de-prevencion-de-lavado-de-activos-y-financiacion-del-terrorismo-Navemar.png';
import politica_sm from '../../assets/Politica-de-prevencion-de-lavado-de-activos-y-financiacion-del-terrorismo-Navemar-Mobile.png';
import { Trans } from 'react-i18next';
import { Link } from 'react-router-dom';
import { saveAs } from 'file-saver';
import pdfFile from '../../components/politicas/Politica Prevencion Lavado Activos Navemar.pdf';

/**
 * Página de "Politica Prevencion Lavado Activos".
 * @component
 * @returns {JSX.Element} Elemento de la página "Politica Prevencion Lavado Activos".
 */
function PoliticaPrevencionLavadoActivos() {
    const handleDownload = () => {
        saveAs(pdfFile, 'Politica Prevencion Lavado Activos Navemar.pdf');
    };
    useEffect(() => {
        window.scrollTo(0, 0);
      }, []);

    return (
        <div className="container-fluid custom-container">
            <div className="politica-container mt-5">
                <div className="politica-content mb-5">
                    <img src={politica} alt="Politica" className="img-fluid default-imagen" />
                    <img src={politica_sm} alt="Politica" className="img-fluid imagen-mobile" />
                    <h3 className="text-center"><Trans>politica_lavado.titulo</Trans></h3>
                    <p className="text-justify margen-centro-bpo subt"><Trans>politica_lavado.subtitulo_1</Trans></p>
                    <p><Trans>politica_lavado.texto_1</Trans></p>
                    <p className="text-justify margen-centro-bpo subt"><Trans>politica_lavado.subtitulo_2</Trans></p>
                    <p><Trans>politica_lavado.texto_2</Trans></p>
                    <p className="text-justify margen-centro-bpo subt"><Trans>politica_lavado.subtitulo_3</Trans></p>
                    <p><Trans>politica_lavado.texto_3</Trans></p>
                    <p className="text-justify margen-centro-bpo subt"><Trans>politica_lavado.subtitulo_4</Trans></p>
                    <p><Trans>politica_lavado.texto_4</Trans></p>
                </div>
            </div>
            <div className="text-center mt-4">
                <Link to="/quienes-somos"><button className="btn btn-primary btn-politicas me-0 me-md-5 mb-3 mb-md-0"><Trans>politica_lavado.boton_1</Trans></button></Link>
                <button className="btn btn-primary btn-politicas" onClick={handleDownload}><Trans>politica_lavado.boton_2</Trans></button>
            </div>
            <div className="height-space-med"></div>
        </div>
    );
}

export default PoliticaPrevencionLavadoActivos;