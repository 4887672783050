import React, { useState, useEffect, useCallback } from 'react';
import './styles/TabsTramites.css';
import { Trans } from 'react-i18next';
import { FormControl, Select, MenuItem } from '@mui/material';
import config from '../configuration/config';
import BeatLoader from "react-spinners/BeatLoader";
import customArrowIcon from '../assets/icons/Icono-flecha-menu-negra.svg';
import iconLinea from '../assets/icons/Icono-consultar-arribo-buques-1.svg';
import iconBuque from '../assets/icons/Icono-consultar-arribo-buques-2.svg';
import iconViaje from '../assets/icons/Icono-consultar-arribo-buques-3.svg';

const BuquesTab = () => {
    const urlHapag = config.buquesHapag;
    const urlEver = config.buquesEver;
    const urlNyk = config.buquesNyk;

    const lineas = [
        { descripcion: "Hapag-Lloyd", valor: "HAPAG" },
        { descripcion: "Evergreen", valor: "EVER" },
        { descripcion: "NYK", valor: "NYK" },
    ];

    const [selectedLinea, setSelectedLinea] = useState('');
    const [selectedBuque, setSelectedBuque] = useState('');
    const [selectedViaje, setSelectedViaje] = useState('');
    const [buquesOptions, setBuquesOptions] = useState([]);
    const [viajesOptions, setViajesOptions] = useState([]);
    const [showConsultButton, setShowConsultButton] = useState(false);
    const [loadingBuques, setLoadingBuques] = useState(false);
    const [loadingViajes, setLoadingViajes] = useState(false);

    const fetchBuques = useCallback((selectedLinea) => {
        fetch(`${config.apiUrl}/consulta-buques`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ linea: selectedLinea }),
        })
            .then(response => {
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                return response.json();
            })
            .then(data => {
                setBuquesOptions(data);
                setLoadingBuques(false);
            })
            .catch(error => {
                console.error('Error fetching buques data:', error);
                setLoadingBuques(false);
            });
    }, []);

    const fetchViajes = useCallback((selectedBuque) => {
        fetch(`${config.apiUrl}/consulta-viajes`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ linea: selectedLinea, buque: selectedBuque }),
        })
            .then(response => {
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                return response.json();
            })
            .then(data => {
                setViajesOptions(data);
                setLoadingViajes(false);
            })
            .catch(error => {
                console.error('Error fetching viajes data:', error);
                setLoadingViajes(false);
            });
    }, [selectedLinea]);

    const checkAllSelected = useCallback(() => {
        if (selectedLinea && selectedBuque && selectedViaje) {
            setShowConsultButton(true);
        } else {
            setShowConsultButton(false);
        }
    }, [selectedLinea, selectedBuque, selectedViaje]);

    useEffect(() => {
        setSelectedBuque('');
        setSelectedViaje('');
        setBuquesOptions([]);
        setViajesOptions([]);
        setShowConsultButton(false);
        if (selectedLinea) {
            setLoadingBuques(true);
            fetchBuques(selectedLinea);
        }
    }, [selectedLinea, fetchBuques]);

    useEffect(() => {
        setSelectedViaje('');
        setViajesOptions([]);
        setShowConsultButton(false);
        if (selectedBuque) {
            setLoadingViajes(true);
            fetchViajes(selectedBuque);
        }
    }, [selectedBuque, fetchViajes]);

    useEffect(() => {
        checkAllSelected();
    }, [selectedLinea, selectedBuque, selectedViaje, checkAllSelected]);

    const CustomArrowIcon = () => (
        <img src={customArrowIcon} alt="Custom Arrow Icon" className="custom-arrow-menu-tramites-buques" />
    );

    const renderSelect = (label, icon, setter, selectedValue, options, loading) => (
        <div className="row justify-content-center borde_tramites">
            <div className="col-1 text-end">
                <img src={icon} alt="Icono Linea" className="icono-buques" />
            </div>
            <div className="col-4 col-md-2 text-start">
                <p><Trans>{label}</Trans></p>
            </div>
            <div className="col-6 col-md-4 text-start">
                <FormControl fullWidth>
                    <Select
                        value={selectedValue}
                        onChange={(e) => setter(e.target.value)}
                        className="custom-select"
                        MenuProps={{ classes: { paper: 'custom-select-menu' } }}
                        inputProps={{ className: 'custom-select-input' }}
                        IconComponent={CustomArrowIcon}
                    >
                        <MenuItem value="" disabled>
                            {loading ? <BeatLoader color="#0082c6" size={15} margin={5} /> : null}
                        </MenuItem>
                        {options.map((option, index) => (
                            <MenuItem key={index} value={option.valor} className="custom-select-item">{option.descripcion}</MenuItem>
                        ))}
                    </Select>
                </FormControl>
            </div>
        </div>
    );

    const handleConsultClick = () => {

        let url
        if (selectedLinea === 'HAPAG') {
            url = urlHapag;
        } else if (selectedLinea === 'EVER') {
            url = urlEver;
        } else if (selectedLinea === 'NYK') {
            url = urlNyk;
        }

        const form = document.getElementById('f_cargue_buque');
        form.action = url;
        form.target = '_blank';
        const nave = document.getElementById('PTWEB_BUQUE');
        const viaje = document.getElementById('PTWEB_VIAJE');
        nave.value = selectedBuque;
        viaje.value = selectedViaje;
        form.submit();
    };


    return (
        <div className="container-fluid custom-container">
            {renderSelect("submenu_buques.linea_maritima", iconLinea, setSelectedLinea, selectedLinea, lineas)}
            {renderSelect("submenu_buques.buque", iconBuque, setSelectedBuque, selectedBuque, buquesOptions, loadingBuques)}
            {renderSelect("submenu_buques.viaje", iconViaje, setSelectedViaje, selectedViaje, viajesOptions, loadingViajes)}
            {showConsultButton && (
                <div className="row">
                    <div className="col-12 text-center">
                        <div className="container-fluid custom-container text-center mt-3">
                            <button className="btn btn-primary" onClick={handleConsultClick}><Trans>submenu_buques.boton</Trans></button>
                        </div>
                    </div>
                    <form id="f_cargue_buque" name="f_cargue_buque" action="" method="post" >
                        <input type="hidden" name="PACCION" id="PACCION" value="M" />
                        <input type="hidden" name="PTWEB_BUQUE" id="PTWEB_BUQUE" />
                        <input type="hidden" name="PTWEB_VIAJE" id="PTWEB_VIAJE" />
                    </form>
                </div>
            )}
        </div>
    );
};

export default BuquesTab;
