import React from 'react';
import './styles/TabsTramites.css';
import { Trans } from 'react-i18next';

const CargosTab = () => {

    return (
        <div className="container-fluid custom-container">
            <div className="row">
                <div className="col-12 text-center borde_tramites">
                    <a className="link-tramites" href="https://www.hapag-lloyd.com/es/online-business/quotation/tariffs/local-charges-service-fees.html#anchor_306979" rel="noreferrer" target="_blank" ><Trans>submenu_tramites.cargos_hapag</Trans></a>
                </div>
                <div className="col-12 text-center mt-4 borde_tramites">
                    <a className="link-tramites" href="https://formsweb.navesoft.com/ords_ecocol_croma/WEB_MENU_PRINEVER?PSCREENNAME=Tarifas" rel="noreferrer" target="_blank" ><Trans>submenu_tramites.cargos_ever</Trans></a>
                </div>
            </div>
        </div>
    );
};

export default CargosTab;