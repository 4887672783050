import React from 'react';
import './styles/TabsTramites.css';
import { Trans } from 'react-i18next';
import { saveAs } from 'file-saver';
import pdfFile from '../components/formatos/DocumentNYK22.zip';
import { Link } from 'react-router-dom';

const FormatosTab = () => {
    const handleDownload = () => {        
        saveAs(pdfFile, 'DocumentNYK24.zip');
    };

    return (
        <div className="container-fluid custom-container">
            <div className="row">
                <div className="col-12 text-center borde_tramites">
                    <a className="link-tramites" href="https://navesoftonline.com/serviciosenlinea.html" rel="noreferrer" target="_blank" ><Trans>submenu_tramites.formatos_hapag</Trans></a>
                </div>
                <div className="col-12 text-center mt-4 borde_tramites">
                    <a className="link-tramites" href="https://formsweb.navesoft.com/ords_ecocol_croma/WEB_MENU_PRINEVER?PSCREENNAME=Descargue Formatos" rel="noreferrer" target="_blank" ><Trans>submenu_tramites.formatos_ever</Trans></a>
                </div>
                <div className="col-12 text-center mt-4 borde_tramites">
                    <Link className="link-tramites" to="" onClick={handleDownload} ><Trans>submenu_tramites.formatos_nyk</Trans></Link>
                </div>
            </div>
        </div>
    );
};

export default FormatosTab;