import React, { useEffect } from 'react';
import MetaDescription from '../MetaDescription';
import '../styles/AgenciamientoPortuarioPage.css';
import { useTranslation, Trans } from 'react-i18next';
import { Link } from 'react-router-dom';
import Indicators from '../Indicators';
import SliderAgenciamiento from '../SliderAgenciamiento';

function AgenciamientoPortuarioPage() {
    const { t } = useTranslation();

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const ImagenPrincipal = () => {
        return (
            <div className="agenciamientoportuarioimg">
                <div className="container-fluid custom-container">
                    <div className="content-portada-agenciamiento">
                        <h1><Trans>servicios.agenciamiento_titulo_principal</Trans></h1>
                        <p><Trans>servicios.agenciamiento_texto_principal</Trans></p>
                        <div className="buttons-container">
                            <Link to="/contactenos"><button className="btn btn-primary me-4"><Trans>servicios.agenciamiento_boton_principal1</Trans></button></Link>
                        </div>
                    </div>
                </div>
            </div>
        );
    };

    const Indicator = () => {
        return (
            <div className="container">
                <div className='row text-justify mt-0 mt-md-4 ms-5 ms-md-0'>
                    <div className='col-12 col-md-6 col-xl-3 border-left py-4 mt-5 mt-xl-0'>
                        <h3><Indicators endNumber={62499} suffix="" decimals={0} time={30} /></h3>
                        <p className="titulo_indicador my-3"><Trans>servicios.agenciamiento_indicador_titulo</Trans></p>
                        <p className="pe-5"><Trans>servicios.agenciamiento_indicador_texto</Trans></p>
                    </div>
                    <div className='col-12 col-md-6 col-xl-3 border-left py-4 mt-5 mt-xl-0'>
                        <h3><Indicators endNumber={56099} suffix="" decimals={0} time={30} /></h3>
                        <p className="titulo_indicador my-3 "><Trans>servicios.agenciamiento_indicador2_titulo</Trans></p>
                        <p className="pe-5"><Trans>servicios.agenciamiento_indicador2_texto</Trans></p>
                    </div>
                    <div className='col-12 col-md-6 col-xl-3 border-left py-4 mt-5 mt-xl-0'>
                        <h3><Indicators endNumber={10356} suffix="" decimals={0} time={30} /></h3>
                        <p className="titulo_indicador my-3"><Trans>servicios.agenciamiento_indicador3_titulo</Trans></p>
                        <p className="pe-5"><Trans>servicios.agenciamiento_indicador3_texto</Trans></p>
                    </div>
                    <div className='col-12 col-md-6 col-xl-3 border-left py-4 mt-5 mt-xl-0'>
                        <h3><Indicators endNumber={815} suffix="" decimals={0} time={30} /></h3>
                        <p className="titulo_indicador my-3"><Trans>servicios.agenciamiento_indicador4_titulo</Trans></p>
                        <p className="pe-5"><Trans>servicios.agenciamiento_indicador4_texto</Trans></p>
                    </div>
                </div>
            </div>
        );
    };

    const SeccionIndicadores = () => {
        return (
            <div className='container-fluid'>
                <div className='custom-container'>
                    <div className="height-space-med"></div>
                    <div className='row'>
                        <div className='col-12 col-xl-10 mt-4'>
                            <h2 className="mb-5"><Trans>servicios.agenciamiento_titulo_indicadores</Trans></h2>
                        </div>
                    </div>
                    <div className='row'>
                        <div className='col-12'>
                            <p className="text-justify"><Trans i18nKey="servicios.agenciamiento_texto_indicadores" components={{ text_blue: <Link className="texto-azul-link" to='/puertos-maritimos'/>}} /></p>
                        </div>
                    </div>
                    <div className='row'>
                        <Indicator />
                    </div>
                    <div className='row'>
                        <div className="text-center mt-5">
                            <Link to="/puertos-maritimos"><button type="button" className="btn btn-primary me-0 mb-3 mb-md-0"><Trans>servicios.agenciamiento_boton_indicadores</Trans></button></Link>
                        </div>
                    </div>

                </div>
            </div>
        );
    };

    const ServiciosAgenciamiento = () => {
        return (
            <div className="container-fluid">
                <div className='custom-container'>
                    <div className="height-space-med"></div>
                    <h4 className="text-justify"><Trans>servicios.agenciamiento_servicios_titulo</Trans></h4>
                    <div className="height-space-min"></div>
                    <div className="list-container">
                        <ul className="centered-list custom-list-services">
                            <li>
                                <p className="text-justify margen-centro-bpo subt"><Trans>servicios.agenciamiento_servicios_1</Trans></p>
                                <p className="text-justify margen-centro-bpo mb-5"><Trans>servicios.agenciamiento_servicios_texto_1</Trans></p>
                            </li>
                            <li>
                                <p className="text-justify margen-centro-bpo subt"><Trans>servicios.agenciamiento_servicios_2</Trans></p>
                                <p className="text-justify margen-centro-bpo mb-5"><Trans i18nKey="servicios.agenciamiento_servicios_texto_2" components={{ text_bold: <span className="texto-bold"/>}} /></p>
                            </li>
                            <li>
                                <p className="text-justify margen-centro-bpo subt"><Trans>servicios.agenciamiento_servicios_3</Trans></p>
                                <p className="text-justify margen-centro-bpo mb-5"><Trans>servicios.agenciamiento_servicios_texto_3</Trans></p>
                            </li>
                            <li>
                                <p className="text-justify margen-centro-bpo subt"><Trans>servicios.agenciamiento_servicios_4</Trans></p>
                                <p className="text-justify margen-centro-bpo mb-5"><Trans>servicios.agenciamiento_servicios_texto_4</Trans></p>
                            </li>
                            <li>
                                <p className="text-justify margen-centro-bpo subt"><Trans>servicios.agenciamiento_servicios_5</Trans></p>
                                <p className="text-justify margen-centro-bpo mb-5"><Trans>servicios.agenciamiento_servicios_texto_5</Trans></p>
                            </li>
                        </ul>
                    </div>
                    <div className="botones-nuestros-servicios botones-bpo text-center mt-0 mt-md-5">
                        <Link to="/nuestros-clientes">
                            <button type="button" className="btn btn-primary me-0 me-md-5 mb-3 mb-md-0">
                                <Trans>servicios.agenciamiento_servicios_boton_clientes</Trans>
                            </button>
                        </Link>
                        <Link to="/nuestros-servicios">
                            <button type="button" className="btn btn-primary">
                                <Trans>servicios.agenciamiento_servicios_boton_servicios</Trans>
                            </button>
                        </Link>
                    </div>
                    <div className="height-space-med"></div>
                </div>
            </div>
        );
    };


    return (
        <div>
            <MetaDescription content={t('servicios.agenciamiento_meta_descripcion')}/>
            <ImagenPrincipal />
            <SeccionIndicadores />
            <SliderAgenciamiento />
            <ServiciosAgenciamiento />

        </div>
    );
}

export default AgenciamientoPortuarioPage;
