import React from 'react';
import './styles/TabsTramites.css';
import { Trans } from 'react-i18next';
import customArrowIcon from '../assets/icons/Icono-flecha-menu-negra.svg';
import Dropdown from 'react-bootstrap/Dropdown';
import { Link } from 'react-router-dom';
import config from '../configuration/config';

const PoderesTab = () => {
    const urlCargue = config.carguePoderes;
    const urlConsulta = config.consultaPoderes;

    const CustomDropdownToggle = React.forwardRef(({ children, onClick }, ref) => (
        <Link
            className="custom-dropdown-toggle-link"
            href=""
            ref={ref}
            onClick={(e) => {
                e.preventDefault();
                onClick(e);
            }}
        >
            {children}
            <img src={customArrowIcon} alt="Custom Arrow Icon" className="custom-arrow-menu-tramites" />
        </Link>
    ));

    const submitFormPoderes = (url, tipo) => {

        const form = document.getElementById('f_cargue_poderes');
        form.action = url;
        form.target = '_blank';
        if (tipo === "1") {
            const input = document.getElementById('LINEAHAPPAP');
            const input2 = document.getElementById('LINEAEVERP');
            const input3 = document.getElementById('LINEANYKP');
            input.value = "LINEAHAPA";
            input2.value = "";
            input3.value = "";
        } else if (tipo === "2") {
            const input = document.getElementById('LINEAEVERP');
            const input2 = document.getElementById('LINEAHAPPAP');
            const input3 = document.getElementById('LINEANYKP');
            input.value = input.name;
            input2.value = "";
            input3.value = "";
        } else if (tipo === "3") {
            const input = document.getElementById('LINEANYKP');
            const input2 = document.getElementById('LINEAEVERP');
            const input3 = document.getElementById('LINEAHAPPAP');
            input.value = "LINEANAVE";
            input2.value = "";
            input3.value = "";
        } else if (tipo === "4") {
            const input = document.getElementById('LINEANYKP');
            const input2 = document.getElementById('LINEAEVERP');
            const input3 = document.getElementById('LINEAHAPPAP');
            input.value = "";
            input2.value = "";
            input3.value = "";
        }
        

        form.submit();
    };

    const handleItemClickCargue = (tipo) => {
        submitFormPoderes(urlCargue, tipo);
    };
    const handleItemClickConsulta = (tipo) => {
        submitFormPoderes(urlConsulta, tipo);
        
    };

    return (
        <div className="container-fluid custom-container">
            <div className="row">
                <div className="col-12 text-center borde_tramites-submenu">
                    <Dropdown>
                        <Dropdown.Toggle className="custom-dropdown-toggle" id="dropdown-basic" as={CustomDropdownToggle}>
                            <Trans>submenu_tramites.poderes_cargue</Trans>
                        </Dropdown.Toggle>

                        <Dropdown.Menu className="custom-dropdown-tramites">
                            <Dropdown.Item onClick={() => handleItemClickCargue("1")}><Trans>submenu_tramites.poderes_cargue_hapag</Trans></Dropdown.Item>
                            <Dropdown.Item onClick={() => handleItemClickCargue("2")}><Trans>submenu_tramites.poderes_cargue_ever</Trans></Dropdown.Item>
                            <Dropdown.Item onClick={() => handleItemClickCargue("3")}><Trans>submenu_tramites.poderes_cargue_nyk</Trans></Dropdown.Item>
                            <Dropdown.Item onClick={() => handleItemClickCargue("4")}><Trans>submenu_tramites.poderes_cargue_admin</Trans></Dropdown.Item>
                        </Dropdown.Menu>
                    </Dropdown>
                </div>
            </div>
            <div className="row">
                <div className="col-12 text-center borde_tramites-submenu">
                    <Dropdown>
                        <Dropdown.Toggle className="custom-dropdown-toggle" id="dropdown-basic" as={CustomDropdownToggle}>
                            <Trans>submenu_tramites.poderes_consulta</Trans>
                        </Dropdown.Toggle>

                        <Dropdown.Menu className="custom-dropdown-tramites">
                            <Dropdown.Item onClick={() => handleItemClickConsulta("1")}><Trans>submenu_tramites.poderes_consulta_hapag</Trans></Dropdown.Item>
                            <Dropdown.Item onClick={() => handleItemClickConsulta("2")}><Trans>submenu_tramites.poderes_consulta_ever</Trans></Dropdown.Item>
                            <Dropdown.Item onClick={() => handleItemClickConsulta("3")}><Trans>submenu_tramites.poderes_consulta_nyk</Trans></Dropdown.Item>
                        </Dropdown.Menu>
                    </Dropdown>
                </div>
            </div>
            <form id="f_cargue_poderes" name="f_cargue_poderes" action="" target="_blank" method="post" >
                <input type="hidden" name="LINEAHAPPA" id="LINEAHAPPAP" />
                <input type="hidden" name="LINEAEVER" id="LINEAEVERP" />
                <input type="hidden" name="LINEANYK" id="LINEANYKP" />
                <input type="hidden" name="PORIGEN" id="PORIGENP" value="N" />
                <input type="hidden" name="pdesdenavesoft" id="pdesdenavesoft" value="S" />
            </form>
        </div>
    );
};

export default PoderesTab;