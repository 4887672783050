import React from 'react';
import AliceCarousel from 'react-alice-carousel';
import 'react-alice-carousel/lib/alice-carousel.css';
import image1 from '../assets/agenciamiento_maritimo.png';
import image2 from '../assets/representacion_aerolineas-Navemar.png';
import image3 from '../assets/soluciones_tecnologia-Navemar-Navesoft.png';
import image4 from '../assets/servicios_bpo-Navemar.png';
import image1_md from '../assets/agenciamiento_maritimo-tablet.png';
import image2_md from '../assets/representacion_aerolineas-Navemar-tablet.png';
import image3_md from '../assets/soluciones_tecnologia-Navemar-Navesoft-tablet.png';
import image4_md from '../assets/servicios_bpo-Navemar-tablet.png';
import image1_sm from '../assets/agenciamiento_maritimo-mobile.png';
import image2_sm from '../assets/representacion_aerolineas-Navemar-mobile.png';
import image3_sm from '../assets/soluciones_tecnologia-Navemar-Navesoft-mobile.png';
import image4_sm from '../assets/servicios_bpo-Navemar-mobile.png';
import './styles/SliderComponent.css';
import { useTranslation } from 'react-i18next';


/**
 * Representa un componente de carrusel/slider que muestra diferentes elementos como imágenes y texto.
 * Utiliza la librería `react-alice-carousel` para la funcionalidad del carrusel y `react-i18next` para la internacionalización.
 * 
 * @component
 * @example
 * return (
 *   <SliderComponent />
 * )
 */
function SliderHomePage() {
    const { t } = useTranslation();

    /**
     * Define los elementos del carrusel. Cada elemento incluye una imagen, título, descripción y texto del botón.
     * La información de texto se internacionaliza utilizando `react-i18next`.
     * 
     * @type {Array.<{image: string, title: string, description: string, buttonText: string}>}
     */
    const items = [
        {
            image: image1,
            image_md: image1_md,
            image_sm: image1_sm,
            title: t('home.tittle1'),
            description: t('home.text1'),
            buttonText: t('home.btn1'),
            link: '/agenciamiento-maritimo-comercial-y-portuario'
        },
        {
            image: image2,
            image_md: image2_md,
            image_sm: image2_sm,
            title: t('home.tittle2'),
            description: t('home.text2'),
            buttonText: t('home.btn1'),
            link: '/representacion-de-aerolineas'
        },
        {
            image: image3,
            image_md: image3_md,
            image_sm: image3_sm,
            title: t('home.tittle3'),
            description: t('home.text3'),
            buttonText: t('home.btn1'),
            link: '/soluciones-de-tecnologia'
        },
        {
            image: image4,
            image_md: image4_md,
            image_sm: image4_sm,
            title: t('home.tittle4'),
            description: t('home.text4'),
            buttonText: t('home.btn1'),
            link: '/gestion-de-liberacion-facturacion-y-recaudo-de-gastos-locales'
        },

    ];

    /**
     * Genera y retorna los elementos JSX para cada item del carrusel.
     * Cada elemento consta de una imagen, título, descripción y un botón.
     * 
     * @returns {JSX.Element[]} Los elementos JSX para ser renderizados por AliceCarousel.
     */
    const renderItems = () => {
        return items.map((item, index) => (
            <div key={index} className="slider-item">
                <img src={item.image} alt={`Slide ${index + 1}`} className="slideimg default-image" />
                <img src={item.image_md} alt={`Slide ${index + 1}`} className="slideimg md-image" />
                <img src={item.image_sm} alt={`Slide ${index + 1}`} className="slideimg sm-image" />
                <div className="slider-content">
                    <h1>{item.title}</h1>
                    <p>{item.description}</p>
                    <a href={item.link}>
                        <button className="btn btn-primary" >{item.buttonText}</button>
                    </a>
                </div>
            </div>
        )); 
    };

    return (
        <div className="slider-wrapper">
            <AliceCarousel
                autoPlay
                autoPlayInterval={1500}
                disableButtonsControls
                infinite
                mouseTracking
                items={renderItems()}

            />
        </div>
    );
}

export default SliderHomePage;
